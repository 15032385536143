import React from 'react';
import { useSelector } from 'react-redux';

import { Prompt, UserFormPopup } from 'components';
import { MessageWrapper, Status } from 'ui-elements';
import { cn } from 'utils/cn';

export const ChatMain = () => {
  const { activeStep } = useSelector((state) => state.chatbotCreating);
  const { cornerRadius, theme, chosenTheme } = useSelector(
    (state) => state.chatbotDesign,
  );
  const { suggestedMessages, initialMessage } = useSelector(
    (state) => state.chatbotSettings,
  );

  return (
    <main
      className={cn(
        'flex flex-auto flex-col gap-2 overflow-hidden p-4',
        cornerRadius === 'rounded' && 'rounded-2xl',
      )}
      style={{
        backgroundColor:
          chosenTheme === 'Main'
            ? theme.light.backgroundColor
            : theme.dark.backgroundColor,
      }}
    >
      <div className="flex flex-auto flex-col gap-2 overflow-y-auto">
        {activeStep === 3 ? (
          <UserFormPopup className="" />
        ) : (
          <>
            <div className="flex flex-auto flex-col gap-2">
              <MessageWrapper
                text={initialMessage || 'Hi! How can I help you?'}
                className={cornerRadius === 'unrounded' && 'rounded-none'}
                style={{
                  backgroundColor:
                    chosenTheme === 'Main'
                      ? theme.light.frameColor
                      : theme.dark.frameColor,
                  color:
                    chosenTheme === 'Main'
                      ? theme.light.textColor
                      : theme.dark.textColor,
                }}
              />
              <MessageWrapper
                fromClient
                text="How can I make a return of a purchased item?"
                className={cornerRadius === 'unrounded' && 'rounded-none'}
                style={{
                  backgroundColor:
                    chosenTheme === 'Main'
                      ? theme.light.accentColor
                      : theme.dark.accentColor,
                  color:
                    chosenTheme === 'Main'
                      ? theme.light?.accentTextColor
                      : theme.dark?.accentTextColor,
                }}
              />
            </div>
            {suggestedMessages?.length > 0 && (
              <div className="flex flex-wrap justify-end gap-2.5">
                {suggestedMessages.map(
                  (message, index) =>
                    message && (
                      <Status
                        key={index}
                        className={
                          cornerRadius === 'unrounded' && 'rounded-none'
                        }
                        style={{
                          borderColor:
                            chosenTheme === 'Main'
                              ? theme.light.accentColor
                              : theme.dark.accentColor,
                          cursor: 'pointer',
                        }}
                        name={message}
                      />
                    ),
                )}
              </div>
            )}
          </>
        )}
      </div>
      {activeStep !== 3 && <Prompt isCustom placeholderColor={chosenTheme === 'Main' ? theme.light.textColor : theme.dark.textColor} />}
    </main>
  );
};
