import { CheckCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useSelector } from 'react-redux';

import useStripe from 'hooks/API/useStripe';
import { usePopup } from 'hooks/usePopup';
import { Button } from 'ui-elements';
import { cn } from 'utils/cn';
import { formatEndSubDate, isSubExpiringSoon } from 'utils/timeHelper';

export const PaywallCard = ({
  title,
  price,
  benefits,
  chatbotId,
  activeSubscriptions,
}) => {
  const { createCheckoutSession } = useStripe();

  const { isWhiteLabelHighlighted } = useSelector((state) => state.popup);
  const activeSubscriptionsFromStore = useSelector(
    (state) => state.stripe.activeSubscriptions,
  );

  const { openCancelSubscriptionPopup, openRenewSubscriptionPopup } =
    usePopup();
  const filteredSubscriptions =
    activeSubscriptions || activeSubscriptionsFromStore;
  const currentSubscription =
    filteredSubscriptions
      .slice()
      .reverse()
      .find((subscription) => subscription.metadata.priceName === title) || {};

  const isActive =
    currentSubscription.status === 'active' ||
    currentSubscription.status === 'trialing';

  const subEnd =
    currentSubscription.cancel_at || currentSubscription.canceled_at;
  const subDate = subEnd
    ? currentSubscription.cancel_at || currentSubscription.canceled_at
    : currentSubscription.current_period_end;

  const renderButton = () => {
    if (isActive && !subEnd) {
      return (
        <Button
          variant="outline"
          className={cn('min-h-10 w-full min-w-fit cursor-pointer')}
          onClick={() => openCancelSubscriptionPopup(currentSubscription.id)}
        >
          Cancel
        </Button>
      );
    } else {
      return subEnd ? (
        <Button
          className={cn('min-h-10 w-full min-w-fit')}
          onClick={() => {
            if (!isActive) {
              createCheckoutSession(title, chatbotId);
            } else {
              openRenewSubscriptionPopup(currentSubscription.id);
            }
          }}
        >
          Renew
        </Button>
      ) : (
        <Button
          className={cn('min-h-10 w-full min-w-fit')}
          onClick={() => {
            createCheckoutSession(title, chatbotId);
          }}
        >
          Get
        </Button>
      );
    }
  };

  return (
    <div
      className={cn(
        'flex min-h-[375px] flex-col gap-2.5 rounded-2xl border-2 border-transparent bg-tableBg p-5 pt-0 shadow-shadow hover:border-main min-[640px]:gap-5 min-[1150px]:w-56',
        isWhiteLabelHighlighted &&
          (title === 'White Label' || title === 'All in 1') &&
          'animate-highlightPing ',
      )}
    >
      <header className="flex flex-col items-center justify-between">
        <div className=" flex min-h-10 w-full justify-center">
          {isActive && (
            <p
              className={cn(
                'relative h-fit w-2/3 -translate-y-0.5 rounded-b-3xl bg-main text-center font-medium text-white min-[640px]:p-1 min-[640px]:px-2',
                title !== 'All in 1' &&
                  filteredSubscriptions.includes('All in 1') &&
                  'hidden',
                subEnd && 'bg-destructive',
              )}
            >
              {subEnd ? 'Expiring' : 'Active'}
            </p>
          )}
          {!isActive && title === 'All in 1' && (
            <p
              className={cn(
                'relative h-fit w-2/3 -translate-y-0.5 rounded-b-3xl bg-main text-center font-medium text-white min-[640px]:p-1 min-[640px]:px-2',
              )}
            >
              {title === 'All in 1' && 'Most picked'}
            </p>
          )}
        </div>
        <p
          className={cn(
            'w-full font-medium',
            isWhiteLabelHighlighted && title === 'White Label' && 'text-main',
          )}
        >
          {title}
        </p>
      </header>
      <main className="flex flex-col gap-5">
        <div className="grid grid-cols-5 gap-1 min-[1100px]:grid-cols-1">
          <p className="col-span-3 self-end">
            <span className="text-3xl font-bold min-[640px]:text-5xl">
              ${price?.split('.')[0]}
            </span>
            <span className="text-2xl font-bold min-[640px]:text-2xl ">
              .{price?.split('.')[1]}
            </span>
            <span className="font-bold text-grayText max-[375px]:text-xs">
              /month
            </span>
          </p>
          <div className="col-span-2 min-[640px]:hidden">{renderButton()}</div>
        </div>
        <ul className="grid grid-cols-1 gap-1 ">
          {benefits?.map((benefit, index) => (
            <li
              key={index}
              className={cn(
                'flex items-center gap-2 text-grayText max-[440px]:text-xs',
                isWhiteLabelHighlighted &&
                  benefit === 'White Label' &&
                  'text-main',
              )}
            >
              <CheckCircleIcon className="max-h-4 min-h-4 min-w-4 max-w-4 " />
              {benefit}
            </li>
          ))}
        </ul>
      </main>
      <footer className="mt-auto flex flex-col gap-2.5">
        {subEnd && (
          <p
            className={cn(
              'text-center text-grayText',
              subEnd && 'text-destructive',
            )}
          >
            {formatEndSubDate(subEnd, subDate)}
          </p>
        )}
        <div className="max-min-[640px]:hidden">{renderButton()}</div>
      </footer>
    </div>
  );
};
