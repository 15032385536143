import React, { useEffect, useRef } from 'react';

export const SubSwitcher = ({ activeType, setActiveType, items }) => {
  const itemRefs = useRef([]);
  const indicatorRef = useRef(null);

  useEffect(() => {
    if (indicatorRef.current && activeType === 'Premium add-ons') {
      indicatorRef.current.style.left = itemRefs.current[0].offsetLeft + 'px';
      indicatorRef.current.style.width = itemRefs.current[0].offsetWidth + 'px';
    } else if (indicatorRef.current && activeType === 'Storage size') {
      indicatorRef.current.style.left = itemRefs.current[1].offsetLeft + 'px';
      indicatorRef.current.style.width = itemRefs.current[1].offsetWidth + 'px';
    }
  }, [activeType]);

  return (
    <div className="relative flex w-fit rounded-full border border-borderList bg-menuBg shadow-shadow">
      {items.map((item, index) => (
        <button
          key={index}
          className="relative z-10 px-5 py-1 whitespace-nowrap text-sm md:text-base"
          ref={(el) => (itemRefs.current[index] = el)}
          onClick={() => setActiveType(item)}
        >
          {item}
        </button>
      ))}
      <span
        className="absolute left-0 top-0 h-full rounded-full bg-whiteBlack transition-all duration-300"
        ref={indicatorRef}
      ></span>
    </div>
  );
};
