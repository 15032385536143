import { PlusIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ChatbotItem } from 'components';
import { useChatbotActions } from 'hooks/useChatbotActions';
import { usePopup } from 'hooks/usePopup';
import { Button, HeaderWrapper, PageContent } from 'ui-elements';
import { capitalize } from 'utils/stringsHelper';

export const Chatbots = () => {
  const { createChatbot } = useChatbotActions();
  const { openWelcomePopup, closeWelcomePopup } = usePopup();

  const { chatbots } = useSelector((state) => state.chatbot);
  const { pageLoading } = useSelector((state) => state.settings);

  useEffect(() => {
    chatbots.length === 0 && !pageLoading && openWelcomePopup();

    return () => {
      closeWelcomePopup();
    };
  }, [chatbots.length, pageLoading]);

  return (
    <PageContent>
      <HeaderWrapper>
        <h2>Chatbots</h2>
        <Button className="group gap-0" size="content" onClick={createChatbot}>
          New chatbot{' '}
          <PlusIcon className="size-0 duration-200 group-hover:ml-2 group-hover:size-5" />
        </Button>
      </HeaderWrapper>
      <div className="relative grid grid-cols-chatbot gap-4 overflow-y-auto pb-10">
        {chatbots?.map((chatbot) => {
          {
            const filteredSubscriptions = chatbot.activeSubscriptions?.filter(
              (subscription) =>
                subscription.status === 'active' ||
                subscription.status === 'trialing',
            );

            return (
              <ChatbotItem
                key={chatbot.id}
                status={
                  filteredSubscriptions.length > 0 &&
                  capitalize(chatbot.settings.status) !== 'Draft'
                    ? 'Premium'
                    : capitalize(chatbot.settings.status || 'Free')
                }
                name={chatbot.settings.title}
                id={chatbot.id}
                updateTime={chatbot.updatedAt}
                unread={chatbot.unread}
              />
            );
          }
        })}
      </div>
    </PageContent>
  );
};
