import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useSelector } from 'react-redux';

import useAppNavigation from 'hooks/useAppNavigation';
import { usePopup } from 'hooks/usePopup';
import { useSteps } from 'hooks/useSteps';
import { Button, FooterWrapper } from 'ui-elements';
import { lastSaved } from 'utils/timeHelper';

export const Footer = () => {
  const { handleStepBack, handleStepNext, handleSaveAsDraft } = useSteps();
  const { activeStep } = useSelector((state) => state.chatbotCreating);
  const { chatbot } = useSelector((state) => state.chatbot);

  const { openCancelBotCreationPopup } = usePopup();
  const { getPath } = useAppNavigation();

  const chatbotId = getPath().pathArray[2];

  return (
    <FooterWrapper>
      <div className="flex items-center gap-4 !pr-0">
        <Button
          variant="redGhost"
          size="sm"
          onClick={() => openCancelBotCreationPopup(chatbotId)}
        >
          Cancel
        </Button>
        <Button variant="ghost" size="sm" onClick={() => handleSaveAsDraft()}>
          Save as draft
        </Button>
      </div>
      {chatbot.updatedAt && (
        <p className="mx-auto !px-0 text-grayText">
          Last saved updates {lastSaved(chatbot.updatedAt)}
        </p>
      )}
      <div className="flex items-center gap-4 !pl-0">
        {activeStep !== 0 && (
          <Button variant="ghost" size="sm" onClick={handleStepBack}>
            <ChevronLeftIcon className="size-5" />
            Step back
          </Button>
        )}
        <Button size="sm" onClick={() => handleStepNext(chatbotId)}>
          {activeStep === 3 ? 'Create Bot' : 'Next Step'}
          {activeStep !== 3 && <ChevronRightIcon className="size-5" />}
        </Button>
      </div>
    </FooterWrapper>
  );
};
