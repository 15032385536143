import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Textarea } from 'ui-elements';

import { setSourcesText } from '../../../redux/slices/chatbotPreview/sourcesSlice';
import { useSources } from 'hooks/useSources';

export const Text = () => {
  const dispatch = useDispatch();
  const { sourcesText } = useSelector((state) => state.sources);
  const { saveText } = useSources();
  const maxChars = 500;
  const [charCount, setCharCount] = useState(sourcesText.length);

  useEffect(() => {
    setCharCount(sourcesText.length);
  }, [sourcesText]);

  const handleTextChange = (e) => {
    const text = e.target.value;
    if (text.length <= maxChars) {
      dispatch(setSourcesText(text));
    }
  };

  return (
    <>
      <header>
        <h2>Text</h2>
        <p>
          Uploading your text is a convenient way to share important information
          with your users. That is how responses of your chatbot will better
          suit your needs and reflect your brand&apos;s voice.
        </p>
      </header>
      <Textarea
        className="h-64"
        placeholder="Please enter the text you want to teach the chatbot"
        value={sourcesText}
        maxChars={maxChars}
        charCounter={charCount}
        onChange={handleTextChange}
      />
      <Button onClick={() => saveText(sourcesText)} className="mx-auto my-0">Save</Button>
    </>
  );
};
