import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import useImageUpload from 'hooks/useImageUpload';
import { Button, IconWrapper, InputTitle } from 'ui-elements';
import { LoaderForInline } from 'ui-elements/LoaderForInline';
import { logoIcons } from 'utils/iconsHelper';

export const CompanyLogo = () => {
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const { companyLogo } = useSelector((state) => state.chatbotDesign);
  const { uploadCompanyLogo } = useImageUpload();
  const fileInputRef = useRef(null);
  const FILE_SIZE_LIMIT_IN_BYTES = 5242880;

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > FILE_SIZE_LIMIT_IN_BYTES) {
      showToastError('File size exceeds 5MB. Please choose a smaller file.');
      fileInputRef.current.value = '';
    } else {
      setImageIsLoading(true);
      uploadCompanyLogo(file).finally(() => setImageIsLoading(false));
    }
  };

  return (
    <div>
      <InputTitle title="Company Logo" />
      <div className="flex items-center gap-5">
        <IconWrapper>
          <div
            className={
              companyLogo && 'h-full w-full rounded-full !object-cover'
            }
            style={{
              backgroundImage: `url(${companyLogo || logoIcons.active})`,
            }}
          ></div>
        </IconWrapper>
        <div>
          <Button
            size="sm"
            variant="outline"
            onClick={handleButtonClick}
            disabled={imageIsLoading}
          >
            <ArrowUpTrayIcon className="size-5" />
            Upload image
          </Button>
          <input
            type="file"
            accept=".jpg,.jpeg,.png,.svg,.webp"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <span className="text-xs text-grayText">
            Up to 5MB in size. JPG, PNG, SVG, WEBP
          </span>
        </div>
        {imageIsLoading && <LoaderForInline />}
      </div>
    </div>
  );
};
