import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import debounce from 'lodash.debounce';
import { hexToRgba, rgbaToHex } from 'utils/colorHelper';

import {
  setDarkAccentColor,
  setDarkAccentTextColor,
  setDarkBackgroundColor,
  setDarkFrameColor,
  setDarkShadowColor,
  setDarkTextColor,
  setLightAccentColor,
  setLightAccentTextColor,
  setLightBackgroundColor,
  setLightFrameColor,
  setLightShadowColor,
  setLightTextColor,
} from '../redux/slices/chatbotPreview/designSlice';
import { setHexColor, setRgbaColor } from '../redux/slices/colorPickerSlice';
import useAppNavigation from './useAppNavigation';
import { useUpdateBot } from './useUpdateBot';

export const useDesign = () => {
  const dispatch = useDispatch();
  const { updateBotTheme } = useUpdateBot();
  const { getPath } = useAppNavigation();
  const chatbotId = getPath().pathArray[2];
  const { chosenTheme, theme } = useSelector((state) => state.chatbotDesign);
  const { hexColor, colorPickerId } = useSelector((state) => state.colorPicker);

  const handleColorPickerChange = (color) => {
    dispatch(setRgbaColor(color.rgb));
    dispatch(setHexColor(rgbaToHex(color.rgb)));
    if (colorPickerId === 'Accent color') {
      dispatch(
        chosenTheme === 'Main'
          ? setLightAccentColor(rgbaToHex(color.rgb))
          : setDarkAccentColor(rgbaToHex(color.rgb)),
      );
    } else if (colorPickerId === 'Background color') {
      dispatch(
        chosenTheme === 'Main'
          ? setLightBackgroundColor(rgbaToHex(color.rgb))
          : setDarkBackgroundColor(rgbaToHex(color.rgb)),
      );
    } else if (colorPickerId === 'Frame color') {
      dispatch(
        chosenTheme === 'Main'
          ? setLightFrameColor(rgbaToHex(color.rgb))
          : setDarkFrameColor(rgbaToHex(color.rgb)),
      );
    } else if (colorPickerId === 'Text color') {
      dispatch(
        chosenTheme === 'Main'
          ? setLightTextColor(rgbaToHex(color.rgb))
          : setDarkTextColor(rgbaToHex(color.rgb)),
      );
    } else if (colorPickerId === 'Accent text color') {
      dispatch(
        chosenTheme === 'Main'
          ? setLightAccentTextColor(rgbaToHex(color.rgb))
          : setDarkAccentTextColor(rgbaToHex(color.rgb)),
      );
    } else if (colorPickerId === 'Shadow color') {
      dispatch(
        chosenTheme === 'Main'
          ? setLightShadowColor(rgbaToHex(color.rgb))
          : setDarkShadowColor(rgbaToHex(color.rgb)),
      );
    }
    debounceUpdateBotTheme(chatbotId, theme);
  };

  const onColorInputChange = (e) => {
    dispatch(setHexColor(e.target.value));
    dispatch(setRgbaColor(hexToRgba(e.target.value)));
    if (colorPickerId === 'Accent color') {
      dispatch(
        chosenTheme === 'Main'
          ? setLightAccentColor(e.target.value)
          : setDarkAccentColor(e.target.value),
      );
    }
    if (colorPickerId === 'Background color') {
      dispatch(
        chosenTheme === 'Main'
          ? setLightBackgroundColor(e.target.value)
          : setDarkBackgroundColor(e.target.value),
      );
    }
    if (colorPickerId === 'Frame color') {
      dispatch(
        chosenTheme === 'Main'
          ? setLightFrameColor(e.target.value)
          : setDarkFrameColor(e.target.value),
      );
    }
    if (colorPickerId === 'Text color') {
      dispatch(
        chosenTheme === 'Main'
          ? setLightTextColor(e.target.value)
          : setDarkTextColor(e.target.value),
      );
    }
    if (colorPickerId === 'Accent text color') {
      dispatch(
        chosenTheme === 'Main'
          ? setLightAccentTextColor(e.target.value)
          : setDarkAccentTextColor(e.target.value),
      );
    }
    if (colorPickerId === 'Shadow color') {
      dispatch(
        chosenTheme === 'Main'
          ? setLightShadowColor(e.target.value)
          : setDarkShadowColor(e.target.value),
      );
    }
  };

  const debounceUpdateBotTheme = useRef(
    debounce((chatbotId, theme) => {
      updateBotTheme(chatbotId, theme);
    }, 500),
  ).current;

  useEffect(() => {
    debounceUpdateBotTheme(chatbotId, theme);
  }, [hexColor, theme]);

  return {
    handleColorPickerChange,
    onColorInputChange,
  };
};
