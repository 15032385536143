import React from 'react';

import { cn } from 'utils/cn';

export const Status = ({ name, className, style }) => (
  <div
    className={cn(
      'flex w-fit items-center justify-center rounded-lg border border-transparent px-2 py-1',
      name === 'Premium' && 'bg-yellow text-sm text-black',
      name === 'Free' && 'bg-grayText text-sm text-white',
      name === 'Trained' && 'border border-active',
      name === 'Training' && 'border border-gray text-grayText',
      name === 'Draft' && 'bg-destructive text-sm text-white',
      name === 'Closed' && 'rounded-2xl border border-gray text-grayText',
      name === 'Soon' && 'rounded-2xl bg-main p-0.5 px-1 text-xs text-white',
      className,
    )}
    style={style}
  >
    {name}
  </div>
);
