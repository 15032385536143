import {
  AdjustmentsHorizontalIcon,
  Bars3BottomLeftIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  IdentificationIcon,
  LinkIcon,
  PaintBrushIcon,
  PencilSquareIcon,
  ShieldExclamationIcon,
  SparklesIcon,
  Square3Stack3DIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';

export const stepsData = [
  {
    title: 'sources',
    description: 'Add your data sources to train your chatbot',
    icons: Square3Stack3DIcon,
    subSteps: [
      { title: 'Files', icons: DocumentDuplicateIcon, quantity: 1 },
      { title: 'Text', icons: Bars3BottomLeftIcon },
      { title: 'Website', icons: LinkIcon },
    ],
  },
  {
    title: 'settings',
    description: 'Configure your bot according to your needs',
    icons: Cog6ToothIcon,
    subSteps: [
      { title: 'Bot\'s texts', icons: PencilSquareIcon },
      { title: 'AI Setting', icons: AdjustmentsHorizontalIcon },
      { title: 'Security', icons: ShieldExclamationIcon },
    ],
  },
  {
    title: 'design',
    description: 'Customize how your widget looks like',
    icons: PaintBrushIcon,
    subSteps: [{ title: 'Appearance', icons: SparklesIcon }],
  },
  {
    title: 'leads',
    description: 'Choose what data to collect from your users ',
    icons: UserGroupIcon,
    subSteps: [{ title: 'User form', icons: IdentificationIcon }],
  },
];
