import React from 'react';

import { cn } from 'utils/cn';

export const LoaderForInline = ({ className }) => {
  return (
    <div className={cn('relative', className)}>
      <div className="h-5 w-5 animate-spin rounded-full border-4 border-transparent border-r-main border-t-main" />
    </div>
  );
};
