import React from 'react';

import { InputWrapper } from 'ui-elements';
import { cn } from 'utils/cn';

export const PickerWrapper = ({ className, active, style, onClick }) => (
  <InputWrapper className="p-2" active={active}>
    <div
      className={cn(
        'size-9 cursor-pointer rounded-lg',
        !className ? 'border border-gray' : className,
      )}
      style={style}
      onClick={onClick}
    ></div>
  </InputWrapper>
);
