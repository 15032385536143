import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAppNavigation from 'hooks/useAppNavigation';

import { setIsUpgradePopup } from '../../../../redux/slices/popupSlice';
import { BotTemplate } from './BotTemplate';
import { ChatAssessment } from './ChatAssessment';
import { ChatbotGuidelines } from './ChatbotGuidelines';
import { ResponseLength } from './ResponseLength';

export const AiSetting = () => {
  const dispatch = useDispatch();
  const { getPath } = useAppNavigation();
  const chatbotId = getPath().pathArray[2];
  const { isAIFunctionality } = useSelector((state) => state.stripe);

  useEffect(() => {
    dispatch(setIsUpgradePopup(!isAIFunctionality));
  }, [isAIFunctionality]);

  return (
    <div className="">
      <h2>AI Setting</h2>
      <ChatbotGuidelines chatbotId={chatbotId} />
      <BotTemplate chatbotId={chatbotId} />
      <ResponseLength chatbotId={chatbotId} />
      <ChatAssessment chatbotId={chatbotId} />
    </div>
  );
};
