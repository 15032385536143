import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAnimations } from 'hooks/useAnimations';
import { ExpandButton } from 'ui-elements';
import { cn } from 'utils/cn';

import { setIsOpen } from '../../redux/slices/asideSlice';
import { Logo } from './Logo';
import { Navigation } from './Navigation';
import { Settings } from './Settings';

export const Aside = ({ hidden }) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.aside);

  const asideRef = useRef(null);
  const { elasticWidth } = useAnimations();

  useEffect(() => {
    elasticWidth(asideRef, isOpen, 170, 100);
  }, [isOpen]);

  return (
    <div
      className={cn(
        'after:content-"" relative z-20 flex min-w-[100px] max-w-[170px] flex-col justify-between border-r-[1px] border-whiteBlack bg-menuBg p-5 pr-0 after:absolute after:right-[-2px] after:top-0 after:h-full after:w-[1px] after:bg-gray [&>*]:pr-5',
        isOpen ? 'max-w-[170px]' : 'max-w-[100px]',
        hidden ? 'hidden' : 'flex',
      )}
      ref={asideRef}
    >
      <Logo />
      <Navigation />
      <Settings />
      <ExpandButton
        onClick={() => dispatch(setIsOpen(!isOpen))}
        isRotate={isOpen}
      />
    </div>
  );
};
