export const isValidUrl = (input) => {
  // First, check if the input looks like an email address
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailPattern.test(input)) {
    return false; // It's an email, not a URL
  }

  // If it's not an email, proceed with URL validation
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // optional protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i',
  );

  // Additional check: input must contain at least one dot
  return urlPattern.test(input) && input.includes('.');
};
