import { BoltIcon } from '@heroicons/react/24/solid';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { usePopup } from 'hooks/usePopup';
import { Button, PopupShadow, PopupWrapper } from 'ui-elements';

export const UpgradeLeadsPopup = () => {
  const { closeUpgradeLeadsPopup, openPaywallPopup } = usePopup();
  const { isAllInOne, isProAnalytic } = useSelector((state) => state.stripe);
  const popupWrapperRef = useRef(null);

  const handlePaywall = () => {
    if (!isAllInOne && !isProAnalytic) {
      openPaywallPopup();
    } else {
      closeUpgradeLeadsPopup();
    }
  };

  const handleOutsideClick = (e) => {
    if (
      popupWrapperRef.current &&
      !popupWrapperRef.current.contains(e.target)
    ) {
      closeUpgradeLeadsPopup();
    }
  };
  return (
    <div
      className="fixed inset-0 flex items-center justify-center"
      onClick={(e) => handleOutsideClick(e)}
    >
      <div ref={popupWrapperRef} className="h-fit w-[420px]">
        <PopupWrapper>
          <h3>Upgrade to download lead table</h3>
          <footer>
            <Button onClick={() => handlePaywall()}>
              <BoltIcon className="size-5 text-yellow" />
              Upgrade
            </Button>
          </footer>
        </PopupWrapper>
        <PopupShadow className='pointer-events-none' />
      </div>
    </div>
  );
};
