import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { usePopup } from 'hooks/usePopup';
import { PopupShadow, PopupWrapper } from 'ui-elements';

export const CopyPopup = () => {
  const { copyText } = useSelector((state) => state.popup);
  const { closeCopyPopup } = usePopup();

  useEffect(() => {
    setTimeout(() => {
      closeCopyPopup();
    }, 2000);
  }, []);

  return (
    <div>
      <PopupWrapper className="fixed left-1/2 top-1/2 w-72 -translate-x-1/2 -translate-y-1/2 transform p-5">
        <h3>{copyText}</h3>
      </PopupWrapper>
      <PopupShadow onClick={() => closeCopyPopup()} />
    </div>
  );
};
