import { ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useSelector } from 'react-redux';

import { SubWrapper } from 'components';
import useStripe from 'hooks/API/useStripe';
import { useLogout } from 'hooks/useLogout';
import { Button, GapBlock, HeaderWrapper, PageContent } from 'ui-elements';

export const Profile = () => {
  const { chatbots } = useSelector((state) => state.chatbot);
  const { signOutFromFirebase } = useLogout();
  const { createPortalSession } = useStripe();

  return (
    <div className="overflow-hidden">
      <PageContent>
        <HeaderWrapper>
          <h2 className="mr-auto">Profile</h2>
          <Button size="content" onClick={createPortalSession}>
            Manage subscriptions
          </Button>
          <Button size="content" variant="logout" onClick={signOutFromFirebase}>
            <ArrowLeftStartOnRectangleIcon className="size-5" />
            Log out
          </Button>
        </HeaderWrapper>
        <GapBlock>
          <h3>Subscriptions</h3>
        </GapBlock>
        <div className="flex flex-auto flex-col gap-12 overflow-y-auto pb-7">
          {chatbots?.map((chatbot) => (
            <SubWrapper
              key={chatbot.id}
              title={chatbot.settings.title}
              chatbotId={chatbot.id}
              chatbotActiveSubscriptions={chatbot.activeSubscriptions}
            />
          ))}
        </div>
      </PageContent>
    </div>
  );
};
