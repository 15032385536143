import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAppNavigation from 'hooks/useAppNavigation';
import { useUpdateBot } from 'hooks/useUpdateBot';
import { Dropdown, InputTitle } from 'ui-elements';

import { setWidgetLocation } from '../../../../redux/slices/chatbotPreview/designSlice';

export const WidgetLocation = () => {
  const dispatch = useDispatch();
  const { widgetLocation } = useSelector((state) => state.chatbotDesign);
  const { updateBotWidgetLocation } = useUpdateBot();
  const { getPath } = useAppNavigation();
  const chatbotId = getPath().pathArray[2];

  return (
    <div>
      <InputTitle title="Widget location" />
      <Dropdown
        title={
          widgetLocation === 'right'
            ? 'Right side of the screen'
            : 'Left side of the screen'
        }
        list={['Right side of the screen', 'Left side of the screen']}
        onClick={(position) => {
          dispatch(setWidgetLocation(position));
          updateBotWidgetLocation(
            chatbotId,
            position === 'Right side of the screen' ? 'right' : 'left',
          );
        }}
      />
    </div>
  );
};
