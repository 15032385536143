import React from 'react';

import { cn } from 'utils/cn';

export const Notification = ({ children, className }) => (
  <div
    className={cn(
      'flex !h-4 !max-h-4 !w-4 !max-w-4 items-center justify-center rounded-full bg-destructive p-1.5 text-xs text-white',
      className,
    )}
  >
    {children}
  </div>
);
