import { CalendarDaysIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { format } from 'date-fns';
import {
  Button,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'ui-elements';
import { cn } from 'utils/cn';

import { setChatsOverviewsDate } from '../../redux/slices/dashboardSlice';

export const DashboardDatePicker = () => {
  const dispatch = useDispatch();
  const { chatsOverviewsDate } = useSelector((state) => state.dashboard);

  const setDate = (date) => {
    const formattedDate = {
      from: date?.from ? format(date?.from, 'yyyy-MM-dd') : '',
      to: date?.to
        ? format(date?.to, 'yyyy-MM-dd')
        : date?.from
          ? format(date?.from, 'yyyy-MM-dd')
          : '',
    };
    dispatch(setChatsOverviewsDate(formattedDate));
  };

  const clearDate = () => {
    dispatch(setChatsOverviewsDate({ from: '', to: '' }));
  };

  return (
    <div
      className={cn(
        'border',
        chatsOverviewsDate?.from || chatsOverviewsDate?.to
          ? 'flex items-center rounded-xl border-border'
          : 'border-transparent',
      )}
    >
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant="icon"
            size="sm"
            className={cn(
              'justify-start text-left font-normal',
              !chatsOverviewsDate && 'text-grayText',
            )}
          >
            <p className="text-sm">
              {chatsOverviewsDate?.from ? (
                chatsOverviewsDate?.to ? (
                  chatsOverviewsDate.from === chatsOverviewsDate.to ? (
                    format(chatsOverviewsDate.from, 'LLL dd')
                  ) : (
                    <>
                      {format(chatsOverviewsDate.from, 'LLL dd')} -{' '}
                      {format(chatsOverviewsDate.to, 'LLL dd')}
                    </>
                  )
                ) : (
                  format(chatsOverviewsDate.from, 'LLL dd')
                )
              ) : (
                <CalendarDaysIcon className="size-5" />
              )}
            </p>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="end">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={chatsOverviewsDate?.from}
            selected={chatsOverviewsDate}
            onSelect={setDate}
            numberOfMonths={1}
          />
        </PopoverContent>
      </Popover>
      {(chatsOverviewsDate?.from || chatsOverviewsDate?.to) && (
        <button>
          <XMarkIcon className="size-5 min-w-5" onClick={clearDate} />
        </button>
      )}
    </div>
  );
};
