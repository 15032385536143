import { ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { Button, LibCheckbox } from 'ui-elements';

export const leadsColumns = [
  {
    id: 'select',
    header: ({ table }) => (
      <LibCheckbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && 'indeterminate')
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <LibCheckbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'created',
    header: ({ column }) => {
      return (
        <Button
          variant="icon"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Date
          <ArrowsUpDownIcon className="ml-2 size-5" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const date = new Date(row.getValue('created'));
      const formatted = new Intl.DateTimeFormat('uk-UA').format(date);

      return <div>{formatted}</div>;
    },
  },
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => <div>{row.getValue('name')}</div>,
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: ({ row }) => <div className="lowercase">{row.getValue('email')}</div>,
  },
  // {
  //   accessorKey: 'phone',
  //   header: 'Phone',
  //   cell: ({ row }) => <div>{row.getValue('phone')}</div>,
  // },
  // {
  //   accessorKey: 'additional',
  //   header: 'Additional info',
  //   cell: ({ row }) => <div>{row.getValue('additional')}</div>,
  // },
  // {
  //   id: 'actions',
  //   enableHiding: false,
  //   cell: ({ row }) => {
  //     const payment = row.original;

  //     return (
  //       <DropdownMenu>
  //         <DropdownMenuTrigger asChild>
  //           <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
  //             <span className="sr-only">Open menu</span>
  //             <EllipsisVerticalIcon className="size-5" />
  //           </Button>
  //         </DropdownMenuTrigger>
  //         <DropdownMenuContent align="end">
  //           <DropdownMenuLabel>Actions</DropdownMenuLabel>
  //           <DropdownMenuItem
  //             onClick={() => navigator.clipboard.writeText(payment.id)}
  //           >
  //             Copy payment ID
  //           </DropdownMenuItem>
  //           <DropdownMenuSeparator />
  //           <DropdownMenuItem>View customer</DropdownMenuItem>
  //           <DropdownMenuItem>View payment details</DropdownMenuItem>
  //         </DropdownMenuContent>
  //       </DropdownMenu>
  //     );
  //   },
  // },
];
