import React from 'react';
import { useSelector } from 'react-redux';

import { useSteps } from 'hooks/useSteps';
import { TableMenuItem } from 'ui-elements';
import { cn } from 'utils/cn';

export const TableMenu = () => {
  const { handleSubStepClick } = useSteps();
  const { steps, activeStep, activeSubStep } = useSelector(
    (state) => state.chatbotCreating,
  );
  const { chatBotFiles } = useSelector((state) => state.chatbot);
  const { isAIFunctionality, isAllInOne } = useSelector(
    (state) => state.stripe,
  );

  const isCursorNotAllowed = () => {
    if (activeStep > 2) {
      return false;
    } else if (activeStep === 0 && isAllInOne) {
      return false;
    } else if (activeStep === 0 && isAIFunctionality) {
      return false;
    } else if (activeStep === 0 && !isAllInOne) {
      return true;
    } else if (activeStep === 0 && !isAIFunctionality) {
      return true;
    }
  };

  const menuItems = steps[activeStep].subSteps;

  return (
    <div className="flex flex-col gap-1">
      {menuItems.map((item, idx) => (
        <TableMenuItem
          key={item.title}
          active={activeSubStep === idx}
          className={isCursorNotAllowed() && 'cursor-not-allowed'}
          onClick={() => handleSubStepClick(idx)}
        >
          <div className="after:content-'' relative after:absolute after:-bottom-1 after:h-[1px] after:w-full after:bg-main after:opacity-0 after:duration-300 hover:after:opacity-100">
            <span
              className={cn(
                activeSubStep === idx && 'text-main',
                'flex items-center gap-2.5',
              )}
            >
              <item.icons className="size-5" />
              {item.title}
            </span>
          </div>
          {item.quantity > 0 && (
            <span className="ml-auto block text-main">
              {chatBotFiles.length}
            </span>
          )}
        </TableMenuItem>
      ))}
    </div>
  );
};
