import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import gsap from 'gsap';

import {
  setCurrentCursorCoordinates,
  setPrevCursorCoordinates,
} from '../redux/slices/animationSlice';
import { setPreviewAnimation } from '../redux/slices/chatbotPreview/index';

export const useAnimations = () => {
  const dispatch = useDispatch();
  const { isPreviewAnimation } = useSelector((state) => state.chatPreview);
  const { prevCursorCoordinates, currentCursorCoordinates } = useSelector(
    (state) => state.animation,
  );
  const { activeStep, prevStep } = useSelector(
    (state) => state.chatbotCreating,
  );

  const elasticWidth = (ref, isOpen, openWidth, closeWidth) => {
    const element = ref.current;

    if (isOpen) {
      gsap.to(element, {
        width: `${openWidth}px`,
        minWidth: `${openWidth}px`,
        maxWidth: `${openWidth}px`,
        duration: 0.7,
        ease: 'elastic.out(1, 0.75)',
      });
    } else {
      gsap.to(element, {
        width: `${closeWidth}px`,
        minWidth: `${closeWidth}px`,
        maxWidth: `${closeWidth}px`,
        duration: 0.7,
        ease: 'elastic.out(1, 0.75)',
      });
    }
  };

  const popupAnimation = (ref) => {
    if (ref.current) {
      gsap.from(ref.current, {
        duration: 0.8,
        opacity: 0,
        scale: 0.6,
        ease: 'elastic.out(1.2, 1)',
      });
    }
  };

  const chatPreviewAnimation = (chatPreviewRef) => {
    if (!isPreviewAnimation) return;
    gsap.fromTo(
      chatPreviewRef.current,
      {
        opacity: 0,
        scale: 0,
      },
      {
        opacity: 1,
        scale: 1,
        transformOrigin: 'bottom center',
        duration: 0.7,
        ease: 'elastic.out(0.7, 0.7)',
        onComplete: () => {
          dispatch(setPreviewAnimation(false));
        },
      },
    );
  };

  const getCursorCoordinates = ({ x, y }) => {
    if (x) {
      dispatch(setPrevCursorCoordinates(currentCursorCoordinates));
      dispatch(
        setCurrentCursorCoordinates({ ...currentCursorCoordinates, x: x }),
      );
    }
    if (y) {
      dispatch(setPrevCursorCoordinates(currentCursorCoordinates));
      dispatch(
        setCurrentCursorCoordinates({ ...currentCursorCoordinates, y: y }),
      );
    }
  };

  const pageContentYTransition = (ref) => {
    if (currentCursorCoordinates.y < prevCursorCoordinates.y) {
      gsap.fromTo(
        ref.current,
        {
          opacity: 0,
          y: -ref.current.offsetHeight / 2,
        },
        {
          duration: 0.4,
          opacity: 1,
          y: 0,
          onComplete: () => {
            ref.current.style.transform = 'none';
          },
        },
      );
    }
    if (currentCursorCoordinates.y > prevCursorCoordinates.y) {
      gsap.fromTo(
        ref.current,
        {
          opacity: 0,
          y: ref.current.offsetHeight / 2,
        },
        {
          duration: 0.4,
          opacity: 1,
          y: 0,
          onComplete: () => {
            ref.current.style.transform = 'none';
          },
        },
      );
    }
  };

  const pageContentXTransition = (ref) => {
    if (activeStep < prevStep) {
      gsap.fromTo(
        ref.current,
        {
          opacity: 0,
          x: -ref.current.offsetWidth / 2,
        },
        {
          duration: 0.4,
          opacity: 1,
          x: 0,
          onComplete: () => {
            ref.current.style.transform = 'none';
          },
        },
      );
    }
    if (activeStep > prevStep) {
      gsap.fromTo(
        ref.current,
        {
          opacity: 0,
          x: ref.current.offsetWidth / 2,
        },
        {
          duration: 0.4,
          opacity: 1,
          x: 0,
          onComplete: () => {
            ref.current.style.transform = 'none';
          },
        },
      );
    }
  };

  const menuWrapperTransition = (menuWrapperRef) => {
    if (currentCursorCoordinates.y !== prevCursorCoordinates.y) {
      gsap.fromTo(
        menuWrapperRef.current,
        {
          opacity: 0,
          y:
            currentCursorCoordinates.y > prevCursorCoordinates.y
              ? menuWrapperRef.current.offsetHeight / 2
              : -menuWrapperRef.current.offsetHeight / 2,
        },
        {
          duration: 0.5,
          opacity: 1,
          y: 0,
        },
      );
    }
  };

  return {
    elasticWidth,
    popupAnimation,
    chatPreviewAnimation,
    getCursorCoordinates,
    pageContentYTransition,
    pageContentXTransition,
    menuWrapperTransition,
  };
};
