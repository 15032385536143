import React from 'react';

import { cn } from 'utils/cn';

export const InputWrapper = ({ children, className, active, style }) => (
  <div
    className={cn(
      'min-h-[50px] w-fit rounded-xl border border-border bg-tableBg p-3 text-textColor duration-200 focus-within:border-main',
      className,
      active && 'border-main',
    )}
    style={style}
  >
    {children}
  </div>
);
