import React from 'react';

export const ChatsTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col justify-end gap-2 rounded-md bg-tableBg p-2">
        <p>{label}</p>
        {payload.map((entry, index) => (
          <p key={index}>Total: {entry.value}</p>
        ))}
      </div>
    );
  }
};
