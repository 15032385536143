import logger from 'services/logger';
import useChat from './API/useChat';
import { useChatbotActions } from './useChatbotActions';

export const useUpdateBot = () => {
  const { updateBot } = useChat();
  const { getChatbots } = useChatbotActions();

  const updateBotTitle = (id, title) => {
    updateBot(id, { title })
      .then((result) => {
        logger.log(result);
        getChatbots();
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateBotName = (id, botName) => {
    updateBot(id, { botName })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateBotInitialMessage = (id, initialMessage) => {
    updateBot(id, { initialMessage })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateBotSuggestedMessages = (id, suggestedMessages) => {
    updateBot(id, { suggestedMessages })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateBotInputFieldText = (id, inputFieldText) => {
    updateBot(id, { inputFieldText })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateBotTheme = (id, theme) => {
    updateBot(id, { theme })
      .then((result) => {
        logger.log(id, theme);
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateBotWidgetLocation = (id, widgetLocation) => {
    updateBot(id, { widgetLocation })
      .then((result) => {
        logger.log(result);
        logger.log(id, widgetLocation);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateCornerRadius = (id, rounded) => {
    updateBot(id, { rounded })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateLeadsFormTitle = (id, leadsFormTitle) => {
    updateBot(id, { leadsFormTitle })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateLeadsForm = (id, leadsForm) => {
    updateBot(id, { leadsForm })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateBotStatus = (id, status) => {
    updateBot(id, { status })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateBotGuidelines = (id, chatbotGuidelines) => {
    updateBot(id, { chatbotGuidelines })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateBotTemplate = (id, botTemplate) => {
    updateBot(id, { botTemplate })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateResponseLength = (id, responseLength) => {
    updateBot(id, { responseLength })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateBotAssessment = (id, chatAssessment) => {
    updateBot(id, { chatAssessment })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateBotDomains = (id, domains) => {
    updateBot(id, { domains })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateBotCompanyLogo = (id, companyLogo) => {
    updateBot(id, { companyLogo })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateBotBubbleIcon = (id, bubbleButtonIcon) => {
    updateBot(id, { bubbleButtonIcon })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const updateBotWhiteLabel = (id, isWhiteLabel) => {
    updateBot(id, { isWhiteLabel })
      .then((result) => {
        logger.log(result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  return {
    updateBotTitle,
    updateBotName,
    updateBotInitialMessage,
    updateBotSuggestedMessages,
    updateBotInputFieldText,
    updateBotTheme,
    updateBotWidgetLocation,
    updateCornerRadius,
    updateLeadsFormTitle,
    updateLeadsForm,
    updateBotStatus,
    updateBotGuidelines,
    updateBotTemplate,
    updateResponseLength,
    updateBotAssessment,
    updateBotDomains,
    updateBotCompanyLogo,
    updateBotBubbleIcon,
    updateBotWhiteLabel,
  };
};
