import {
  chatBotsIcons,
  chatsIcons,
  dashboardIcons,
  pluginIcons,
  profileIcons,
  settingsIcons,
  supportIcons,
} from './iconsHelper';

export const leadsFieldsTypes = ['Text', 'Email', 'Phone'];

export const mainNavigationItems = [
  {
    id: 'chatbots',
    icon: chatBotsIcons,
    isMain: true,
  },
  {
    id: 'profile',
    icon: profileIcons,
    isMain: true,
  },
  {
    id: 'support',
    icon: supportIcons,
    isMain: true,
  },
];

export const subNavigationItems = [
  {
    id: 'dashboard',
    icon: dashboardIcons,
    isSub: true,
  },
  {
    id: 'chats',
    icon: chatsIcons,
    isSub: true,
  },
  {
    id: 'settings',
    icon: settingsIcons,
    isSub: true,
  },
  {
    id: 'plugin',
    icon: pluginIcons,
    isSub: true,
  },
];
