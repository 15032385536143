import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';

import { cva } from 'class-variance-authority';
import { cn } from 'utils/cn';

const buttonVariants = cva(
  'flex items-center justify-center gap-[5px] text-base font-medium whitespace-nowrap rounded-xl px-4 py-1 w-fit border border-transparent transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 min-h-[50px]',
  {
    variants: {
      variant: {
        default: 'bg-main text-white shadow hover:bg-mainHover',
        destructive: 'bg-destructive text-white hover:bg-destructiveHover',
        outline: 'border-border hover:border-main',
        colorOutline: 'border-main hover:border-mainHover',
        secondary: 'bg-secondary text-whiteBlack hover:bg-secondaryHover',
        ghost: 'hover:border-border text-grayText',
        redGhost: 'text-destructive hover:border-destructive',
        logout: 'border-blackWhite text-blackWhite hover:bg-border',
        login:
          'bg-white dark:bg-border border border-border hover:bg-border shadow-loginShadow',
        disabled: 'bg-grayText text-white cursor-not-allowed',
        disabledOutline: 'border-border text-grayText cursor-not-allowed',
        icon: 'bg-transparent',
      },
      size: {
        default: 'min-w-[280px]',
        sm: 'min-w-fit min-h-fit px-2.5 rounded-xl',
        content: 'min-w-fit min-h-10 max-h-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

const Button = React.forwardRef(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
