import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useAnimations } from 'hooks/useAnimations';
import { cn } from 'utils/cn';

import { ChatFooter } from './ChatFooter';
import { ChatHeader } from './ChatHeader';
import { ChatMain } from './ChatMain';

export const ChatPreview = () => {
  const { chatPreviewAnimation } = useAnimations();

  const chatPreviewRef = useRef(null);

  const { chosenTheme, cornerRadius, theme } = useSelector(
    (state) => state.chatbotDesign,
  );
  const { chatbot } = useSelector((state) => state.chatbot);
  const { isPreviewOpen } = useSelector((state) => state.chatPreview);
  const isWhiteLabel = chatbot.settings?.isWhiteLabel;

  useEffect(() => {
    chatPreviewAnimation(chatPreviewRef);
  }, [isPreviewOpen]);

  return (
    <div
      className={cn(
        'relative flex max-h-[538px] min-h-[538px] min-w-[340px] max-w-[340px] flex-auto flex-col gap-2.5 overflow-hidden p-2.5',
        cornerRadius === 'rounded' && 'rounded-2xl',
      )}
      style={{
        color:
          chosenTheme === 'Main' ? theme.light.textColor : theme.dark.textColor,
        backgroundColor:
          chosenTheme === 'Main'
            ? theme.light.frameColor
            : theme.dark.frameColor,
        boxShadow:
          chosenTheme === 'Main'
            ? `${theme.light.shadow.shadowOffsetX}px ${theme.light.shadow.shadowOffsetY}px ${theme.light.shadow.shadowBlur}px ${theme.light.shadow.shadowSpread}px ${theme.light.shadow.shadowColor}`
            : `${theme.dark.shadow.shadowOffsetX}px ${theme.dark.shadow.shadowOffsetY}px ${theme.dark.shadow.shadowBlur}px ${theme.dark.shadow.shadowSpread}px ${theme.dark.shadow.shadowColor}`,
      }}
      ref={chatPreviewRef}
    >
      <ChatHeader />
      <ChatMain />
      {!isWhiteLabel && <ChatFooter />}
    </div>
  );
};
