import React from 'react';

import { cn } from 'utils/cn';

export const Step = ({ title, Icon, active, success, onClick }) => (
  <button
    className={cn(
      '[&:not(:first-child)]:before:content-"" [ relative flex flex-col items-center gap-3 [&:not(:first-child)]:before:absolute [&:not(:first-child)]:before:right-[calc(100%+4px)] [&:not(:first-child)]:before:top-1/2 [&:not(:first-child)]:before:h-0.5 [&:not(:first-child)]:before:w-[72px] [&:not(:first-child)]:before:bg-border ',
    )}
    onClick={onClick}
  >
    <div
      className={cn(
        'flex size-10 items-center justify-center rounded-full border-2 border-border bg-tableBg duration-200 hover:border-main',
        (success || active) && 'border-main',
      )}
    >
      <Icon
        className={cn(
          'size-6 text-grayText',
          (success || active) && 'text-main',
        )}
      />
    </div>
    <p
      className={cn(
        'absolute -bottom-6 left-1/2 -translate-x-1/2 transform font-medium capitalize text-grayText',
        (success || active) && 'text-main',
      )}
    >
      {title}
    </p>
  </button>
);
