import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUpdateBot } from 'hooks/useUpdateBot';
import { Dropdown, InputTitle, Status } from 'ui-elements';

import { setChatAssessment } from '../../../../redux/slices/chatbotPreview/settingsSlice';

export const ChatAssessment = ({ chatbotId }) => {
  const dispatch = useDispatch();
  const { chatAssessment } = useSelector((state) => state.chatbotSettings);
  const { updateBotAssessment } = useUpdateBot();

  const list = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div>
      <div className="relative w-fit">
        <InputTitle
          title="Chat assessment"
          tooltipText="Select a value (from 0 to 10 points) at which a notification is sent that the user is ready to take a targeted action"
        />
        <Status name='Soon' className='absolute top-0 left-[calc(100%+4px)]' />
      </div>
      <Dropdown
        title={chatAssessment}
        // list={list}
        // onClick={(value) => {
        //   dispatch(setChatAssessment(value));
        //   updateBotAssessment(chatbotId, value);
        // }}
        className='pointer-events-none opacity-40'
      />
    </div>
  );
};
