import { configureStore } from '@reduxjs/toolkit';

import animationSlice from './slices/animationSlice';
import asideSlice from './slices/asideSlice';
import chatMenuSlice from './slices/chatMenuSlice';
import chatbotCreatingSlice from './slices/chatbotCreating';
import chatbotDesignSlice from './slices/chatbotPreview/designSlice';
import chatbotPreviewSlice from './slices/chatbotPreview/index';
import leadsSlice from './slices/chatbotPreview/leadsSlice';
import chatbotSettingsSlice from './slices/chatbotPreview/settingsSlice';
import sourcesSlice from './slices/chatbotPreview/sourcesSlice';
import chatbotSlice from './slices/chatbotSlice';
import colorPickerSlice from './slices/colorPickerSlice';
import dashboardSlice from './slices/dashboardSlice';
import dropdownSlice from './slices/dropdownSlice';
import menuSlice from './slices/menuSlice';
import popupSlice from './slices/popupSlice';
import settingsSlice from './slices/settingsSlice';
import stripeSlice from './slices/stripeSlice';
import userSlice from './slices/userSlice';

export const store = configureStore({
  reducer: {
    animation: animationSlice,
    aside: asideSlice,
    chatMenu: chatMenuSlice,
    chatbot: chatbotSlice,
    chatbotCreating: chatbotCreatingSlice,
    chatbotDesign: chatbotDesignSlice,
    chatbotSettings: chatbotSettingsSlice,
    chatPreview: chatbotPreviewSlice,
    colorPicker: colorPickerSlice,
    dashboard: dashboardSlice,
    dropdown: dropdownSlice,
    leadsSlice,
    menu: menuSlice,
    popup: popupSlice,
    settings: settingsSlice,
    sources: sourcesSlice,
    stripe: stripeSlice,
    user: userSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
