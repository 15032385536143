import React from 'react';

import { SubStepContent, TableMenu } from 'components';
import {
  TableContentWrapper,
  TableMenuWrapper,
  TableWrapper,
  UsedSymbols,
} from 'ui-elements';

export const Sources = () => {
  return (
    <TableWrapper>
      <TableMenuWrapper>
        <TableMenu />
        <UsedSymbols />
      </TableMenuWrapper>
      <TableContentWrapper>
        <SubStepContent />
      </TableContentWrapper>
    </TableWrapper>
  );
};
