import { BoltIcon } from '@heroicons/react/24/solid';
import React from 'react';

import useAppNavigation from 'hooks/useAppNavigation';
import { usePopup } from 'hooks/usePopup';
import { Button, PopupShadow, PopupWrapper } from 'ui-elements';

export const CreateBotPopup = () => {
  const { closeCreateBotPopup } = usePopup();
  const { getPath, goToPlugin } = useAppNavigation();
  const chatbotId = getPath().pathArray[2];
  
  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <PopupWrapper className="h-fit w-[420px]">
        <h3>
          Congratulations, you have successfully created a chat bot 🎉 <br />
          Test it before plugging it into your site
        </h3>
        <footer>
          <Button
            onClick={() => {
              closeCreateBotPopup();
              // add loading?
              goToPlugin(chatbotId);
            }}
          >
            <BoltIcon className="size-5 text-yellow" />
            Test
          </Button>
        </footer>
      </PopupWrapper>
      <PopupShadow />
    </div>
  );
};
