import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUpdateBot } from 'hooks/useUpdateBot';
import { InputTitle, TextInput } from 'ui-elements';

import { setInitialMessage } from '../../../../redux/slices/chatbotPreview/settingsSlice';

export const InitialMessage = ({ chatbotId }) => {
  const dispatch = useDispatch();
  const { initialMessage } = useSelector((state) => state.chatbotSettings);
  const { updateBotInitialMessage } = useUpdateBot();

  return (
    <div>
      <InputTitle
        title="Initial Message"
        tooltipText="The first message that is displayed to the user when they interact with the chatbot"
      />
      <TextInput
        value={initialMessage}
        placeholder="Hi! How can I help you?"
        onChange={(e) => dispatch(setInitialMessage(e.target.value))}
        onBlur={() => updateBotInitialMessage(chatbotId, initialMessage)}
      />
    </div>
  );
};
