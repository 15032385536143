import React, { forwardRef } from 'react';

import { cn } from 'utils/cn';

export const IconWrapper = forwardRef(
  ({ className, children, style, onClick }, ref) => (
    <div
      className={cn(
        'flex min-h-14 min-w-14 items-center justify-center overflow-hidden rounded-full border border-gray',
        className,
      )}
      style={style}
      ref={ref}
      onClick={onClick}
    >
      <div className="size-6 [&>*]:h-full [&>*]:w-full [&>*]:bg-contain [&>*]:bg-center [&>*]:bg-no-repeat">
        {children}
      </div>
    </div>
  ),
);
IconWrapper.displayName = 'IconWrapper';
