import { Bars3Icon, TrashIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useLeadsForm } from 'hooks/useLeadsForm';
import { Checkbox, Dropdown, TextInput } from 'ui-elements';
import { cn } from 'utils/cn';
import { leadsFieldsTypes } from 'utils/data';

export const FormItem = ({ index, field }) => {
  const {
    handleRequiredChange,
    handleRemoveField,
    handleFieldChange,
    handleFieldTypeChange,
  } = useLeadsForm();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: field.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="grid grid-cols-[24px_1fr_1fr_20px_24px] items-center gap-3 rounded "
    >
      <button
        {...attributes}
        {...listeners}
        className="cursor-grab active:cursor-grabbing"
      >
        <Bars3Icon className="size-6" />
      </button>
      <Dropdown
        title={field.type || 'Choose type'}
        list={leadsFieldsTypes}
        onClick={(item) => {
          if (field.title !== 'Email') {
            handleFieldTypeChange(index, item);
          }
        }}
        disabled={field.title === 'Email'}
      />
      <TextInput
        placeholder="Field title"
        value={field.title}
        onChange={(e) => handleFieldChange(index, e.target.value)}
        readOnly={field.title === 'Email'}
      />
      <Checkbox
        checked={field.isRequired}
        onChange={() => handleRequiredChange(index)}
        disabled={field.title === 'Email'}
        tooltipText={
          field.title === 'Email'
            ? 'Email is required'
            : 'Check the mandatory fields'
        }
      />
      <button
        onClick={() => {
          if (field.title !== 'Email') {
            handleRemoveField(index);
          }
        }}
      >
        <TrashIcon
          className={cn(
            'size-6 text-destructive',
            field.title === 'Email' && 'cursor-not-allowed text-grayText',
          )}
        />
      </button>
    </div>
  );
};
