import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useSources } from 'hooks/useSources';
import { Button, TextInput, Toggler } from 'ui-elements';
import { cn } from 'utils/cn';
import { isValidUrl } from 'utils/validations';

export const Website = () => {
  const [sourceLink, setSourceLink] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isAllPagesIncluded, setIsAllPagesIncluded] = useState(false);

  const { saveWebsite } = useSources();

  const { chatBotFiles } = useSelector((state) => state.chatbot);

  const sourcesLinks = chatBotFiles.reduce((acc, file) => {
    if (file.source === 'WEB_SCRAPE') {
      acc.push(file.name);
    }
    return acc;
  }, []);

  const handleSourceLinkChange = useCallback((e) => {
    const newValue = e.target.value;
    setSourceLink(newValue);
    setIsValid(isValidUrl(newValue) || newValue === '');
  }, []);

  const handleScan = useCallback(() => {
    if (isValidUrl(sourceLink)) {
      setSourceLink('');
      saveWebsite(sourceLink, isAllPagesIncluded);
    } else {
      setIsValid(false);
    }
  }, [sourceLink, isAllPagesIncluded, saveWebsite]);

  return (
    <>
      <header>
        <h2>Website</h2>
        <p>
          If you have an e-commerce website or offer specific products,services,
          including links in the chatbot can help promote them and potentially
          lead to conversions. You can include links like
          <strong> https://example.com </strong>or simply
          <strong> example.com</strong>.
        </p>
      </header>
      <div className="">
        <div className="flex flex-col gap-1">
          <div className="flex gap-4">
            <TextInput
              className={cn('w-full', !isValid ? 'border-red-500' : '')}
              placeholder="Enter URL"
              value={sourceLink}
              onChange={handleSourceLinkChange}
              maxLength={500}
            />
            <Button
              className="min-w-32"
              variant="colorOutline"
              onClick={handleScan}
              disabled={!isValid || sourceLink === ''}
            >
              Scan
            </Button>
          </div>
          {!isValid && sourceLink !== '' && (
            <p className="text-sm text-red-500">
              Please enter a valid URL (e.g., example.com or
              https://example.com)
            </p>
          )}
        </div>
        <div className="flex items-center gap-4">
          <Toggler
            checked={isAllPagesIncluded}
            onChange={() => setIsAllPagesIncluded(!isAllPagesIncluded)}
          />
          <p>
            Include all the links starting with the URL (not including files on
            the website).
          </p>
        </div>
      </div>
      <h3 className="mt-5">Included Links</h3>
      <TextInput value={sourcesLinks} maxLength={1000} />
    </>
  );
};
