import React from 'react';

import { useAuthorization } from 'hooks/useAuthorization';

import { Loader } from './Loader';

export const PageWrapper = ({ children }) => {
  const { pageLoading } = useAuthorization();

  return (
    <div className="pageWrapper flex flex-auto overflow-hidden [&>*]:flex [&>*]:flex-auto [&>*]:flex-col">
      {pageLoading ? <Loader /> : children}
    </div>
  );
};
