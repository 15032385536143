import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { showToastError } from 'components/CustomToast';
import { useWebSocket } from 'context/WebSocketContext';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import useAppNavigation from 'hooks/useAppNavigation';
import { auth } from 'utils/firebaseHelper';

import { setPageLoading } from '../redux/slices/settingsSlice';
import logger from 'services/logger';

export const useLogin = () => {
  const dispatch = useDispatch();
  const { isConnected } = useWebSocket();
  const { goToChatbots } = useAppNavigation();

  const signInWithGoogle = useCallback(async (event) => {
    event.preventDefault();
    try {
      dispatch(setPageLoading(true));
      await signInWithPopup(auth, new GoogleAuthProvider().addScope('email'));
    } catch (error) {
      handleError(error);
    }
  }, []);

  const handleError = useCallback((error) => {
    logger.log(error);
    
    dispatch(setPageLoading(false));
    showToastError(
      'Something went wrong with your authorization. Please, try again.',
    );
  }, []);

  useEffect(() => {
    auth.onIdTokenChanged((user) => {
      if (user) {
        if (window.tolt_referral) {
          window.tolt.signup(user.email);
        }
        if (isConnected) {
          goToChatbots();
        }
      } else {
        dispatch(setPageLoading(false));
      }
    });
  }, [isConnected]);

  return {
    signInWithGoogle,
  };
};
