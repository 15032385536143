import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUpdateBot } from 'hooks/useUpdateBot';
import { Dropdown, InputTitle } from 'ui-elements';

import { setResponseLength } from '../../../../redux/slices/chatbotPreview/settingsSlice';

export const ResponseLength = ({ chatbotId }) => {
  const dispatch = useDispatch();
  const { responseLength } = useSelector((state) => state.chatbotSettings);
  const { updateResponseLength } = useUpdateBot();

  const list = ['Short', 'Medium', 'Long'];

  return (
    <div>
      <InputTitle title="Response Length" />
      <Dropdown
        title={responseLength}
        list={list}
        onClick={(value) => {
          dispatch(setResponseLength(value));
          updateResponseLength(chatbotId, value);
        }}
      />
    </div>
  );
};
