import React from 'react';
import { useSelector } from 'react-redux';

import { Button, InputTitle, PopupWrapper, TextInput } from 'ui-elements';
import { cn } from 'utils/cn';

export const UserFormPopup = ({ className }) => {
  const { leadsFormTitle, leadsForm } = useSelector(
    (state) => state.leadsSlice,
  );
  const { theme, chosenTheme, cornerRadius } = useSelector(
    (state) => state.chatbotDesign,
  );

  return (
    <div className={cn('', className)}>
      <PopupWrapper
        className={cn(
          'gap-1.5 p-3 !shadow-none',
          cornerRadius === 'unrounded' && 'rounded-none',
        )}
        style={{
          backgroundColor:
            chosenTheme === 'Main'
              ? theme.light.frameColor
              : theme.dark.frameColor,
        }}
        isAnimation={false}
      >
        <p>{leadsFormTitle}</p>
        {leadsForm?.map((item, index) => (
          <div className="" key={index}>
            <InputTitle
              className="!mb-0.5"
              title={item.isRequired ? `${item.title} *` : item.title}
            />
            <TextInput
              value={item.value}
              className={cn(
                'flex max-h-10 min-h-10 items-center border-none py-0',
                cornerRadius === 'unrounded' && 'rounded-none',
              )}
              style={{
                backgroundColor:
                  chosenTheme === 'Main'
                    ? theme.light.backgroundColor
                    : theme.dark.backgroundColor,
              }}
              readOnly
            />
          </div>
        ))}

        <Button
          size="sm"
          className={cn(
            'mt-4 min-h-10',
            cornerRadius === 'unrounded' && 'rounded-none',
          )}
          style={{
            backgroundColor:
              chosenTheme === 'Main'
                ? theme.light.accentColor
                : theme.dark.accentColor,
            color:
              chosenTheme === 'Main'
                ? theme.light?.accentTextColor
                : theme.dark?.accentTextColor,
          }}
        >
          Send
        </Button>
      </PopupWrapper>
    </div>
  );
};
