import log from "loglevel";

const logger = log.getLogger("ai-desk");

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  logger.setLevel("debug"); // Enable debug level logs in development
} else {
  logger.setLevel("error"); // Only log errors in production
}

export default logger;
