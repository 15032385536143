export const getCoordinates = (ref) => {
  if (ref.current) {
    const rect = ref.current.getBoundingClientRect();
    return {
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
    };
  }
  return null;
};
