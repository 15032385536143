import {
  ChatBubbleOvalLeftEllipsisIcon,
  FireIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardContent } from 'components';
import {
  HeaderWrapper,
  PageContent,
  TableContentWrapper,
  TableMenuItem,
  TableMenuWrapper,
  TableWrapper,
} from 'ui-elements';
import { cn } from 'utils/cn';

import { setActiveDashboardMenuItem } from '../redux/slices/dashboardSlice';

export const Dashboard = () => {
  const dispatch = useDispatch();
  const { chatsQuantity, hotLeadsQuantity, activeDashboardMenuItem } =
    useSelector((state) => state.dashboard);
  const { chatBotFiles } = useSelector((state) => state.chatbot);

  const menuItems = [
    {
      title: 'Chats',
      icons: ChatBubbleOvalLeftEllipsisIcon,
      quantity: chatsQuantity,
    },
    {
      title: 'Leads',
      icons: FireIcon,
      quantity: hotLeadsQuantity,
    },
  ];

  return (
    <div className="flex flex-auto">
      <PageContent>
        <HeaderWrapper>
          <h2>Dashboard</h2>
        </HeaderWrapper>
        <div className="flex flex-auto">
          <TableWrapper>
            <TableMenuWrapper>
              <div className="flex flex-col gap-1">
                {menuItems.map((item, idx) => (
                  <TableMenuItem
                    key={item.title}
                    onClick={() => dispatch(setActiveDashboardMenuItem(idx))}
                  >
                    <span
                      className={cn(
                        'flex items-center gap-2.5',
                        activeDashboardMenuItem === idx && 'text-main',
                      )}
                    >
                      <item.icons className="size-5" />
                      {item.title}
                    </span>
                    {item.quantity > 0 && (
                      <span className="ml-auto block text-main">
                        {item.quantity}
                      </span>
                    )}
                  </TableMenuItem>
                ))}
              </div>
            </TableMenuWrapper>
            <TableContentWrapper>
              <DashboardContent />
            </TableContentWrapper>
          </TableWrapper>
        </div>
      </PageContent>
    </div>
  );
};
