import React from 'react';

import { ChatPreviewWrapper, SubStepContent, TableMenu } from 'components';
import {
  TableContentWrapper,
  TableMenuWrapper,
  TableWrapper,
} from 'ui-elements';

export const Settings = () => {
  return (
    <div>
      <TableWrapper>
        <TableMenuWrapper>
          <TableMenu />
        </TableMenuWrapper>
        <TableContentWrapper>
          <SubStepContent />
        </TableContentWrapper>
      </TableWrapper>
      <ChatPreviewWrapper />
    </div>
  );
};
