import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAppNavigation from 'hooks/useAppNavigation';
import { useUpdateBot } from 'hooks/useUpdateBot';
import { TextInput, Toggler } from 'ui-elements';

import {
  addDomain,
  changeDomain,
  removeDomain,
  setDomains,
} from '../../../../redux/slices/chatbotPreview/settingsSlice';

export const Security = () => {
  const dispatch = useDispatch();
  const { domains } = useSelector((state) => state.chatbotSettings);
  const { updateBotDomains } = useUpdateBot();
  const { getPath } = useAppNavigation();
  const chatbotId = getPath().pathArray[2];

  const [isSecure, setIsSecure] = useState(domains[0] === '*' ? false : true);

  const setSecurity = (prev) => {
    setIsSecure((prev) => !prev);
    if (prev) {
      dispatch(setDomains(['*']));
    } else {
      dispatch(setDomains(['']));
    }
  };

  const handleChangeDomain = (index, domain) => {
    dispatch(changeDomain({ index, domain }));
  };

  const handleAddDomain = () => {
    if (domains.length < 5) {
      dispatch(addDomain());
    }
  };

  useEffect(() => {
    updateBotDomains(chatbotId, domains);
  }, [domains.length]);

  // TODO: domain validation and cut it off correctly on blur

  return (
    <div className="flex flex-col gap-5">
      <h2>Security</h2>
      <div className="flex items-center gap-2.5">
        <Toggler checked={isSecure} onChange={() => setSecurity(isSecure)} />
        <p>Allow requests to your bot only on specific domains</p>
      </div>
      {isSecure && (
        <div className="flex flex-col gap-5">
          <h3>Domains</h3>
          {domains.map((domain, index) => (
            <div key={index} className="flex items-center gap-2.5">
              <TextInput
                value={domain}
                placeholder='https://example.com'
                onChange={(e) => handleChangeDomain(index, e.target.value)}
                onBlur={() => updateBotDomains(chatbotId, domains)}
              />
              {index === domains.length - 1 && (
                <button onClick={() => handleAddDomain()}>
                  <PlusIcon className="size-5" />
                </button>
              )}
              {index !== domains.length - 1 && (
                <button onClick={() => dispatch(removeDomain(index))}>
                  <TrashIcon className="size-5 hover:text-destructive" />
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
