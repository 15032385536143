import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useRef, useState } from 'react';

import { docIcons, docxIcons, pdfIcons, txtIcons } from 'utils/iconsHelper';

const ImageConfig = {
  pdf: pdfIcons,
  msword: docIcons,
  docx: docxIcons,
  txt: txtIcons,
  default: txtIcons,
};

export const DropFileInput = ({
  onFileChange,
  inputText,
  inputDescription,
  file,
  setFile,
}) => {
  const [fileIsLoading, setFileIsLoading] = useState(false);
  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add('opacity-80');

  const onDragLeave = () => wrapperRef.current.classList.remove('opacity-80');

  const onDrop = () => wrapperRef.current.classList.remove('opacity-80');

  return (
    <div
      ref={wrapperRef}
      className="relative block w-full rounded-2xl border border-dashed border-border bg-list p-2.5 transition-opacity duration-200 hover:opacity-80 [&+&]:mt-4"
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className="p-2.5 text-center font-semibold">
        {!fileIsLoading && !file && (
          <div>
            <p>{inputText}</p>
            <p className="text-grayText">{inputDescription}</p>
          </div>
        )}
        <input
          type="file"
          accept=".pdf, .doc, .docx"
          value=""
          onChange={onFileChange}
          className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
        />
        {!fileIsLoading && file ? (
          <div className="">
            <img
              className="h-10 w-10"
              src={ImageConfig[file.type.split('/')[1]] || ImageConfig.default}
              alt=""
            />
            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-center">
              {file.name}
            </p>
            <XMarkIcon
              className="absolute right-2.5 top-2.5 h-5 w-5 cursor-pointer"
              onClick={() => setFile(null)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
