import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import logger from 'services/logger';
import { v4 as uuidv4 } from 'uuid';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const socket = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isConnectionStabled, setIsConnectionStabled] = useState(false);
  const responseHandlers = useRef(new Map());
  const reconnectInterval = useRef(null);

  const connectWebSocket = useCallback(() => {
    logger.debug('Connecting to WebSocket...');
    socket.current = new WebSocket(process.env.REACT_APP_BASE_URL);

    const openHandler = () => {
      logger.debug('WebSocket connected');
      setIsConnected(true);
      if (!reconnectInterval.current) {
        setIsConnectionStabled(true);
      }
      clearInterval(reconnectInterval.current);
      reconnectInterval.current = null;
    };

    const closeHandler = () => {
      logger.debug('WebSocket disconnected');
      setIsConnected(false);
      if (!reconnectInterval.current) {
        setIsConnectionStabled(false);
        reconnectInterval.current = setInterval(() => {
          logger.debug('Attempting to reconnect...');
          connectWebSocket();
        }, 1000);
      }
    };

    const errorHandler = (error) => {
      logger.error('WebSocket error:', error);
    };

    const messageHandler = (event) => {
      const data = JSON.parse(event.data);
      !data.isStream && logger.debug('Received message:', data);
      const { requestId, code, isStream, isStreamEnd, chunk, ...responseData } = data;

      const handler = responseHandlers.current.get(requestId);
      if (handler) {
        if (handler.signal && handler.signal.aborted) {
          handler.reject(new DOMException('Aborted', 'AbortError'));
          responseHandlers.current.delete(requestId);
        } else if (code === 200) {
          if (isStream) {
            handler.result = (handler.result || '') + chunk;

            if (handler.onChunk) {
              handler.onChunk(chunk);
            }
          } else if (isStreamEnd) {
            handler.resolve(handler.result || responseData);
            responseHandlers.current.delete(requestId);
          } else {
            handler.resolve(responseData);
          }
        } else {
          logger.error('Error response received:', responseData);
          handler.reject(responseData.message);
          responseHandlers.current.delete(requestId);
        }
      }
    };

    socket.current.onopen = openHandler;
    socket.current.onclose = closeHandler;
    socket.current.onerror = errorHandler;
    socket.current.onmessage = messageHandler;
  }, []);

  const sendRequest = useCallback(
    (method, args, options = {}) => {
      if (!socket.current || !isConnected) {
        logger.error('WebSocket connection not established or not ready');
        return Promise.reject('WebSocket is not connected or not ready');
      }

      const requestId = uuidv4();
      const packet = { method, args, requestId };
      logger.debug('Sending request:', packet);

      return new Promise((resolve, reject) => {
        const responseHandler = {
          resolve,
          reject,
          signal: options.signal,
          onChunk: options.onChunk
        };
        responseHandlers.current.set(requestId, responseHandler);

        if (options.signal) {
          options.signal.addEventListener('abort', () => {
            reject(new DOMException('Aborted', 'AbortError'));
            responseHandlers.current.delete(requestId);
          });
        }

        socket.current.send(JSON.stringify(packet));
      });
    },
    [isConnected],
  );

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
      }
      if (socket.current) {
        socket.current.close();
      }
    };
  }, [connectWebSocket]);

  const stableConnection = () => {
    logger.debug(isConnectionStabled);
    setIsConnectionStabled(true);
  };

  return (
    <WebSocketContext.Provider value={{ socket, isConnected, isConnectionStabled, sendRequest, stableConnection }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
