import React from 'react';
import { useSelector } from 'react-redux';

import { download, generateCsv, mkConfig } from 'export-to-csv';
import { usePopup } from 'hooks/usePopup';
import { Button, Notification } from 'ui-elements';
import { convertArrayOfObjectsToCSV } from 'utils/csv';

import { DashboardDatePicker } from '../DashboardDatePicker';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
  filename: 'leads',
});

export const LeadsHeader = ({
  selectedRowsQuantity,
  selectedRowIndexes,
  selectedRows,
  data,
}) => {
  const { openDeleteLeadsPopup, openCopyPopup, openUpgradeLeadsPopup } =
    usePopup();
  const { isAllInOne, isProAnalytic } = useSelector((state) => state.stripe);

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const downloadAnalytics = () => {
    if (!isAllInOne && !isProAnalytic) {
      openUpgradeLeadsPopup();
    } else {
      data.length > 0 && handleExportData();
    }
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-7">
        <h3>Leads:</h3>
        {selectedRowsQuantity > 0 && (
          <div className="flex gap-1">
            <Button
              variant="destructive"
              size="sm"
              onClick={() => openDeleteLeadsPopup(selectedRowIndexes)}
            >
              Delete
              <Notification className="!h-6 !max-h-6 !w-6 !max-w-6 border border-white bg-transparent">
                {selectedRowsQuantity}
              </Notification>
            </Button>
            <Button
              size="sm"
              onClick={() => {
                convertArrayOfObjectsToCSV(selectedRows);
                openCopyPopup('Successfully copied to clipboard');
              }}
            >
              Copy
              <Notification className="!h-6 !max-h-6 !w-6 !max-w-6 border border-white bg-transparent">
                {selectedRowsQuantity}
              </Notification>
            </Button>
          </div>
        )}
      </div>
      <div className="flex items-center gap-1">
        <DashboardDatePicker />
        <Button
          size="sm"
          onClick={() => downloadAnalytics()}
        >
          Download
        </Button>
      </div>
    </div>
  );
};
