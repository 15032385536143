// from timestamp -> 'Just now', '1 minute ago', '1 hour ago', '1 day ago', '1 month ago', '1 year ago'
export const lastSaved = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();
  const diff = now - date;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''} ago`;
  }
  if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''} ago`;
  }
  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  }
  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }
  if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  }
  if (seconds > 0) {
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  }
  return 'Just now';
};

// from timestamp -> '9:36' or 'Thu'
export const formatTime = (timestamp) => {
  const currentDate = new Date();
  const itemDate = new Date(timestamp);

  if (
    itemDate.getDate() === currentDate.getDate() &&
    itemDate.getMonth() === currentDate.getMonth() &&
    itemDate.getFullYear() === currentDate.getFullYear()
  ) {
    return itemDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  } else {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return daysOfWeek[itemDate.getDay()];
  }
};

// Feb 22 from timestamp
export const formatMonthDay = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });
};

// from to timestamps -> array of days
export const getDaysBetween = (fromTimestamp, toTimestamp) => {
  const days = [];

  // convert timestamps to Date objects
  const fromDate = new Date(fromTimestamp);
  const toDate = new Date(toTimestamp);

  // iterate through each day from 'fromDate' to 'toDate'
  for (let date = fromDate; date <= toDate; date.setDate(date.getDate() + 1)) {
    days.push(new Date(date)); // push a new Date object representing the current day
  }

  return days;
};

// Unix timestamp -> Next billing 25/05/2024 or Expires on 18/06/2024 if less than 3 days
export const formatEndSubDate = (subEnd, timestamp) => {
  const MS_IN_A_DAY = 24 * 60 * 60 * 1000;
  const now = new Date();
  const targetDate = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
  const diffInDays = (targetDate - now) / MS_IN_A_DAY;

  const formattedDate = targetDate.toLocaleDateString('en-GB'); // Format date as DD/MM/YYYY

  if (subEnd) {
    return `${diffInDays <= 0 ? 'Expired' : 'Expires'} on ${formattedDate}`;
  } else {
    return `Next billing ${formattedDate}`;
  }
};

export const isSubExpiringSoon = (timestamp) => {
  const MS_IN_A_DAY = 24 * 60 * 60 * 1000;
  const now = new Date();
  const targetDate = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
  const diffInDays = (targetDate - now) / MS_IN_A_DAY;

  return diffInDays < 3;
};
