import React from 'react';

import { cn } from 'utils/cn';

export const PopupShadow = ({ onClick, className }) => (
  <div
    onClick={onClick}
    className={cn(
      'fixed left-0 top-0 z-10 h-screen w-screen bg-black bg-opacity-30',
      className,
    )}
  ></div>
);
