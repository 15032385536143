import React from 'react';
import { useSelector } from 'react-redux';

import { EmbedOption } from 'components';
import useAppNavigation from 'hooks/useAppNavigation';
import { usePopup } from 'hooks/usePopup';
import { Button, HeaderWrapper, PageContent } from 'ui-elements';
import { lastSaved } from 'utils/timeHelper';

export const Plugin = () => {
  const { chatbot } = useSelector((state) => state.chatbot);

  const { openCopyPopup } = usePopup();

  const { getPath, goToSettings } = useAppNavigation();
  const chatbotId = getPath().pathArray[2];

  const baseChatUrl = process.env.REACT_APP_BASE_CHAT_URL;
  const scriptURL = `"${baseChatUrl}script/${chatbotId}" ai_support_source="https://aidesk.pro"`;
  const iframeURL = `"${baseChatUrl}iframe/${chatbotId}" ai_support_source="https://aidesk.pro"`;
  const script = `<script src=${scriptURL}></script>`;
  const iframe = `<iframe src=${iframeURL}></iframe>`;
  const copyScript = () => {
    navigator.clipboard.writeText(script);
    openCopyPopup('Script successfully copied');
  };

  const copyIframe = () => {
    navigator.clipboard.writeText(iframe);
    openCopyPopup('Iframe successfully copied');
  };

  return (
    <div className="overflow-hidden [&>*]:flex [&>*]:flex-auto [&>*]:overflow-hidden">
      <div className="flex-auto">
        <PageContent>
          <HeaderWrapper>
            <h2>Embed on your website</h2>
          </HeaderWrapper>
          <div className="flex flex-auto overflow-hidden">
            <div className="flex flex-col gap-10 overflow-hidden">
              <EmbedOption
                title="Script"
                index={1}
                description="To add the chat bubble to your website, add this script tag to your html code"
                onClick={copyScript}
                code={script}
              />
              <EmbedOption
                title="Iframe"
                index={2}
                description="To add the chatbot any where on your website, add this iframe to your html code"
                onClick={copyIframe}
                code={iframe}
                isDropdown
              />
            </div>
          </div>
        </PageContent>
        <div className="relative flex min-w-[600px] flex-col items-center overflow-y-auto border-l-[1px] border-whiteBlack bg-menuBg py-5 after:absolute after:left-0 after:top-0 after:h-full after:w-[1px] after:bg-gray after:content-['']">
          <div className="flex w-full flex-col items-center">
            <h2 className="h-10 px-5">Test your chat bot</h2>
            <iframe
              id="chatbotIframe"
              src={`${baseChatUrl}iframe/${chatbotId}`}
              title="chatbot"
              style={{ width: '100%', height: '640px' }}
            ></iframe>
            <script src={scriptURL}></script>
            <div className="mx-auto w-full max-w-[340px]">
              <p>Last trained: {lastSaved(chatbot?.updatedAt)}</p>
            </div>
            <Button
              className="mx-5 mt-5"
              onClick={() => goToSettings(chatbotId)}
            >
              Edit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
