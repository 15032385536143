import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isHideMenu: true,
  isOpenMenu: true,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setIsHideMenu: (state, action) => {
      state.isHideMenu = action.payload;
    },
    toggleMenu: (state) => {
      state.isOpenMenu = !state.isOpenMenu;
    }
  }
});

export const { setIsHideMenu, toggleMenu } = menuSlice.actions;

export default menuSlice.reducer;