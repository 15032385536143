import React from 'react';

import { usePopup } from 'hooks/usePopup';
import { Button, PopupShadow, PopupWrapper } from 'ui-elements';
import { useChatbotActions } from 'hooks/useChatbotActions';

export const DeleteChatbotPopup = () => {
  const { closeDeleteChatbotPopup } = usePopup();
  const { deleteChatbot } = useChatbotActions();
  return (
    <div>
      <PopupWrapper className="fixed left-1/2 top-1/2 w-72 -translate-x-1/2 -translate-y-1/2 transform p-5 !shadow-none">
        <h3>Delete chatbot?</h3>
        <footer>
          <Button
            size="sm"
            variant="outline"
            className="p-2"
            onClick={() => closeDeleteChatbotPopup()}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="destructive"
            className="p-2"
            onClick={() => {
              closeDeleteChatbotPopup();
              deleteChatbot();
            }}
          >
            Delete
          </Button>
        </footer>
      </PopupWrapper>
      <PopupShadow />
    </div>
  );
};
