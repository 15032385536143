import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import React, { useRef } from 'react';

import { useAnimations } from 'hooks/useAnimations';
import useAppNavigation from 'hooks/useAppNavigation';
import { usePopup } from 'hooks/usePopup';
import { Button } from 'ui-elements';

export const Footer = ({ id, status }) => {
  const { getCursorCoordinates } = useAnimations();
  const { openChatbotActionsPopup } = usePopup();
  const { openChatbot } = useAppNavigation();

  const chatbotActionsRef = useRef(null);

  return (
    <footer className="flex gap-2">
      <Button
        size="content"
        className="w-full"
        onClick={(e) => {
          openChatbot(status, id);
          getCursorCoordinates({ y: e.clientY });
        }}
      >
        Open chatbot
      </Button>
      <Button
        size="content"
        variant="outline"
        className="aspect-square !min-h-10 p-0"
        ref={chatbotActionsRef}
        onClick={() => openChatbotActionsPopup(chatbotActionsRef, id)}
      >
        <EllipsisVerticalIcon className="size-6" />
      </Button>
    </footer>
  );
};
