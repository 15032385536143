import { ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';

import { cn } from 'utils/cn';

import { ContentWrapper } from './ContentWrapper';
import { TriggerButton } from './TriggerButton';

export const Dropdown = ({ title, list, onClick, disabled, className }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState(title);
  const dropdownRef = useRef(null);

  const openDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpenDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={cn(disabled && 'pointer-events-none', className)}
      ref={dropdownRef}
    >
      <TriggerButton onClick={openDropdown} isOpenDropdown={isOpenDropdown}>
        <span className="font-normal">{title || selectedItem}</span>
        <ChevronDownIcon
          className={cn(
            'size-4 duration-200',
            isOpenDropdown && '-scale-y-100',
            disabled && 'hidden',
          )}
        />
      </TriggerButton>
      {isOpenDropdown && (
        <ContentWrapper
          list={list}
          onClick={onClick}
          selectedItem={selectedItem}
          setIsOpenDropdown={setIsOpenDropdown}
          setSelectedItem={setSelectedItem}
        />
      )}
    </div>
  );
};
