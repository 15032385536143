import { createSlice } from '@reduxjs/toolkit';
import { addDays, format } from 'date-fns';

const initialState = {
  chatsQuantity: 0,
  hotLeadsQuantity: 0,
  activeDashboardMenuItem: 0,
  chatsOverviewsDate: {
    from: format(addDays(Date.now(), -7), 'yyyy-MM-dd'),
    to: format(addDays(Date.now(), 0), 'yyyy-MM-dd'),
  },
  leadsDate: {
    from: format(addDays(Date.now(), -7), 'yyyy-MM-dd'),
    to: format(addDays(Date.now(), 0), 'yyyy-MM-dd'),
  },
  leadsData: [],
  chatsOverview: [],
  selectedRowIndexes: [],
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setChatsQuantity: (state, action) => {
      state.chatsQuantity = action.payload;
    },
    setHotLeadsQuantity: (state, action) => {
      state.hotLeadsQuantity = action.payload;
    },
    setActiveDashboardMenuItem: (state, action) => {
      state.activeDashboardMenuItem = action.payload;
    },
    setChatsOverviewsDate: (state, action) => {
      state.chatsOverviewsDate = action.payload;
    },
    setLeadsDate: (state, action) => {
      state.leadsDate = action.payload;
    },
    setLeadsData: (state, action) => {
      state.leadsData = action.payload;
    },
    setChatOverview: (state, action) => {
      state.chatsOverview = action.payload;
    },
    setSelectedRowIndexes: (state, action) => {
      state.selectedRowIndexes = action.payload;
    },
    removeLeadsByChatIds: (state, action) => {
      const chatIds = action.payload;
      state.leadsData = state.leadsData.filter((lead) => !chatIds.includes(lead.chatId));
    }
  },
});

export const {
  setChatsQuantity,
  setHotLeadsQuantity,
  setActiveDashboardMenuItem,
  setChatsOverviewsDate,
  setLeadsDate,
  setLeadsData,
  setChatOverview,
  setSelectedRowIndexes,
  removeLeadsByChatIds,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
