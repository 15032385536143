import React from 'react';
import { useSelector } from 'react-redux';

import { CancelBotCreationPopup } from './CancelBotCreationPopup';
import { CancelSubscriptionPopup } from './CancelSubscriptionPopup';
import { ChatbotActionsPopup } from './ChatbotActionsPopup';
import { ClientInfoPopup } from './ClientInfoPopup';
import { ColorPickerPopup } from './ColorPickerPopup';
import { CopyPopup } from './CopyPopup';
import { CreateBotPopup } from './CreateBotPopup';
import { CustomShadowPopup } from './CustomShadowPopup';
import { DeleteLeadsPopup } from './DeleteLeadsPopup';
import { MoreStoragePopup } from './MoreStorage/MoreStoragePopup';
import { PaywallPopup } from './PaywallPopup';
import { RenewSubscriptionPopup } from './RenewSubscriptionPopup';
import { SubscriptionCanceledPopup } from './SubscriptionCanceledPopup';
import { SubscriptionRenewedPopup } from './SubscriptionRenewedPopup';
import { UpgradeLeadsPopup } from './UpgradeLeadsPopup';
import { UpgradePopup } from './UpgradePopup';
import { WelcomePopup } from './Welcome';
import { DeleteChatbotPopup } from './DeleteChatbotPopup';
import { PaywallTrial } from './PaywallTrial';

export const Popups = () => {
  const {
    isWelcomePopup,
    isCancelBotCreationPopup,
    isColorPickerPopup,
    isUpgradePopup,
    isCustomShadowPopup,
    isClientInfoPopup,
    isDeleteLeadsPopup,
    isCopyPopup,
    isUpgradeLeadsPopup,
    isCreateBotPopup,
    isChatbotActionsPopup,
    isPaywallPopup,
    isCancelSubscriptionPopup,
    isSubscriptionCanceledPopup,
    isMoreStoragePopup,
    isRenewSubscriptionPopup,
    isSubscriptionRenewedPopup,
    isDeleteChatbotPopup,
    isTrialPaywallPopup
  } = useSelector((state) => state.popup);
  const { isAIFunctionality, isAllInOne, isProAnalytic } = useSelector(
    (state) => state.stripe,
  );

  return (
    <div className="fixed left-0 top-0 z-20 h-0 w-0">
      {isWelcomePopup && <WelcomePopup />}
      {isCancelBotCreationPopup && <CancelBotCreationPopup />}
      {isUpgradePopup && (!isAIFunctionality || !isAllInOne) && (
        <UpgradePopup />
      )}
      {isTrialPaywallPopup && <PaywallTrial />}
      {isDeleteChatbotPopup && <DeleteChatbotPopup />}
      {isCustomShadowPopup && <CustomShadowPopup />}
      {isColorPickerPopup && <ColorPickerPopup />}
      {isClientInfoPopup && <ClientInfoPopup />}
      {isDeleteLeadsPopup && <DeleteLeadsPopup />}
      {isCopyPopup && <CopyPopup />}
      {isUpgradeLeadsPopup && (!isProAnalytic || !isAllInOne) && (
        <UpgradeLeadsPopup />
      )}
      {isCreateBotPopup && <CreateBotPopup />}
      {isChatbotActionsPopup && <ChatbotActionsPopup />}
      {isPaywallPopup && <PaywallPopup />}
      {isCancelSubscriptionPopup && <CancelSubscriptionPopup />}
      {isSubscriptionCanceledPopup && <SubscriptionCanceledPopup />}
      {isMoreStoragePopup && <MoreStoragePopup />}
      {isRenewSubscriptionPopup && <RenewSubscriptionPopup />}
      {isSubscriptionRenewedPopup && <SubscriptionRenewedPopup />}
    </div>
  );
};
