import React from 'react';

export const DropIndicator = ({ beforeName }) => {
  return (
    <div
      data-before={beforeName || '-1'}
      data-column="leads"
      className="my-0.5 h-0.5 w-full bg-main opacity-0"
    />
  );
};
