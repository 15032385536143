import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown, InputTitle } from 'ui-elements';

import { setChosenTheme } from '../../../../redux/slices/chatbotPreview/designSlice';

export const Theme = () => {
  const dispatch = useDispatch();
  const { chosenTheme } = useSelector((state) => state.chatbotDesign);

  return (
    <div>
      <InputTitle title="Theme" />
      <Dropdown
        title={chosenTheme}
        list={['Main', 'Dark']}
        onClick={(theme) => dispatch(setChosenTheme(theme))}
      />
    </div>
  );
};
