import {
  BellIcon,
  InformationCircleIcon,
  MoonIcon,
  SunIcon,
} from '@heroicons/react/24/outline';
import React from 'react';

import { useTheme } from 'hooks/useTheme';

const settings = [
  {
    id: 'info',
    icon: InformationCircleIcon,
    onClick: () => window.open('https://aidesk.pro', '_blank', 'noopener,noreferrer'),
  },
  {
    id: 'notifications',
    icon: BellIcon,
    onClick: () => window.open('https://x.com/aideskapp', '_blank', 'noopener,noreferrer'),
  },
  {
    id: 'theme',
    icon: MoonIcon,
    dark: SunIcon,
    onClick: 'toggleTheme',
  },
];

export const Settings = () => {
  const { toggleTheme, isDarkMode } = useTheme();

  const handleClick = (setting) => {
    if (setting.id === 'theme') {
      toggleTheme();
    } else if (typeof setting.onClick === 'function') {
      setting.onClick();
    }
  };

  return (
    <div className="flex flex-col items-center gap-5">
      {settings.map((setting) => {
        const IconComponent =
          isDarkMode && setting.id === 'theme' ? setting.dark : setting.icon;

        return (
          <button
            key={setting.id}
            onClick={() => handleClick(setting)}
            className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none"
            aria-label={setting.id}
          >
            <IconComponent className="h-6 w-6" />
          </button>
        );
      })}
    </div>
  );
};
