import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'ui-elements';
import { cn } from 'utils/cn';

export const InputTitle = ({ title, tooltipText, className }) => (
  <div className={cn('mb-2.5 flex items-center gap-1', className)}>
    {title}
    {tooltipText && (
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <QuestionMarkCircleIcon className="size-5 cursor-pointer text-grayText" />
          </TooltipTrigger>
          <TooltipContent>
            <p>{tooltipText}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )}
  </div>
);
