import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon, Loader, PopupWrapper, TextInput } from 'ui-elements';
import { googleIcon } from 'utils/iconsHelper';
import { useLogin } from 'hooks/useLogin';
import useAppNavigation from 'hooks/useAppNavigation';
import useAuth from 'hooks/API/useAuth';

export const LoginPopup = () => {
  const { pageLoading } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.user);
  const { goToChatbots } = useAppNavigation();
  const { signInWithGoogle } = useLogin();
  const { sendAuthLink } = useAuth();

  const [email, setEmail] = useState('');
  const [linkSent, setLinkSent] = useState(false);
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);

  const handleSendAuthLink = async () => {
    setError(null);
    const settings = {
      url: `${window.location.origin}/login?email=${encodeURIComponent(
        email,
      )}`,
      handleCodeInApp: true,
    };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      setSending(true);
      await sendAuthLink(email, settings);
      setLinkSent(true);
    } catch (err) {
      setError('Failed to send authentication link. Please try again.');
      console.error(err);
    } finally {
      setSending(false);
    }
  };

  useEffect(() => {
    if (user) {
      goToChatbots();
    }
  }, [user, goToChatbots]);

  return (
    <div className="fixed left-0 top-0 z-20 flex h-screen w-screen items-center justify-center bg-gradient-to-b from-bodyBgFrom to-bodyBgTo">
      {pageLoading ? (
        <Loader />
      ) : (
        <PopupWrapper className="max-w-96 p-7">
          <h3 className="text-center">Welcome to AI Desk</h3>

          <Button variant="login" onClick={signInWithGoogle}>
            <Icon iconPath={googleIcon} className="min-h-8 min-w-8" />
            <span className="flex-auto">Login with Google</span>
          </Button>

          <p
            className="flex items-center text-grayText before:mr-4 before:block before:h-[1px] before:flex-auto before:bg-grayText before:content-[''] after:ml-4 after:block after:h-[1px] after:flex-auto after:bg-grayText after:content-['']"
          >
            or
          </p>

          {linkSent ? (
            <>
              <p className="!text-center">Check your inbox ✉️</p>
              <p className="!text-center">
                {"If you don't see the email in your inbox, check your spam folder."}
              </p>
            </>
          ) : (
            <>
              <TextInput
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <Button onClick={handleSendAuthLink} disabled={sending}>
                {sending ? 'Sending...' : 'Get a one-time link'}
              </Button>

              {error && <p className="text-red-500 text-center mt-2">{error}</p>}
            </>
          )}
        </PopupWrapper>
      )}
    </div>
  );
};
