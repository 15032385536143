import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showToastError } from 'components/CustomToast';
import { useWebSocket } from 'context/WebSocketContext';
import useAppNavigation from 'hooks/useAppNavigation';
import { auth } from 'utils/firebaseHelper';

import { setChatbots } from '../redux/slices/chatbotSlice';
import { setPageLoading } from '../redux/slices/settingsSlice';
import { setUser } from '../redux/slices/userSlice';
import useAuth from './API/useAuth';
import { useChatbotActions } from './useChatbotActions';
import { useLogout } from './useLogout';
import logger from 'services/logger';

export const useAuthorization = () => {
  const dispatch = useDispatch();
  const { isConnectionStabled, isConnected, stableConnection, socket } =
    useWebSocket();
  const userFromStore = useSelector((state) => state.user);
  const { chatbotId } = useSelector((state) => state.chatbot);
  const { pageLoading } = useSelector((state) => state.settings);
  const { getChatbot, getChatbotsStream, getChatbots } = useChatbotActions();
  const { signIn } = useAuth();
  const { signOutFromFirebase } = useLogout();
  const { goToLogin, getPath } = useAppNavigation();
  const currentPath = getPath().pathname;

  const handleAuthChange = useCallback((user) => {
    if (user) {
      logger.log('AUTHING...');
      signIn(user.email, user.uid, user.displayName)
        .then(() => {
          dispatch(
            setUser({
              id: user.uid,
              email: user.email,
              name: user.displayName,
            }),
          );
          // getChatbots();
          streamChatBots();
        })
        .catch(handleError);
    } else {
      dispatch(setPageLoading(false));
      goToLogin();
    }
  });

  const handleRetryAuth = () => {
    if (userFromStore.user && !isConnectionStabled) {
      logger.log('RETRY AUTH');
      logger.log(userFromStore);
      signIn(
        userFromStore.user.email,
        userFromStore.user.id,
        userFromStore.user.name,
      )
        .then(() => {
          stableConnection();
          streamChatBots();
          // getChatbots();
        })
        .catch(handleError);
    }
  };

  const handleError = useCallback(
    (error) => {
      dispatch(setPageLoading(false));
      showToastError(
        'Something went wrong with your authorization. Please, try again.',
      );
      logger.error('Authorization error:', error);
      signOutFromFirebase();
    },
    [dispatch, signOutFromFirebase],
  );

  useEffect(() => {
    if (isConnected && pageLoading) {
      if (window.tolt_referral) {
        window.tolt.signup(user.email);
      }
      const unsubscribe = auth.onAuthStateChanged(handleAuthChange);
      return () => unsubscribe();
    }
  }, [isConnected]);

  useEffect(() => {
    if (isConnected && !pageLoading && !isConnectionStabled) {
      handleRetryAuth();
    }
  }, [isConnected, currentPath, pageLoading]);

  useEffect(() => {
    if (isConnected && chatbotId) {
      logger.log('GETTING CHATBOT');
      getChatbot(chatbotId);
    }
  }, [isConnected, chatbotId]);

  const handleMessage = (event) => {
    const data = JSON.parse(event.data);
    if (data?.name !== 'readBot') return;
    dispatch(setChatbots(data.chats));
    dispatch(setPageLoading(false));
  };

  function streamChatBots() {
    getChatbotsStream();
    socket.current.addEventListener('message', handleMessage);
  }

  return {
    pageLoading,
    handleAuthChange,
  };
};
