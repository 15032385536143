import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAppNavigation from 'hooks/useAppNavigation';
import { usePopup } from 'hooks/usePopup';
import { useUpdateBot } from 'hooks/useUpdateBot';
import { Checkbox, InputTitle } from 'ui-elements';

import { toggleBotWhiteLabel } from '../../../../redux/slices/chatbotSlice';
import { highlightWhiteLabel } from '../../../../redux/slices/popupSlice';

export const WhiteLabel = () => {
  const dispatch = useDispatch();
  const isWhiteLabelSub = useSelector((state) => state.stripe.isWhiteLabel);
  const { chatbot } = useSelector((state) => state.chatbot);

  const { openPaywallPopup } = usePopup();
  const { updateBotWhiteLabel } = useUpdateBot();

  const { getPath } = useAppNavigation();
  const chatbotId = getPath().pathArray[2];
  const isChatbotWithLabel = chatbot.settings?.isWhiteLabel;

  const handleCheckboxChange = () => {
    if (!isWhiteLabelSub) {
      dispatch(highlightWhiteLabel(true));
      openPaywallPopup();
    }
    if (isWhiteLabelSub) {
      updateBotWhiteLabel(chatbotId, !isChatbotWithLabel);
      dispatch(toggleBotWhiteLabel(!isChatbotWithLabel));
    }
  };

  return (
    <div>
      <InputTitle
        title="White Label"
        tooltipText="Take the bar “Powered by AI Desk off your chat bot"
      />
      <Checkbox
        checked={isChatbotWithLabel}
        onChange={() => handleCheckboxChange()}
      />
    </div>
  );
};
