import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAppNavigation from 'hooks/useAppNavigation';
import { useUpdateBot } from 'hooks/useUpdateBot';
import { InputTitle, PickerWrapper } from 'ui-elements';

import { setCornerRadius } from '../../../../redux/slices/chatbotPreview/designSlice';

export const CornerRadius = () => {
  const dispatch = useDispatch();
  const { cornerRadius } = useSelector((state) => state.chatbotDesign);
  const { updateCornerRadius } = useUpdateBot();
  const { getPath } = useAppNavigation();
  const chatbotId = getPath().pathArray[2];

  const handleCornerRadius = (cornerRadius) => {
    dispatch(setCornerRadius(cornerRadius));
  };

  const variants = [
    {
      title: 'rounded',
      className: 'rounded-lg border',
    },
    {
      title: 'unrounded',
      className: 'rounded-none border',
    },
  ];

  return (
    <div>
      <InputTitle title="Corner Radius" />
      <div className="grid grid-cols-colorsSettings gap-4">
        {variants.map((variant) => (
          <div key={variant.title}>
            <div className="flex items-center gap-2.5">
              <PickerWrapper
                className={variant.className}
                active={cornerRadius === variant.title}
                onClick={() => {
                  handleCornerRadius(variant.title);
                  updateCornerRadius(chatbotId, variant.title);
                }}
              />
              <span className="capitalize">{variant.title}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
