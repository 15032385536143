import React from 'react';
import { useSelector } from 'react-redux';

import { BubbleButton } from './BubbleButton';
import { ChatPreview } from './ChatPreview';

export const ChatPreviewWrapper = () => {
  const { isPreviewOpen } = useSelector((state) => state.chatPreview);

  return (
    <div className="flex flex-col overflow-y-auto pl-5 pr-24">
      <div className="mt-auto flex flex-col gap-5 pb-7">
        {isPreviewOpen && <ChatPreview />}
        <BubbleButton />
      </div>
    </div>
  );
};
