import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { ChatsDashboard, LeadsTable } from 'components';
import { addDays } from 'date-fns';
import { useDashboard } from 'hooks/useDashboard';
import debounce from 'lodash.debounce';
import { getDaysBetween } from 'utils/timeHelper';

export const DashboardContent = () => {
  const { activeDashboardMenuItem } = useSelector((state) => state.dashboard);
  const { getLeadsByDate, getChatsOverview } = useDashboard();
  const { chatsOverviewsDate } = useSelector((state) => state.dashboard);

  // here are dashboard functions I wanted to work together
  // const debounceChatsOverview = useRef(
  //   debounce((days) => {
  //     getChatsOverview(days);
  //   }, 500),
  // ).current;

  // const debounceGetLeads = useRef(
  //   debounce((from, to) => getLeadsByDate(from, to), 500),
  // ).current;

  // useEffect(() => {
  //   const days = getDaysBetween(
  //     chatsOverviewsDate?.from,
  //     chatsOverviewsDate?.to,
  //   );
  //   const fromTime = new Date(chatsOverviewsDate?.from);
  //   const toTime = addDays(new Date(chatsOverviewsDate?.to), 1);
  //   debounceGetLeads(fromTime, toTime);
  //   debounceChatsOverview(days);
  // }, [chatsOverviewsDate?.from, chatsOverviewsDate?.to]);

  return (
    <div className="flex flex-auto flex-col gap-5 [&>*]:flex [&>*]:flex-col [&>*]:gap-5">
      {activeDashboardMenuItem === 0 && <ChatsDashboard />}
      {activeDashboardMenuItem === 1 && <LeadsTable />}
    </div>
  );
};
