import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  aiDeskTheme: '',
  pageLoading: true,
  chatsLoading: false,
  messagesLoading: false,
  chatsOverviewLoading: false,
  leadsOverviewLoading: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleDarkMode: (state, action) => {
      state.aiDeskTheme = action.payload;
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
    setChatsLoading: (state, action) => {
      state.chatsLoading = action.payload;
    },
    setMessagesLoading: (state, action) => {
      state.messagesLoading = action.payload;
    },
    setChatsOverviewLoading: (state, action) => {
      state.chatsOverviewLoading = action.payload;
    },
    setLeadsOverviewLoading: (state, action) => {
      state.leadsOverviewLoading = action.payload;
    },
  },
});

export const {
  toggleDarkMode,
  setPageLoading,
  setChatsLoading,
  setMessagesLoading,
  setChatsOverviewLoading,
  setLeadsOverviewLoading,
} = settingsSlice.actions;
export default settingsSlice.reducer;
