import React, { useEffect, useState } from 'react';

import { cn } from 'utils/cn';
import { GetIconPath } from 'utils/iconsHelper';

export const Icon = ({ iconPath, active, className }) => {
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  return (
    iconPath && (
      <div
        className={cn(
          'size-5 min-w-5 max-w-5 bg-contain bg-center bg-no-repeat',
          className,
        )}
        style={{ backgroundImage: `url(${GetIconPath(iconPath, isActive)})` }}
        onMouseEnter={() => setIsActive(true)}
        onMouseLeave={() => setIsActive(active || false)}
      ></div>
    )
  );
};
