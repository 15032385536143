import { BoltIcon } from '@heroicons/react/24/solid';
import React from 'react';

import { usePopup } from 'hooks/usePopup';
import { Button, PopupShadow, PopupWrapper } from 'ui-elements';
import { useSelector } from 'react-redux';

export const SubscriptionRenewedPopup = () => {
  const { closeRenewedSubscriptionPopup } = usePopup();

  const { typeOfRenewSubscription } = useSelector((state) => state.stripe);

  const isRenew = typeOfRenewSubscription === 'renew';

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <PopupWrapper className="h-fit w-[420px]">
        <h3>{isRenew ? 'Your subscription has been renewed 🎉' : 'Your subscription has been changed 🎉'} </h3>
        <footer>
          <Button
            onClick={closeRenewedSubscriptionPopup}
          >
            <BoltIcon className="size-5 text-yellow" />
            Continue
          </Button>
        </footer>
      </PopupWrapper>
      <PopupShadow />
    </div>
  );
};
