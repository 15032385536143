import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useAppNavigation from 'hooks/useAppNavigation';
import { useChatbotActions } from 'hooks/useChatbotActions';
import { Chats, Dashboard, Plugin, Settings } from 'pages';
import { Loader } from 'ui-elements';

export const Chatbot = () => {
  const { pageLoading } = useSelector((state) => state.settings);
  const { getChatbot } = useChatbotActions();
  const { getPath } = useAppNavigation();

  const chatbotId = getPath().pathArray[2];

  useEffect(() => {
    getChatbot(chatbotId);
  }, []);

  const isDashboard = getPath().pathArray[3] === 'dashboard';
  const isChats = getPath().pathArray[3] === 'chats';
  const isSettings = getPath().pathArray[3] === 'settings';
  const isPlugin = getPath().pathArray[3] === 'plugin';

  if (pageLoading) <Loader />;

  return (
    <div className="overflow-hidden [&>*]:flex [&>*]:flex-auto [&>*]:overflow-hidden">
      {isDashboard && <Dashboard />}
      {isChats && <Chats />}
      {isSettings && <Settings />}
      {isPlugin && <Plugin />}
    </div>
  );
};
