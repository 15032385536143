import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAnimations } from 'hooks/useAnimations';
import useAppNavigation from 'hooks/useAppNavigation';
import { ExpandButton, MenuWrapper } from 'ui-elements';
import { cn } from 'utils/cn';

import { toggleMenu } from '../../redux/slices/menuSlice';
import { ChatsMenuContent } from './ChatsMenuContent';
import { SettingsMenuContent } from './SettingsMenuContent';

export const Menu = ({ hidden }) => {
  const dispatch = useDispatch();
  const { elasticWidth } = useAnimations();
  const menuRef = useRef(null);
  const { isOpenMenu } = useSelector((state) => state.menu);
  const { getPath } = useAppNavigation();

  useEffect(() => {
    elasticWidth(menuRef, isOpenMenu, 330, 100);
  }, [isOpenMenu]);

  return (
    <div
      className={cn(
        'after:content-"" relative z-10 flex min-w-[100px] max-w-[330px] !flex-none flex-col justify-between border-r-[1px] border-whiteBlack bg-menuBg after:absolute after:right-[-2px] after:top-0 after:h-full after:w-[1px] after:bg-gray',
        hidden && '!min-w-0 !max-w-0 overflow-hidden',
      )}
      ref={menuRef}
    >
      <MenuWrapper>
        {getPath().pathArray.includes('chats') && <ChatsMenuContent />}
        {getPath().pathArray.includes('settings') && <SettingsMenuContent />}
      </MenuWrapper>

      <ExpandButton
        onClick={() => dispatch(toggleMenu(!isOpenMenu))}
        isRotate={isOpenMenu}
      />
    </div>
  );
};
