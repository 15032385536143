import React from 'react';
import { useSelector } from 'react-redux';

import {
  Settings as ChatbotSettings,
  Design,
  Leads,
  Sources,
} from 'components';
import { HeaderWrapper, PageContent } from 'ui-elements';

export const Settings = () => {
  const { activeStep } = useSelector((state) => state.chatbotCreating);

  return (
    <div className="">
      <PageContent>
        <HeaderWrapper>
          <h1>Settings</h1>
        </HeaderWrapper>
        <div className="flex flex-auto overflow-hidden [&>*]:flex [&>*]:flex-auto">
          {activeStep === 0 && <Sources />}
          {activeStep === 1 && <ChatbotSettings />}
          {activeStep === 2 && <Design />}
          {activeStep === 3 && <Leads />}
        </div>
      </PageContent>
    </div>
  );
};
