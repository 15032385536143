import React, { useEffect } from 'react';

import { ChatBubbleButton } from './ChatBubbleButton';
import { Colors } from './Colors';
import { CompanyLogo } from './CompanyLogo';
import { CornerRadius } from './CornerRadius';
import { Shadow } from './Shadow';
import { Theme } from './Theme';
import { WhiteLabel } from './WhiteLabel';
import { WidgetLocation } from './WidgetLocation';
import { usePopup } from 'hooks/usePopup';

export const Appearance = () => {
  const { closeUpgradePopup } = usePopup();

  useEffect(() => {
    closeUpgradePopup();
  }, []);

  return (
    <div>
      <h2>Appearance</h2>
      <Theme />
      <CompanyLogo />
      <ChatBubbleButton />
      <Colors />
      <CornerRadius />
      <Shadow />
      <WidgetLocation />
      <WhiteLabel />
    </div>
  );
};
