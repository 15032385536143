import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUpdateBot } from 'hooks/useUpdateBot';
import { Dropdown, InputTitle } from 'ui-elements';

import { setBotTemplate } from '../../../../redux/slices/chatbotPreview/settingsSlice';

export const BotTemplate = ({ chatbotId }) => {
  const dispatch = useDispatch();
  const { botTemplate } = useSelector((state) => state.chatbotSettings);
  const { updateBotTemplate } = useUpdateBot();

  const list = ['Support', 'Sales', 'Marketing', 'Feedback', 'Education'];

  return (
    <div>
      <InputTitle title="Bot template" />
      <Dropdown
        title={botTemplate}
        list={list}
        onClick={(value) => {
          dispatch(setBotTemplate(value));
          updateBotTemplate(chatbotId, value);
        }}
      />
    </div>
  );
};
