import React from 'react';

import useAppNavigation from 'hooks/useAppNavigation';

import { BotName } from './BotName';
import { InitialMessage } from './InitialMessage';
import { InputFieldText } from './InputFieldText';
import { SuggestedMessages } from './SuggestedMessages';

export const BotsTexts = () => {
  const { getPath } = useAppNavigation();
  const chatbotId = getPath().pathArray[2];

  return (
    <div className="flex flex-col gap-5">
      <h2>Bots texts</h2>
      <BotName chatbotId={chatbotId} />
      <InitialMessage chatbotId={chatbotId} />
      <SuggestedMessages chatbotId={chatbotId} />
      <InputFieldText chatbotId={chatbotId} />
    </div>
  );
};
