import React from 'react';
import { useSelector } from 'react-redux';

export const StepTitle = () => {
  const { steps, activeStep } = useSelector((state) => state.chatbotCreating);

  return (
    <div>
      <h1 className="text-4xl font-medium text-grayText">
        Step {activeStep + 1}:{' '}
        <span className="capitalize text-main"> {steps[activeStep].title}</span>
      </h1>
      <p className="text-xl">{steps[activeStep].description}</p>
    </div>
  );
};
