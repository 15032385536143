import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { InputWrapper } from 'ui-elements';
import { cn } from 'utils/cn';

import { setSearchChatsByValue } from '../../redux/slices/chatMenuSlice';
import { toggleMenu } from '../../redux/slices/menuSlice';
import { SearchDatePicker } from './SearchDatePicker';

export const MenuSearch = () => {
  const dispatch = useDispatch();
  const { isOpenMenu } = useSelector((state) => state.menu);
  const inputRef = useRef(null);

  const openMenu = () => {
    dispatch(toggleMenu(true));
    inputRef.current.focus();
  };

  return (
    <InputWrapper className="flex w-full items-center gap-1 max-h-[50px]">
      <MagnifyingGlassIcon
        className="mx-auto size-5 min-w-5 cursor-pointer"
        onClick={() => !isOpenMenu && openMenu()}
      />
      <input
        className={cn('', !isOpenMenu && 'h-0 w-0 opacity-0')}
        type="text"
        placeholder="Search"
        ref={inputRef}
        onChange={(e) => dispatch(setSearchChatsByValue(e.target.value))}
      />
      {isOpenMenu && <SearchDatePicker />}
    </InputWrapper>
  );
};
