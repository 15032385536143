export const paywallItems = [
  {
    title: 'PRO Analytic',
    price: '9.99',
    benefits: [
      'Deep statistic',
      'Mass leads export',
      'Unlimited messages',
      '~120 ten-page PDFs',
    ],
  },
  {
    title: 'AI Functionality',
    price: '18.99',
    benefits: [
      'AI chatbot training',
      'AI Support 24/7',
      'Identifying Hot Leads',
      'Unlimited messages',
      '~120 ten-page PDFs',
    ],
  },
  {
    title: 'White Label',
    price: '39.99',
    benefits: ['Remove our badge', 'Unlimited messages', '~120 ten-page PDFs'],
  },
  {
    title: 'All in 1',
    price: '59.99',
    benefits: ['PRO Analytic', 'AI Functionality', 'White Label'],
  },
];

export const storageTariffs = [
  {
    title: 'Basic',
    price: 'free',
    storage: '50K',
    id: 'Free',
    priceId: null,
  },
  {
    title: 'Standard',
    price: '4.99',
    storage: '500K',
    hint: '~30 ten-page docs',
    id: 'Pro',
    priceId: null,
  },
  {
    title: 'Extra',
    price: '19.99',
    storage: '2.5M',
    hint: '~150 ten-page docs',
    id: 'ProMax',
    priceId: null,
  },
  {
    title: 'Individual plan',
    hint: 'As many symbols as you need for your business. Contact our support team and we\'ll find the perfect option.',
    id: 'Individual',
    priceId: null,
  },
];
