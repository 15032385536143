import React, { useEffect, useRef } from 'react';
import { ChromePicker } from 'react-color';
import { useSelector } from 'react-redux';

import { useDesign } from 'hooks/useDesign';
import { usePopup } from 'hooks/usePopup';
import { PopupWrapper } from 'ui-elements';

export const ColorPickerPopup = () => {
  const { moveToScreen, closePopup, closeColorPickerPopup } = usePopup();

  const colorPickerPopupRef = useRef(null);
  const { hexColor, rgbaColor } = useSelector((state) => state.colorPicker);
  const { colorPickerCoordinate } = useSelector((state) => state.popup);
  const { handleColorPickerChange, onColorInputChange } = useDesign();

  const handleClickOutside = (event) => {
    if (closePopup(colorPickerPopupRef, event)) {
      closeColorPickerPopup();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    moveToScreen(colorPickerPopupRef);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed inset-0 ">
      <div
        className="fixed z-10 grid w-80 transform -translate-x-1/2"
        ref={colorPickerPopupRef}
        style={{
          top: `${colorPickerCoordinate.y - 270}px`,
          left: `${colorPickerCoordinate.x}px`,
        }}
      >
        <PopupWrapper className="gap-0 p-2.5">
          <ChromePicker
            width="100%"
            color={rgbaColor}
            onChange={(color) => {
              handleColorPickerChange(color);
            }}
          />
          <div className="flex items-center justify-between gap-2">
            <span className="select-none">HEX</span>
            <input
              type="text"
              value={hexColor}
              onChange={(e) => onColorInputChange(e)}
              className="border-gray-300 w-full rounded-md border border-border p-1 font-medium uppercase focus:border-transparent focus:outline-none focus:ring-2 focus:ring-main"
            />
          </div>
        </PopupWrapper>
      </div>
    </div>
  );
};
