import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useUpdateBot } from 'hooks/useUpdateBot';

import { setChatbotId } from '../../redux/slices/chatbotSlice';
import { Footer } from './Footer';
import { Header } from './Header';
import { Main } from './Main';

export const ChatbotItem = ({ name, unread, status, updateTime, id }) => {
  const dispatch = useDispatch();
  const { updateBotTitle } = useUpdateBot();

  const [isEdiNameMode, setIsEditNameMode] = useState(false);
  const [newBotName, setNewBotName] = useState('');
  const chatbotItemRef = useRef(null);

  const updateName = () => {
    setIsEditNameMode(false);
    if(newBotName === '') return setNewBotName(name);
    updateBotTitle(id, newBotName);
    dispatch(setChatbotId(''));
  };

  const cancelUpdateName = () => {
    setIsEditNameMode(false);
    setNewBotName('');
  };

  const editBotName = () => {
    setNewBotName(name);
    setIsEditNameMode(true);
    dispatch(setChatbotId(id));
  };

  const handleClickedOutside = (e) => {
    if (chatbotItemRef.current && !chatbotItemRef.current.contains(e.target)) {
      cancelUpdateName();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickedOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickedOutside);
    };
  }, []);

  return (
    <div
      className="flex h-fit flex-col gap-4 rounded-xl bg-tableBg p-5 shadow-shadow"
      ref={chatbotItemRef}
    >
      <Header
        botName={name}
        newBotName={newBotName}
        isEdiNameMode={isEdiNameMode}
        setNewBotName={setNewBotName}
        updateName={updateName}
        cancelUpdateName={cancelUpdateName}
        editBotName={editBotName}
        unread={unread}
      />
      <Main status={status} updateTime={updateTime} />
      <Footer id={id} status={status} />
    </div>
  );
};
