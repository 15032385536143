import { useWebSocket } from 'context/WebSocketContext';

const useCarbon = () => {
  const { sendRequest } = useWebSocket();

  const uploadFile = (id) => {
    return sendRequest('carbon/uploadFile', { id });
  };

  const getStats = (id) => {
    return sendRequest('carbon/getStats', { id });
  };

  const getFiles = (id) => {
    return sendRequest('carbon/getFiles', { id });
  };

  const uploadText = (text, id) => {
    return sendRequest('carbon/uploadText', { text, id });
  };

  const uploadWebsite = (url, id, isAllPagesIncluded) => {
    return sendRequest('carbon/uploadWebsite', { url, id, isAllPagesIncluded });
  };

  const deleteFiles = (fileIds, id) => {
    return sendRequest('carbon/deleteFiles', { fileIds, id });
  };

  const getUser = () => {
    return sendRequest('carbon/getUser', {});
  };

  const deleteUsers = (ids) => {
    return sendRequest('carbon/deleteUsers', { ids });
  };

  return {
    getStats,
    getFiles,
    uploadWebsite,
    uploadFile,
    uploadText,
    deleteFiles,
    getUser,
    deleteUsers,
  };
};

export default useCarbon;
