import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUpdateBot } from 'hooks/useUpdateBot';
import { InputTitle, Textarea } from 'ui-elements';

import { setChatbotGuidelines } from '../../../../redux/slices/chatbotPreview/settingsSlice';

export const ChatbotGuidelines = ({ chatbotId }) => {
  const dispatch = useDispatch();
  const { chatbotGuidelines } = useSelector((state) => state.chatbotSettings);
  const { updateBotGuidelines } = useUpdateBot();
  const maxChars = 500;

  const handleTextChange = (e) => {
    const text = e.target.value;
    if (text.length <= maxChars) {
      dispatch(setChatbotGuidelines(text));
    }
  };

  return (
    <div>
      <InputTitle title="Chatbot Guidelines" />
      <Textarea
        className="h-36"
        placeholder="Be polite and friendly. Don`t use slang."
        value={chatbotGuidelines}
        maxChars={maxChars}
        charCounter={chatbotGuidelines.length}
        onChange={(e) => handleTextChange(e)}
        onBlur={() => updateBotGuidelines(chatbotId, chatbotGuidelines)}
      />
    </div>
  );
};
