import { ChevronDownIcon, ArrowsUpDownIcon, TrashIcon } from '@heroicons/react/24/outline';
import { flexRender, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Button, Icon, Status } from 'ui-elements';
import { fileIconPath } from 'utils/iconsHelper';
import { getFileFormat } from 'utils/stringsHelper';
import { useSources } from 'hooks/useSources';
import { LoaderForInline } from 'ui-elements/LoaderForInline';
import { LoaderForTable } from 'ui-elements/LoaderForTable';

export function FilesTable() {
  const { deleteFile } = useSources();
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const { chatBotFiles, chatbotFilesAreLoading } = useSelector((state) => state.chatbot);
  const dispatch = useDispatch();

  const filesColumns = [
    {
      accessorKey: 'type',
      header: ({ column }) => (
        <Button variant="icon" size="sm" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Type
          <ChevronDownIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => <Icon iconPath={fileIconPath(getFileFormat(row.getValue('name')))} />,
    },
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <Button variant="icon" size="sm" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Name
          <ArrowsUpDownIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => row.getValue('name'),
    },
    {
      accessorKey: 'sync_status',
      header: ({ column }) => (
        <Button variant="icon" size="sm" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Status
          <ChevronDownIcon className="h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => <Status name={row.getValue('sync_status') == 'READY' ? 'Trained' : 'Training'} />,
    },
    {
      accessorKey: 'last_sync',
      header: ({ column }) => (
        <Button variant="icon" size="sm" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
          Date
          <ArrowsUpDownIcon className="h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const date = row.getValue('last_sync') ? new Date(row.getValue('last_sync')) : new Date();
        const formattedDate = new Intl.DateTimeFormat('uk-UA').format(date);
        return <div>{formattedDate}</div>;
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const file = row.original;
        return (
          <Button variant="icon" size="sm" onClick={() => deleteFile(file)}>
            <TrashIcon className="h-5 w-5 text-grayText hover:text-destructive" />
          </Button>
        );
      },
    },
  ];

  const table = useReactTable({
    data: chatBotFiles,
    columns: filesColumns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="mt-5 w-full">
      <div className="flex items-center justify-between">
        <h3>Uploaded files</h3>
      </div>

      <div className="rounded-md">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          {chatbotFilesAreLoading && (
            <TableRow>
              <TableCell colSpan={filesColumns.length} className="text-center">
                <LoaderForTable />
              </TableCell>
            </TableRow>
          )}
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={filesColumns.length} className="h-24 text-center" />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
