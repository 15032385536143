import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDesign } from 'hooks/useDesign';
import { usePopup } from 'hooks/usePopup';
import { InputTitle, PickerWrapper } from 'ui-elements';

import {
  setDarkShadow,
  setLightShadow,
  setShadowName,
} from '../../../../redux/slices/chatbotPreview/designSlice';

export const Shadow = () => {
  const { openCustomShadowPopup } = usePopup();

  const pickersRef = useRef([]);
  const dispatch = useDispatch();
  const { chosenTheme, shadowName } = useSelector(
    (state) => state.chatbotDesign,
  );
  const { onColorInputChange } = useDesign();

  const handleShadow = (e, shadow, index) => {
    dispatch(setShadowName(shadow));
    if (chosenTheme === 'Main') {
      if (shadow === 'No shadow') {
        dispatch(
          setLightShadow({
            shadowOffsetX: 0,
            shadowBlur: 0,
            shadowOffsetY: 0,
            shadowSpread: 0,
            shadowColor: '#D8D8E6',
          }),
        );
      }
      if (shadow === 'Soft shade') {
        dispatch(
          setLightShadow({
            shadowOffsetX: 0,
            shadowBlur: 30,
            shadowOffsetY: 10,
            shadowSpread: 0,
            shadowColor: '#D8D8E6',
          }),
        );
      }
    } else {
      if (shadow === 'No shadow') {
        dispatch(
          setDarkShadow({
            shadowOffsetX: 0,
            shadowBlur: 0,
            shadowOffsetY: 0,
            shadowSpread: 0,
            shadowColor: '#D8D8E6',
          }),
        );
      }
      if (shadow === 'Soft shade') {
        dispatch(
          setDarkShadow({
            shadowOffsetX: 0,
            shadowBlur: 30,
            shadowOffsetY: 10,
            shadowSpread: 0,
            shadowColor: '#D8D8E6',
          }),
        );
      }
    }
    if (shadow === 'Custom') {
      openCustomShadowPopup(pickersRef.current[index]);
    }
  };

  const variants = [
    {
      title: 'No shadow',
      className: 'bg-[#f0f0f5]',
    },
    {
      title: 'Soft shade',
      className: 'shadow-md bg-[#f0f0f5]',
    },
    {
      title: 'Custom',
      className: 'bg-[#f0f0f5]',
    },
  ];

  return (
    <div>
      <InputTitle title="Shadow" />
      <div className="grid grid-cols-colorsSettings gap-4">
        {variants.map((variant, index) => (
          <div key={variant.title}>
            <div className="flex items-center gap-2.5">
              <div
                ref={(el) => (pickersRef.current[index] = el)}
                onClick={(e) => {
                  handleShadow(e, variant.title, index);
                }}
              >
                <PickerWrapper
                  className={variant.className}
                  active={shadowName === variant.title}
                />
              </div>
              <span>{variant.title}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
