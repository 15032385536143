import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useChatActions } from 'hooks/useChatActions';
import { Loader, MessageWrapper } from 'ui-elements';
import { cn } from 'utils/cn';

export const MessagesContainer = ({ className }) => {
  const messagesContainerRef = useRef(null);
  const { chatMessages } = useSelector((state) => state.chatbot);
  const { messagesLoading } = useSelector((state) => state.settings);
  const { readAllMessages } = useChatActions();

  useEffect(() => {
    messagesContainerRef.current.scrollTop =
      messagesContainerRef.current.scrollHeight;
  }, [chatMessages]);

  useEffect(() => {
    const unreadMessages = chatMessages
      ?.filter((message) => !message.read)
      ?.map((message) => message.id);
    if (unreadMessages?.length > 0) {
      readAllMessages(unreadMessages);
    }
  }, [chatMessages]);

  return (
    <div
      className={cn('flex flex-auto flex-col gap-5 overflow-y-auto', className)}
      ref={messagesContainerRef}
    >
      {messagesLoading && <Loader />}
      {!messagesLoading &&
        chatMessages?.map((message, index) => (
          <MessageWrapper
            key={message.id + index}
            text={message.content}
            fromClient={message.role === 'user'}
            read={message.read}
          />
        ))}
    </div>
  );
};
