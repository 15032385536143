import React from 'react';
import { useSelector } from 'react-redux';

import { useSteps } from 'hooks/useSteps';

import { Step } from './Step';

export const StepsContainer = () => {
  const { steps, activeStep } = useSelector((state) => state.chatbotCreating);
  const { handleStepClick } = useSteps();

  return (
    <div className="flex gap-20">
      {steps &&
        steps.map((step, index) => (
          <Step
            key={step.title}
            active={steps.indexOf(step) === activeStep}
            success={steps.indexOf(step) < activeStep}
            onClick={() => handleStepClick(index)}
            Icon={step.icons}
            {...step}
          />
        ))}
    </div>
  );
};
