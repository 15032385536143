import { ChevronRightIcon } from '@heroicons/react/24/solid';
import React from 'react';

export const ExpandButton = ({ onClick, isRotate }) => (
  <button
    onClick={onClick}
    className='after:content-"" before:content-"" absolute bottom-6 right-0 z-10 flex h-5 w-5 translate-x-1/2 transform items-center justify-center rounded-full border-[1px] border-whiteBlack bg-menuBg !p-0 before:absolute before:-left-0.5 before:-top-0.5 before:z-10 before:h-[calc(100%+4px)] before:w-[calc(50%+3px)] before:bg-menuBg after:absolute after:-inset-[2px] after:rounded-full after:border-[1px] after:border-gray'
  >
    <ChevronRightIcon
      className={`z-10 w-3 duration-200 ${isRotate ? '-scale-x-100' : ''}`}
    />
  </button>
);
