import { useDispatch, useSelector } from 'react-redux';

import * as Carbon from 'carbon-connect-js';
import { showToastError, showToastSuccess } from 'components/CustomToast';

import {
  decrimentSymbolsUsed,
  setChatbotFiles,
  setChatbotFilesAreLoading,
  setSymbolsUsed,
} from '../redux/slices/chatbotSlice';
import useCarbon from './API/useCarbon';
import useAppNavigation from './useAppNavigation';
import { usePopup } from './usePopup';

export const useSources = () => {
  const dispatch = useDispatch();
  const { chatBotFiles, chatbotId, symbolsUsed, symbolsAvaliable } =
    useSelector((state) => state.chatbot);
  const { isAIFunctionality } = useSelector((state) => state.stripe);
  const {
    deleteFiles,
    uploadWebsite,
    uploadFile,
    uploadText,
    getFiles,
    getStats,
  } = useCarbon();
  const { getPath } = useAppNavigation();
  const { openMoreStoragePopup } = usePopup();
  const chatbotIdFromPath = getPath().pathArray[2];
  const currentChatBotId = chatbotId || chatbotIdFromPath;

  const getChatBotFiles = () => {
    dispatch(setChatbotFilesAreLoading(true));
    getFiles(currentChatBotId)
      .then((result) => {
        if (result.files) {
          dispatch(setChatbotFiles(result.files.filter((file) => file.name)));
        } else {
          dispatch(setChatbotFiles([]));
        }
        if (result.stats) {
          dispatch(setSymbolsUsed(result.stats.num_characters_synced));
        }
      })
      .finally(() => dispatch(setChatbotFilesAreLoading(false)));
  };

  const uploadFileFromDevice = async (event) => {
    if (isAIFunctionality) {
      if (symbolsUsed <= symbolsAvaliable) {
        dispatch(setChatbotFilesAreLoading(true));
        const files = [...event.target.files];
        const result = await uploadFile(currentChatBotId);
        const accessToken = result.token.access_token;
        const response = await Carbon.uploadFiles({
          accessToken,
          files,
          embeddingModel: 'OPENAI',
        });
        if (response.status !== 200) {
          showToastError(response.error.message);
        }
        getChatBotFiles();
      } else {
        openMoreStoragePopup();
      }
    }
  };

  const saveText = (text) => {
    if (isAIFunctionality) {
      uploadText(text, currentChatBotId)
        .then(() => {
          showToastSuccess('Success!', 'Your text has successfuly saved!');
        })
        .catch((error) => {
          showToastError(error);
        });
    }
  };

  const saveWebsite = (url, isAllPagesIncluded) => {
    if (isAIFunctionality && url) {
      if (symbolsUsed <= symbolsAvaliable) {
        uploadWebsite(url, currentChatBotId, isAllPagesIncluded)
          .then(() => {
            getChatBotFiles();
            showToastSuccess('Success!', 'Your website has successfuly saved!');
          })
          .catch((error) => {
            showToastError(error);
          });
      } else {
        openMoreStoragePopup();
      }
    }
  };

  const deleteFile = (fileToDelete) => {
    dispatch(
      setChatbotFiles(
        chatBotFiles.filter((file) => file.id !== fileToDelete.id),
      ),
    );
    dispatch(
      decrimentSymbolsUsed(fileToDelete.file_statistics?.num_characters),
    );
    deleteFiles([fileToDelete.id], currentChatBotId)
      .then(() => {
        showToastSuccess('Success!', 'Your file has successfuly deleted!');
      })
      .catch((error) => {
        showToastError(error);
      });
  };

  return {
    deleteFile,
    saveWebsite,
    getChatBotFiles,
    uploadFileFromDevice,
    saveText,
  };
};
