import React from 'react';

import { Icon } from 'ui-elements';
import { toastIcon } from 'utils/iconsHelper';

export const ToastIcon = () => (
  <div className="relative z-50 flex items-center">
    <div className="block h-12 w-12 overflow-hidden">
      <Icon default={toastIcon} dark={toastIcon} />
    </div>
  </div>
);
