import { useDispatch, useSelector } from 'react-redux';

import {
  addLeadField,
  changeLeadField,
  removeLeadField,
  setLeadFieldRequired,
  setLeadFieldType,
  setLeadsFormTitle,
} from '../redux/slices/chatbotPreview/leadsSlice';

export const useLeadsForm = () => {
  const dispatch = useDispatch();
  const { leadsForm } = useSelector((state) => state.leadsSlice);

  const handleRequiredChange = (index) => {
    dispatch(setLeadFieldRequired(index));
  };

  const handleRemoveField = (index) => {
    dispatch(removeLeadField(index));
  };

  const handleAddField = () => {
    if (leadsForm.length < 5) {
      dispatch(addLeadField());
    }
  };

  const handleFieldChange = (index, value) => {
    dispatch(changeLeadField({ index, value }));
  };

  const handleFieldTypeChange = (index, value) => {
    dispatch(setLeadFieldType({ index, value }));
  };

  const handleFormTitleChange = (value) => {
    dispatch(setLeadsFormTitle(value));
  };

  return {
    handleRequiredChange,
    handleRemoveField,
    handleAddField,
    handleFieldChange,
    handleFieldTypeChange,
    handleFormTitleChange,
  };
};
