import { BoltIcon } from '@heroicons/react/24/solid';
import React from 'react';

import useAppNavigation from 'hooks/useAppNavigation';
import { usePopup } from 'hooks/usePopup';
import { Button, PopupShadow, PopupWrapper } from 'ui-elements';

export const SubscriptionCanceledPopup = () => {
  const { closeSubscriptionCanceledPopup } = usePopup();
  const { getPath, goToPlugin } = useAppNavigation();

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <PopupWrapper className="h-fit w-[420px]">
        <h3>Your subscription has been cancelled.</h3>
        <footer>
          <Button
            onClick={() => {
              closeSubscriptionCanceledPopup();
            }}
          >
            <BoltIcon className="size-5 text-yellow" />
            Continue
          </Button>
        </footer>
      </PopupWrapper>
      <PopupShadow />
    </div>
  );
};
