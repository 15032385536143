import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePopup } from 'hooks/usePopup';

import { addDays } from 'date-fns';
import { useDashboard } from 'hooks/useDashboard';
import debounce from 'lodash.debounce';
import { Button, Loader } from 'ui-elements';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'ui-elements';

import { LeadsHeader } from './LeadsHeader';
import { leadsColumns } from './columns';

export function LeadsTable() {
  const { isDeleteLeadsPopup } = useSelector((state) => state.popup);
  const [sorting, setSorting] = useState([
    {
      id: 'created',
      desc: true,
    },
  ]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const { chatsOverviewsDate, leadsData } = useSelector(
    (state) => state.dashboard,
  );
  const { leadsOverviewLoading } = useSelector((state) => state.settings);

  const selectedRowIndexes = Object.keys(rowSelection)?.filter(
    (key) => rowSelection[key],
  );
  const selectedRows = leadsData
    ?.filter((_, index) => selectedRowIndexes?.includes(index.toString()))
    .map(({ chatId, created, ...rest }) => rest);

  const selectedRowsQuantity = selectedRowIndexes?.length;
  const { getLeadsByDate } = useDashboard();

  const columns = useMemo(() => leadsColumns, []);

  const debounceGetLeads = useRef(
    debounce((from, to) => getLeadsByDate(from, to), 500),
  ).current;

  useEffect(() => {
    setRowSelection({});
    const fromTime = new Date(chatsOverviewsDate?.from);
    const toTime = addDays(new Date(chatsOverviewsDate?.to), 1);
    debounceGetLeads(fromTime, toTime);
  }, [chatsOverviewsDate?.from, chatsOverviewsDate?.to]);

  const table = useReactTable({
    data: leadsData,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  useEffect(() => {
    if(!isDeleteLeadsPopup) setRowSelection({})
  }, [isDeleteLeadsPopup])

  return (
    <div className="relative w-full flex-auto">
      <LeadsHeader
        selectedRowsQuantity={selectedRowsQuantity}
        selectedRowIndexes={selectedRowIndexes}
        selectedRows={selectedRows}
        data={leadsData}
      />
      {leadsOverviewLoading ? (
        <Loader />
      ) : (
        <>
          <div className="rounded-md border border-border">
            <Table>
              <TableHeader>
                {table?.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table?.getRowModel().rows?.length ? (
                  table?.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns?.length}
                      className="h-24 text-center"
                    >
                      No leads found for this period 😢
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div className="flex items-center justify-between space-x-2 py-4">
            <div className="flex w-[100px] items-center justify-center text-sm font-medium">
              Page {table?.getState().pagination.pageIndex + 1} of{' '}
              {table?.getPageCount()}
            </div>
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => table?.previousPage()}
                disabled={!table?.getCanPreviousPage()}
              >
                Previous
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => table?.nextPage()}
                disabled={!table?.getCanNextPage()}
              >
                Next
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
