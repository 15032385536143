import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  triggerCoordinates: {},
};

const dropdownSlice = createSlice({
  name: 'dropdown',
  initialState: initialState,
  reducers: {
    setTriggerCoordinates: (state, action) => {
      state.triggerCoordinates = action.payload;
    },
  },
});

export const { setTriggerCoordinates } = dropdownSlice.actions;

export default dropdownSlice.reducer;
