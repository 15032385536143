import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import useAppNavigation from 'hooks/useAppNavigation';
import { useChatActions } from 'hooks/useChatActions';
import { useChatHistory } from 'hooks/useChatHistory';
import debounce from 'lodash.debounce';
import { Loader, SettingsMenuWrapper, Switcher } from 'ui-elements';
import { cn } from 'utils/cn';

import { MenuChatItem } from './MenuChatItem';
import { MenuSearch } from './MenuSearch';

export const ChatsMenuContent = () => {
  const { isOpenMenu } = useSelector((state) => state.menu);
  const { chatsLoading } = useSelector((state) => state.settings);
  const { chats } = useSelector((state) => state.chatbot);
  const { searchChatsByValue, searchByDate, searchByStatus } = useSelector(
    (state) => state.chatMenu,
  );

  const { getAllChats, streamAllChats } = useChatActions();
  const { socket } = useChatHistory();

  const { getPath } = useAppNavigation();
  const chatId = getPath().pathArray[4];

  const debouncedGetAllChats = useRef(
    debounce((searchByDate, value, status) => {
      getAllChats({}, searchByDate, value, status);
    }, 500),
  ).current;
  
  useEffect(() => { 
    debouncedGetAllChats(searchByDate, searchChatsByValue, searchByStatus);
  }, [searchChatsByValue, searchByDate, searchByStatus]);
  
  const scrollHandler = ({ target }) => {
    const {scrollTop, scrollHeight, clientHeight} = target;
    if (scrollTop +  clientHeight >= scrollHeight) {
      getAllChats({skip: chats.length}, searchChatsByValue, searchByDate, searchByStatus);
    }
  }

  return (
    <>
      <header
        className={cn('flex justify-between gap-1', !isOpenMenu && 'flex-col')}
      >
        <h2>Chats</h2>
        <Switcher />
      </header>
      <SettingsMenuWrapper className="overflow-hidden">
        <MenuSearch />
          <div
            className='overflow-y-scroll'
            onScroll={scrollHandler}
          >
            {chats?.map((chat) => (
              <MenuChatItem
                key={chat?.id}
                data={chat?.data}
                updatedAt={chat?.updatedAt}
                active={chat?.id === chatId}
                id={chat?.id}
                unreadMessages={chat?.unread || chat?.history?.length}
                lastMessage={chat?.lastMessage?.content || chat?.history?.[chat?.history?.length - 1]?.content}
                status={chat?.status}
              />
            ))}
          </div>
        {chatsLoading && <Loader />}
      </SettingsMenuWrapper>
    </>
  );
};
