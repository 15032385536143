import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUpdateBot } from 'hooks/useUpdateBot';
import { InputTitle, TextInput } from 'ui-elements';

import { setBotName } from '../../../../redux/slices/chatbotPreview/settingsSlice';

export const BotName = ({ chatbotId }) => {
  const dispatch = useDispatch();
  const { botName } = useSelector((state) => state.chatbotSettings);
  const { updateBotName } = useUpdateBot();

  return (
    <div>
      <InputTitle title="Bot Name" />
      <TextInput
        value={botName}
        placeholder="Chat with us!"
        onChange={(e) => dispatch(setBotName(e.target.value))}
        onBlur={() => updateBotName(chatbotId, botName)}
      />
    </div>
  );
};
