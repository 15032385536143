import {
  ChatBubbleLeftEllipsisIcon as ChatbotIcon,
  ChatBubbleLeftRightIcon as ChatsIcon,
  Squares2X2Icon as DashboardIcon,
  CodeBracketSquareIcon as PluginIcon,
  UserIcon as ProfileIcon,
  Cog6ToothIcon as SettingsIcon,
  QuestionMarkCircleIcon as SupportIcon,
} from '@heroicons/react/24/outline';
import {
  ChatBubbleLeftEllipsisIcon as ChatIconActive,
  ChatBubbleLeftRightIcon as ChatsIconActive,
  Squares2X2Icon as DashboardIconActive,
  CodeBracketSquareIcon as PluginIconActive,
  UserIcon as ProfileIconActive,
  Cog6ToothIcon as SettingsIconActive,
  QuestionMarkCircleIcon as SupportIconActive,
} from '@heroicons/react/24/solid';
import { useSelector } from 'react-redux';

const basePaths = '/assets/icons/';

export const logoIcons = {
  default: '/assets/logo.svg',
  dark: '/assets/logo-dark.svg',
  active: '/assets/logo-accent.svg',
  darkActive: '/assets/logo-accent.svg',
  avif: '/assets/logo.avif',
};

// Menu icons
export const chatBotsIcons = {
  default: ChatbotIcon,
  active: ChatIconActive,
};

export const profileIcons = {
  default: ProfileIcon,
  active: ProfileIconActive,
};

export const supportIcons = {
  default: SupportIcon,
  active: SupportIconActive,
};

export const dashboardIcons = {
  default: DashboardIcon,
  active: DashboardIconActive,
};

export const chatsIcons = {
  default: ChatsIcon,
  active: ChatsIconActive,
};

export const settingsIcons = {
  default: SettingsIcon,
  active: SettingsIconActive,
};

export const pluginIcons = {
  default: PluginIcon,
  active: PluginIconActive,
};
// /Menu icons

// formats
export const txtIcons = {
  default: `${basePaths}TXT.svg`,
  dark: `${basePaths}TXT-dark.svg`,
};

export const pdfIcons = {
  default: `${basePaths}PDF.svg`,
  dark: `${basePaths}PDF.svg`,
};

export const docIcons = {
  default: `${basePaths}DOC.svg`,
  dark: `${basePaths}DOC.svg`,
};

export const docxIcons = {
  default: `${basePaths}DOCX.svg`,
  dark: `${basePaths}DOCX.svg`,
};
// /formats

export const googleIcon = {
  default: `${basePaths}google.svg`,
  dark: `${basePaths}google.svg`,
};

export const infinityIcon = {
  default: `${basePaths}infinity.svg`,
  dark: `${basePaths}infinity-dark.svg`,
};

export const toastIcon = `${basePaths}toast-error.svg`;

export const GetIconPath = (group, isActive) => {
  const aiDeskTheme = useSelector((state) => state.settings.aiDeskTheme);
  const isDark = aiDeskTheme === 'dark';

  if (group.active && group.darkActive) {
    return isDark
      ? isActive
        ? group.darkActive
        : group.dark
      : isActive
        ? group.active
        : group.default;
  } else {
    return isDark ? group.dark : group.default;
  }
};

export const fileIconPath = (sourceType) => {
  if (!sourceType) return docIcons;
  const type = sourceType?.toLowerCase();
  switch (type) {
    case 'pdf':
      return pdfIcons;
    case 'msword':
      return docIcons;
    case 'txt':
      return txtIcons;
    default:
      return docxIcons;
  }
};
