import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAppNavigation from 'hooks/useAppNavigation';
import { cn } from 'utils/cn';

import { setIsUpgradePopup } from '../redux/slices/popupSlice';

export const TableContentWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const { getPath } = useAppNavigation();
  const { activeStep, activeSubStep } = useSelector(
    (state) => state.chatbotCreating,
  );
  const { isAIFunctionality } = useSelector((state) => state.stripe);
  const isDashboardRoute = getPath().pathArray.includes('dashboard');

  useEffect(() => {
    dispatch(setIsUpgradePopup(!isAIFunctionality));
  }, [isAIFunctionality]);

  return (
    <div
      className={cn(
        'relative flex flex-auto flex-col overflow-y-auto',
        !isAIFunctionality &&
          ((activeStep === 0 && activeSubStep === 0) ||
            (activeStep === 1 && activeSubStep === 1)) &&
          !isDashboardRoute &&
          'after:absolute after:inset-0 after:z-10 after:backdrop-blur-sm',
      )}
    >
      {children}
    </div>
  );
};
