import React from 'react';

import { Card, CardContent, CardHeader, CardTitle } from 'ui-elements';

import { DashboardDatePicker } from '../DashboardDatePicker';
import { ChatsOverview } from './ChatsOverview';

export const ChatsDashboard = () => {
  return (
    <Card className="col-span-4">
      <CardHeader className="flex-row items-center justify-between">
        <CardTitle>Chats</CardTitle>
        <DashboardDatePicker />
      </CardHeader>
      <CardContent className="pl-2">
        <ChatsOverview />
      </CardContent>
    </Card>
  );
};
