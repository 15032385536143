import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hexColor: '#3DC96D',
  rgbaColor: { r: 173, g: 47, b: 251, a: 1 },
  actionFunction: null,
  colorPickerId: null,
};

const colorPickerSlice = createSlice({
  name: 'colorPicker',
  initialState,
  reducers: {
    setHexColor(state, action) {
      state.hexColor = action.payload;
    },
    setRgbaColor(state, action) {
      state.rgbaColor = action.payload;
    },
    setActionFunction(state, action) {
      state.actionFunction = action.payload;
    },
    setColorPickerId(state, action) {
      state.colorPickerId = action.payload;
    },
  },
});

export const { setHexColor, setRgbaColor, setActionFunction, setColorPickerId } =
  colorPickerSlice.actions;
export default colorPickerSlice.reducer;
