import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSubscriptionId: '',
  activeSubscriptions: [],
  isProAnalytic: true,
  isAIFunctionality: true,
  isWhiteLabel: true,
  isAllInOne: true,
  typeOfRenewSubscription: 'renew',
  subscriptionToChangeTitle: ''
};

const stripeSlice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    setSelectedSubscriptionId: (state, action) => {
      state.selectedSubscriptionId = action.payload;
    },
    setTypeOfRenewSubscription: (state, action) => {
      state.typeOfRenewSubscription = action.payload;
    },
    setSubscriptionToChangeTitle: (state, action) => {
      state.subscriptionToChangeTitle = action.payload;
    },
    setActiveSubscriptions: (state, action) => {
      state.activeSubscriptions = action.payload;

      // Filter subscriptions that are active or trialing
      const filteredSubs = action.payload.filter(
        (subscription) =>
          subscription.status === 'active' ||
          subscription.status === 'trialing',
      );

      // Reset the flags to true initially
      let isProAnalytic = false;
      let isAIFunctionality = false;
      let isWhiteLabel = false;
      let isAllInOne = false;

      // Set flags based on subscription metadata priceName
      filteredSubs.forEach((subscription) => {
        const priceName = subscription.metadata.priceName;

        if (priceName === 'All in 1') {
          isAllInOne = true;
          isProAnalytic = true;
          isAIFunctionality = true;
          isWhiteLabel = true;
        } else if (priceName === 'White Label') {
          isWhiteLabel = true;
        } else if (priceName === 'AI Functionality') {
          isAIFunctionality = true;
        } else if (priceName === 'PRO Analytic') {
          isProAnalytic = true;
        }
      });

      state.isAllInOne = isAllInOne;
      state.isProAnalytic = isProAnalytic;
      state.isAIFunctionality = isAIFunctionality;
      state.isWhiteLabel = isWhiteLabel;
    },
  },
});

export const {
  setTypeOfRenewSubscription,
  setSelectedSubscriptionId,
  setSubscriptionToChangeTitle,
  setActiveSubscriptions,
} = stripeSlice.actions;
export default stripeSlice.reducer;
