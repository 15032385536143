import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconWrapper } from 'ui-elements';
import { cn } from 'utils/cn';

import { setPreviewAnimation, togglePreview } from '../../redux/slices/chatbotPreview/index';

export const BubbleButton = () => {
  const dispatch = useDispatch();
  const bubbleButtonRef = useRef(null);
  const { chosenTheme, theme, bubbleButtonIcon, widgetLocation } = useSelector(
    (state) => state.chatbotDesign,
  );

  return (
    <IconWrapper
      className={cn(
        'ml-auto cursor-pointer border-transparent',
        widgetLocation === 'left' && 'ml-0 mr-auto',
      )}
      style={{
        color:
          chosenTheme === 'Main' ? theme.light.textColor : theme.dark.textColor,
        backgroundColor:
          chosenTheme === 'Main'
            ? theme.light.frameColor
            : theme.dark.frameColor,
        boxShadow:
          chosenTheme === 'Main'
            ? `${theme.light.shadow.shadowOffsetX}px ${theme.light.shadow.shadowOffsetY}px ${theme.light.shadow.shadowBlur}px ${theme.light.shadow.shadowSpread}px ${theme.light.shadow.shadowColor}`
            : `${theme.dark.shadow.shadowOffsetX}px ${theme.dark.shadow.shadowOffsetY}px ${theme.dark.shadow.shadowBlur}px ${theme.dark.shadow.shadowSpread}px ${theme.dark.shadow.shadowColor}`,
      }}
      ref={bubbleButtonRef}
      onClick={() => {
        dispatch(togglePreview());
        dispatch(setPreviewAnimation(true))
      }}
    >
      {bubbleButtonIcon ? (
        <div
          className="h-full w-full rounded-full object-contain"
          style={{
            backgroundImage: `url(${bubbleButtonIcon || logoIcons.active})`,
          }}
        ></div>
      ) : (
        <ChatBubbleBottomCenterTextIcon />
      )}
    </IconWrapper>
  );
};
