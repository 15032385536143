import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { Aside, Menu } from 'components';
import useAppNavigation from 'hooks/useAppNavigation';

import { setIsHideMenu } from '../redux/slices/menuSlice';

export const MainLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isHideMenu } = useSelector((state) => state.menu);
  const { getPath } = useAppNavigation();

  const hideMenu = () => {
    if (
      getPath().pathArray.includes('settings') ||
      getPath().pathArray.includes('chats')
    ) {
      dispatch(setIsHideMenu(false));
    } else {
      dispatch(setIsHideMenu(true));
    }
  };

  useEffect(() => {
    if (getPath().pathname === '/') navigate('/login');
  }, [getPath().pathname]);

  useEffect(() => {
    hideMenu();
  }, [location.pathname]);

  return (
    <>
      <Aside />
      {!isHideMenu && <Menu />}
      <Outlet />
    </>
  );
};
