import React from 'react';
import { useSelector } from 'react-redux';

import { cn } from 'utils/cn';

export const SettingsMenuItem = ({ title, Icon, active, onClick }) => {
  const { isOpenMenu } = useSelector((state) => state.menu);

  return (
    <button
      className={cn(
        'flex min-h-[50px] w-full items-stretch justify-between overflow-hidden rounded-2xl capitalize shadow-shadow  hover:bg-borderList',
        !isOpenMenu && 'mx-auto max-w-[50px]',
      )}
      onClick={onClick}
    >
      {isOpenMenu && (
        <div className="flex items-center px-5 py-2.5">{title}</div>
      )}
      <div
        className={cn(
          'flex items-center justify-center rounded-lg text-whiteBlack',
          active ? 'bg-main text-white' : 'bg-blackWhite',
          isOpenMenu ? 'flex-none px-4' : 'flex-auto px-1',
        )}
      >
        <Icon className="size-6 min-w-6" />
      </div>
    </button>
  );
};
