import { useWebSocket } from 'context/WebSocketContext';

const useAuth = () => {
  const { sendRequest } = useWebSocket();

  const signIn = (email, uid, name) => {
    return sendRequest('auth/user', { email, uid, name });
  };

  const signOut = (email, uid) => {
    return sendRequest('user/signout', { email, uid });
  };

  const sendAuthLink = (email, settings) => {
    return sendRequest('auth/sendAuthLink', { email, settings });
  };

  return {
    sendAuthLink,
    signIn,
    signOut,
  };
};

export default useAuth;
