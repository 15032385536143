import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  botName: '',
  initialMessage: '',
  suggestedMessages: ['Prices', 'Returns', 'Shipping'],
  inputFieldText: '',
  chatbotGuidelines: 'Be polite and friendly. Don`t use slang.',
  botTemplate: 'Support',
  responseLength: 'Medium',
  chatAssessment: 7,
  domains: ['*'],
};

const chatbotSettingsSlice = createSlice({
  name: 'chatbotSettings',
  initialState,
  reducers: {
    setBotName: (state, action) => {
      state.botName = action.payload;
    },
    setInitialMessage: (state, action) => {
      state.initialMessage = action.payload;
    },
    addSuggestedMessages: (state, action) => {
      state.suggestedMessages = [...state.suggestedMessages, ''];
    },
    removeSuggestedMessages: (state, action) => {
      state.suggestedMessages = state.suggestedMessages.filter(
        (_, index) => index !== action.payload,
      );
    },
    changeSuggestedMessages: (state, action) => {
      state.suggestedMessages[action.payload.index] = action.payload.message;
    },
    setSuggestedMessages: (state, action) => {
      state.suggestedMessages = action.payload;
    },
    setInputFieldText: (state, action) => {
      state.inputFieldText = action.payload;
    },
    setChatbotGuidelines: (state, action) => {
      state.chatbotGuidelines = action.payload;
    },
    setBotTemplate: (state, action) => {
      state.botTemplate = action.payload;
    },
    setResponseLength: (state, action) => {
      state.responseLength = action.payload;
    },
    setChatAssessment: (state, action) => {
      state.chatAssessment = action.payload;
    },
    setDomains: (state, action) => {
      state.domains = action.payload;
    },
    addDomain: (state, action) => {
      state.domains = [...state.domains, ''];
    },
    removeDomain: (state, action) => {
      state.domains = state.domains.filter(
        (_, index) => index !== action.payload,
      );
    },
    changeDomain: (state, action) => {
      state.domains[action.payload.index] = action.payload.domain;
    },
  },
});

export const {
  setBotName,
  setInitialMessage,
  addSuggestedMessages,
  removeSuggestedMessages,
  changeSuggestedMessages,
  setSuggestedMessages,
  setInputFieldText,
  setChatbotGuidelines,
  setBotTemplate,
  setResponseLength,
  setChatAssessment,
  setDomains,
  addDomain,
  removeDomain,
  changeDomain,
} = chatbotSettingsSlice.actions;
export default chatbotSettingsSlice.reducer;
