import { useDispatch } from 'react-redux';

import { setChatbotId } from '../redux/slices/chatbotSlice';
import {
  setColorPickerId,
  setHexColor,
  setRgbaColor,
} from '../redux/slices/colorPickerSlice';
import { setSelectedRowIndexes } from '../redux/slices/dashboardSlice';
import {
  highlightWhiteLabel,
  setChatbotActionsPopupCoordinates,
  setClientInfoPopupCoordinates,
  setColorPickerCoordinate,
  setCopyText,
  setCustomShadowPopupCoordinate,
  setIsCancelBotCreationPopup,
  setIsCancelSubscriptionPopup,
  setIsChatbotActionsPopup,
  setIsClientInfoPopup,
  setIsColorPickerPopup,
  setIsCopyPopup,
  setIsCreateBotPopup,
  setIsCustomShadowPopup,
  setIsDeleteChatbotPopup,
  setIsDeleteLeadsPopup,
  setIsMoreStoragePopup,
  setIsPaywallPopup,
  setIsRenewSubscriptionPopup,
  setIsSubscriptionCanceledPopup,
  setIsSubscriptionRenewedPopup,
  setIsTrialPaywallPopup,
  setIsUpgradeLeadsPopup,
  setIsUpgradePopup,
  setIsWelcomePopup,
} from '../redux/slices/popupSlice';
import {
  setSelectedSubscriptionId,
  setSubscriptionToChangeTitle,
  setTypeOfRenewSubscription,
} from '../redux/slices/stripeSlice';
import logger from 'services/logger';

export const usePopup = () => {
  const dispatch = useDispatch();

  const moveToScreen = (ref) => {
    if (ref.current) {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const { x, y } = ref.current.getBoundingClientRect();

      let left;
      let top;
      if (x + ref.current.clientWidth > windowWidth) {
        left = windowWidth - ref.current.clientWidth - 10;
      }
      if (y < 0) {
        top = 10;
      }
      if (y + ref.current.clientHeight > windowHeight) {
        top = windowHeight - ref.current.clientHeight - 10;
      }

      ref.current.style.left = `${left}px`;
      ref.current.style.top = `${top}px`;
    }
  };

  const getTriggerCoordinates = (triggerRef) => {
    if (triggerRef.current) {
      const { x, y, width, height } =
        triggerRef.current.getBoundingClientRect();
      return { x, y, width, height };
    } else if (triggerRef) {
      const { x, y, width, height } = triggerRef.getBoundingClientRect();
      return { x, y, width, height };
    }
    return null;
  };

  const closePopup = (popupRef, event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      return true;
    }
    return false;
  };

  const openClientInfoPopup = (triggerRef) => {
    dispatch(setIsClientInfoPopup(true));
    const coordinates = getTriggerCoordinates(triggerRef);
    if (coordinates) {
      dispatch(setClientInfoPopupCoordinates(coordinates));
    } else {
      logger.log('No coordinates');
    }
  };
  const closeClientInfoPopup = (popupRef, event) => {
    if (closePopup(popupRef, event)) {
      dispatch(setIsClientInfoPopup(false));
    }
  };

  const openCancelBotCreationPopup = (id) => {
    dispatch(setIsCancelBotCreationPopup(true));
    dispatch(setChatbotId(id));
  };
  const closeCancelBotCreationPopup = () => {
    dispatch(setIsCancelBotCreationPopup(false));
    dispatch(setChatbotId(null));
  };

  const openDeleteLeadsPopup = (selectedRowIndexes) => {
    dispatch(setIsDeleteLeadsPopup(true));
    dispatch(setSelectedRowIndexes(selectedRowIndexes));
  };
  const closeDeleteLeadsPopup = () => {
    dispatch(setIsDeleteLeadsPopup(false));
  };

  const openCopyPopup = (copyText) => {
    dispatch(setIsCopyPopup(true));
    dispatch(setCopyText(copyText));
  };
  const closeCopyPopup = () => {
    dispatch(setIsCopyPopup(false));
    dispatch(setCopyText(''));
  };

  const openUpgradeLeadsPopup = () => {
    dispatch(setIsUpgradeLeadsPopup(true));
  };
  const closeUpgradeLeadsPopup = () => {
    dispatch(setIsUpgradeLeadsPopup(false));
  };

  const openCreateBotPopup = () => {
    dispatch(setIsCreateBotPopup(true));
  };
  const closeCreateBotPopup = () => {
    dispatch(setIsCreateBotPopup(false));
  };

  const openChatbotActionsPopup = (triggerRef, id) => {
    dispatch(setChatbotId(id));
    dispatch(setIsChatbotActionsPopup(true));
    const coordinates = getTriggerCoordinates(triggerRef);
    if (coordinates) {
      dispatch(setChatbotActionsPopupCoordinates(coordinates));
    } else {
      logger.log('No coordinates');
    }
  };
  const closeChatbotActionsPopup = (popupRef, event) => {
    if (popupRef && event) {
      if (closePopup(popupRef, event)) {
        dispatch(setIsChatbotActionsPopup(false));
      }
    } else {
      dispatch(setIsChatbotActionsPopup(false));
    }
  };

  const openPaywallPopup = () => {
    dispatch(setIsPaywallPopup(true));
  };
  const closePaywallPopup = () => {
    dispatch(setIsPaywallPopup(false));
    dispatch(highlightWhiteLabel(false));
  };

  const openCancelSubscriptionPopup = (id) => {
    dispatch(setSelectedSubscriptionId(id));
    dispatch(setIsCancelSubscriptionPopup(true));
  };
  const closeCancelSubscriptionPopup = () => {
    dispatch(setIsCancelSubscriptionPopup(false));
  };

  const openUpgradePopup = () => {
    dispatch(setIsUpgradePopup(true));
  };
  const closeUpgradePopup = () => {
    dispatch(setIsUpgradePopup(false));
  };

  const openTrialPaywallPopup = () => {
    dispatch(setIsTrialPaywallPopup(true))
  }
  const closeTrialPaywallPopup = () => {
    dispatch(setIsTrialPaywallPopup(false))
  }

  const openSubscriptionCanceledPopup = () => {
    dispatch(setIsSubscriptionCanceledPopup(true));
  };

  const closeSubscriptionCanceledPopup = () => {
    closeCancelSubscriptionPopup();
    dispatch(setIsSubscriptionCanceledPopup(false));
  };

  const openMoreStoragePopup = () => {
    dispatch(setIsMoreStoragePopup(true));
  };
  const closeMoreStoragePopup = () => {
    dispatch(setIsMoreStoragePopup(false));
  };

  const openRenewSubscriptionPopup = (
    id,
    type = 'renew',
    title = '',
    chatbotId,
  ) => {
    dispatch(setChatbotId(chatbotId));
    dispatch(setSelectedSubscriptionId(id));
    dispatch(setTypeOfRenewSubscription(type));
    dispatch(setSubscriptionToChangeTitle(title));
    dispatch(setIsRenewSubscriptionPopup(true));
  };
  const closeRenewSubscriptionPopup = () => {
    dispatch(setIsRenewSubscriptionPopup(false));
  };

  const openRenewedSubscripionPopup = (id) => {
    dispatch(setIsSubscriptionRenewedPopup(true));
  };
  const closeRenewedSubscriptionPopup = () => {
    closeRenewSubscriptionPopup();
    dispatch(setIsSubscriptionRenewedPopup(false));
  };

  const openDeleteChatbotPopup = () => {
    dispatch(setIsDeleteChatbotPopup(true));
  };
  const closeDeleteChatbotPopup = () => {
    dispatch(setIsDeleteChatbotPopup(false));
  };

  const openWelcomePopup = () => {
    dispatch(setIsWelcomePopup(true));
  };
  const closeWelcomePopup = () => {
    dispatch(setIsWelcomePopup(false));
  };

  const openColorPickerPopup = (triggerRef) => {
    dispatch(setIsColorPickerPopup(true));
    const coordinates = getTriggerCoordinates(triggerRef);
    if (coordinates) {
      dispatch(setColorPickerCoordinate(coordinates));
    }
  };
  const closeColorPickerPopup = () => {
    dispatch(setIsColorPickerPopup(false));
    dispatch(setColorPickerCoordinate({}));
    dispatch(setHexColor(null));
    dispatch(setRgbaColor(null));
    dispatch(setColorPickerId(null));
  };

  const openCustomShadowPopup = (triggerRef) => {
    dispatch(setIsCustomShadowPopup(true));
    const coordinates = getTriggerCoordinates(triggerRef);
    if (coordinates) {
      dispatch(setCustomShadowPopupCoordinate(coordinates));
    }
  };
  const closeCustomShadowPopup = () => {
    dispatch(setIsCustomShadowPopup(false));
    dispatch(setCustomShadowPopupCoordinate({}));
  };

  return {
    openDeleteChatbotPopup,
    closeDeleteChatbotPopup,

    moveToScreen,
    getTriggerCoordinates,
    closePopup,

    openClientInfoPopup,
    closeClientInfoPopup,

    openCancelBotCreationPopup,
    closeCancelBotCreationPopup,

    openDeleteLeadsPopup,
    closeDeleteLeadsPopup,

    openCopyPopup,
    closeCopyPopup,

    openUpgradeLeadsPopup,
    closeUpgradeLeadsPopup,

    openCreateBotPopup,
    closeCreateBotPopup,

    openChatbotActionsPopup,
    closeChatbotActionsPopup,

    openPaywallPopup,
    closePaywallPopup,

    openCancelSubscriptionPopup,
    closeCancelSubscriptionPopup,

    openUpgradePopup,
    closeUpgradePopup,

    openSubscriptionCanceledPopup,
    closeSubscriptionCanceledPopup,

    openMoreStoragePopup,
    closeMoreStoragePopup,

    openRenewSubscriptionPopup,
    closeRenewSubscriptionPopup,

    openRenewedSubscripionPopup,
    closeRenewedSubscriptionPopup,

    openWelcomePopup,
    closeWelcomePopup,

    openColorPickerPopup,
    closeColorPickerPopup,

    openCustomShadowPopup,
    closeCustomShadowPopup,

    openTrialPaywallPopup,
    closeTrialPaywallPopup
  };
};
