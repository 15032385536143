import React from 'react';

import { InputWrapper } from 'ui-elements';
import { cn } from 'utils/cn';

export const TextInput = ({
  placeholder,
  value,
  onChange,
  className,
  style,
  readOnly,
  onBlur,
  maxLength,
}) => (
  <InputWrapper className={cn('w-full', className)} style={style}>
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      onBlur={onBlur}
      maxLength={maxLength || 100}
    />
  </InputWrapper>
);
