import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import useAppNavigation from 'hooks/useAppNavigation';
import { useChatActions } from 'hooks/useChatActions';
import { usePopup } from 'hooks/usePopup';
import { Toggler } from 'ui-elements';
import { textareaAutoHeight } from 'utils/autoHeight';
import { cn } from 'utils/cn';

import { InputWrapper } from '../ui-elements/InputWrapper';

export const Prompt = ({ isCustom, isDashboard, placeholderColor }) => {
  const textareaRef = useRef(null);
  const { cornerRadius, theme, chosenTheme } = useSelector(
    (state) => state.chatbotDesign,
  );
  const { isAIFunctionality } = useSelector((state) => state.stripe);
  const { inputFieldText } = useSelector((state) => state.chatbotSettings);
  const [isFocused, setIsFocused] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');
  const { chatMessages, chats } = useSelector((state) => state.chatbot);
  const { sendMessage, updateAnswerType } = useChatActions();
  const { openPaywallPopup } = usePopup();

  const { getPath } = useAppNavigation();
  // const chatbotId = getPath().pathArray[2];
  const chatId = getPath().pathArray[4];

  const currentChat = chats?.find((chat) => chat?.id === chatId);
  const currentAnswerType = currentChat?.answer;
  const isAnswerTypeChecked = currentAnswerType === 'assistant';

  const changeAnswerType = (type) => {
    if (type === 'assistant' && !isAIFunctionality) {
      openPaywallPopup();
    } else {
      updateAnswerType(type);
    }
  };

  const lastUserMessage = chatMessages
    ?.filter((message) => message.role === 'user')
    .slice(-1)[0];
  const lastMessageId = lastUserMessage?.id;

  const sendAssistantMessage = () => {
    isDashboard && sendMessage(textareaValue, lastMessageId);
    setTextareaValue('');
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      sendAssistantMessage();
    }
  };

  useEffect(() => {
    textareaAutoHeight(textareaRef);
  }, []);

  useLayoutEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.setProperty(
        '--placeholder-color',
        placeholderColor,
      );
    }
  }, [placeholderColor]);

  return (
    <div className="">
      {isDashboard && (
        <div className="relative ml-auto mr-8 flex w-fit items-center gap-2 rounded-t-2xl border border-b-0 border-border bg-tableBg px-2.5 py-1">
          <Toggler
            checked={isAnswerTypeChecked}
            onChange={() =>
              changeAnswerType(isAnswerTypeChecked ? 'manual' : 'assistant')
            }
          />
          AI answers
        </div>
      )}
      <InputWrapper
        className={cn(
          'flex w-full items-center justify-between gap-1 border-border px-4 py-2.5',
          isCustom && cornerRadius === 'rounded' && 'rounded-2xl',
          isCustom && cornerRadius === 'unrounded' && 'rounded-none',
          isDashboard && '!border-border',
        )}
        style={{
          backgroundColor:
            isCustom && chosenTheme === 'Main'
              ? theme.light.frameColor
              : isCustom && theme.dark.frameColor,
          color:
            isCustom && chosenTheme === 'Main'
              ? theme.light.textColor
              : isCustom && theme.dark.textColor,
          borderColor:
            isFocused && isCustom && chosenTheme === 'Main'
              ? theme.light.accentColor
              : isCustom && isFocused && chosenTheme === 'Dark'
                ? theme.dark.accentColor
                : 'transparent',
        }}
      >
        <textarea
          value={textareaValue}
          className="h-fit max-h-40 min-h-0 w-full rounded-none border-none p-0 outline-none"
          placeholder={
            isCustom
              ? inputFieldText || 'Enter your question...'
              : 'Type an answer...'
          }
          ref={textareaRef}
          onChange={(e) => {
            textareaAutoHeight(textareaRef);
            setTextareaValue(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        ></textarea>
        <button onClick={() => sendAssistantMessage()}>
          <PaperAirplaneIcon className="size-6" />
        </button>
      </InputWrapper>
    </div>
  );
};
