import React from 'react';
import { useSelector } from 'react-redux';

import { useSteps } from 'hooks/useSteps';
import { SettingsMenuItem, SettingsMenuWrapper } from 'ui-elements';
import { stepsData } from 'utils/steps';

export const SettingsMenuContent = () => {
  const { activeStep } = useSelector((state) => state.chatbotCreating);
  const { handleStepClick } = useSteps();
  return (
    <>
      <h2>Settings</h2>
      <SettingsMenuWrapper>
        {stepsData.map((step, index) => (
          <SettingsMenuItem
            key={index}
            title={step.title}
            Icon={step.icons}
            active={index === activeStep}
            onClick={() => handleStepClick(index)}
          />
        ))}
      </SettingsMenuWrapper>
    </>
  );
};
