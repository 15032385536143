import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUpdateBot } from 'hooks/useUpdateBot';
import { InputTitle, TextInput } from 'ui-elements';

import { setInputFieldText } from '../../../../redux/slices/chatbotPreview/settingsSlice';

export const InputFieldText = ({ chatbotId }) => {
  const dispatch = useDispatch();
  const { inputFieldText } = useSelector((state) => state.chatbotSettings);
  const { updateBotInputFieldText } = useUpdateBot();

  return (
    <div className="">
      <InputTitle
        title="Input field text"
        tooltipText="Input field text enables users to engage in natural language conversations, for more personalized user experience"
      />
      <TextInput
        value={inputFieldText}
        placeholder="Enter your question..."
        onChange={(e) => dispatch(setInputFieldText(e.target.value))}
        onBlur={() => updateBotInputFieldText(chatbotId, inputFieldText)}
      />
    </div>
  );
};
