import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chatbotId: '',
  chatbots: [],
  chatbot: {},
  chats: [],
  activeChat: {},
  chatMessages: [],
  cachedChatsHistory: [],
  chatBotFiles: [],
  activeSubscriptions: [],
  symbolsUsed: 0,
  symbolsAvaliable: 50000,
  chatbotFilesAreLoading: true,
};

const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    setSymbolsAvaliable: (state, action) => {
      state.symbolsAvaliable = action.payload;
    },
    setSymbolsUsed: (state, action) => {
      state.symbolsUsed = action.payload;
    },
    decrimentSymbolsUsed: (state, action) => {
      state.symbolsUsed -= action.payload;
    },
    setActiveSubscriptions: (state, action) => {
      state.activeSubscriptions = action.payload;
    },
    setChatbotId: (state, action) => {
      state.chatbotId = action.payload;
    },
    setChatbots: (state, action) => {
      state.chatbots = action.payload;
    },
    setChatbot: (state, action) => {
      state.chatbot = action.payload;
    },
    setChats: (state, { payload }) => {
      if(payload.paginated) state.chats.push(...payload.chats);
      else state.chats = payload.chats;
    },
    addNewChat: (state, action) => {
      const chatId = action.payload.id;
      const isDuplicate = state.chats.some((chat) => chat.id === chatId);
      if (isDuplicate) {
        return;
      }

      if (state.chats.length > 0) {
        state.chats?.unshift(action.payload);
      } else {
        state.chats = [action.payload];
      }
    },
    addUnreadMessage: (state, action) => {
      state.chats = state.chats?.map((chat) => {
        if (chat.id === action.payload) {
          chat.unread += 1;
        }
        return chat;
      });
    },
    setActiveChat: (state, action) => {
      state.activeChat = action.payload;
    },
    setActiveChatStatus: (state, action) => {
      state.activeChat.status = action.payload;
    },
    setChatMessages: (state, action) => {
      state.chatMessages = action.payload;
    },
    addAssistantMessage: (state, action) => {
      state.chatMessages.push(action.payload);
    },
    readChatMessages: (state, action) => {
      state.chats = state.chats.map((chat) => {
        if (chat.id === action.payload) {
          chat.unread = 0;
        }
        return chat;
      });
    },
    addNewMessageToActiveChat: (state, action) => {
      state.chatMessages.push(action.payload);
    },
    changeChatAnswerType: (state, action) => {
      state.chats = state.chats.map((chat) => {
        if (chat.id === action.payload.chatId) {
          chat.answer = action.payload.answer;
        }
        return chat;
      });
    },
    removeChatFromActiveChats: (state, action) => {
      state.chats = state.chats.filter((chat) => chat.id !== action.payload);
    },
    addCachedChatsHistory: (state, action) => {
      const { chatId, history } = action.payload;
      if (chatId && history) {
        const chatIndex = state.cachedChatsHistory?.findIndex(
          (chat) => chat.chatId === chatId,
        );
        if (chatIndex === -1) {
          state.cachedChatsHistory.push({ chatId, history });
        }
      }
    },
    addMessageToCachedChatsHistory: (state, action) => {
      const { chatId, message } = action.payload;
      if (chatId && message) {
        const chatIndex = state.cachedChatsHistory.findIndex(
          (chat) => chat.chatId === chatId,
        );
        if (chatIndex !== -1) {
          state.cachedChatsHistory[chatIndex].history.push(message);
        }
      }
    },
    setChatbotFiles: (state, action) => {
      state.chatBotFiles = action.payload;
    },
    setChatbotFilesAreLoading: (state, action) => {
      state.chatbotFilesAreLoading = action.payload;
    },
    toggleBotWhiteLabel: (state, action) => {
      state.chatbot.settings.isWhiteLabel = action.payload;
    },
  },
});

export const {
  setSymbolsAvaliable,
  setSymbolsUsed,
  decrimentSymbolsUsed,
  setActiveSubscriptions,
  setChatbotId,
  setChatbots,
  setChatbot,
  setChats,
  addNewChat,
  addUnreadMessage,
  setActiveChat,
  setActiveChatStatus,
  setChatMessages,
  addAssistantMessage,
  readChatMessages,
  addNewMessageToActiveChat,
  changeChatAnswerType,
  removeChatFromActiveChats,
  addCachedChatsHistory,
  addMessageToCachedChatsHistory,
  setChatbotFiles,
  setChatbotFilesAreLoading,
  toggleBotWhiteLabel,
} = chatbotSlice.actions;
export default chatbotSlice.reducer;
