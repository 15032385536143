import React, { useEffect, useRef } from 'react';

import { useAnimations } from 'hooks/useAnimations';
import { cn } from 'utils/cn';

export const PopupWrapper = ({
  children,
  className,
  style,
  isAnimation = true,
}) => {
  const { popupAnimation } = useAnimations();
  const popupRef = useRef(null);

  useEffect(() => {
    if (isAnimation) popupAnimation(popupRef);
  }, []);

  return (
    <div
      className={cn(
        'relative z-20 flex h-fit max-h-[90vh] w-full flex-col items-center gap-5 overflow-y-auto rounded-2xl bg-tableBg p-7 shadow-shadow [&>*]:w-full [&>footer>button]:w-full [&>footer]:flex [&>footer]:gap-2.5 [&>h3]:text-center [&>header]:flex [&>header]:items-center [&>header]:justify-between [&>header]:gap-2',
        className,
      )}
      style={style}
      ref={popupRef}
    >
      {children}
    </div>
  );
};
