import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import useAppNavigation from 'hooks/useAppNavigation';
import { useDashboard } from 'hooks/useDashboard';
import debounce from 'lodash.debounce';
import {
  Bar,
  BarChart,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Loader } from 'ui-elements';
import { cn } from 'utils/cn';
import { formatMonthDay } from 'utils/timeHelper';
import { getDaysBetween } from 'utils/timeHelper';

import { ChatsTooltip } from './ChatsTooltip';

export function ChatsOverview() {
  const { isDarkMode } = useSelector((state) => state.settings);
  const { chatsOverview, chatsOverviewsDate } = useSelector(
    (state) => state.dashboard,
  );
  const { chatsOverviewLoading } = useSelector((state) => state.settings);
  const formatChatsOverview = chatsOverview?.map((item) => ({
    ...item,
    date: formatMonthDay(item?.date),
  }));

  const textColor = isDarkMode ? '#fff' : '#000';
  const barColor = isDarkMode ? '#eaeaea' : '#D7D6D6';
  const barActiveColor = isDarkMode ? '#5d5c5e' : '#717172';

  const { getChatsOverview } = useDashboard();
  const { getPath } = useAppNavigation();
  const chatbotId = getPath().pathArray[2];

  const debounceChatsOverview = useRef(
    debounce((days) => {
      getChatsOverview(days);
    }, 500),
  ).current;

  useEffect(() => {
    const days = getDaysBetween(
      chatsOverviewsDate?.from,
      chatsOverviewsDate?.to,
    );
    debounceChatsOverview(days);
  }, [chatsOverviewsDate?.from, chatsOverviewsDate?.to, chatbotId]);

  return (
    <div className="relative">
      <div
        className={cn(
          'relative after:pointer-events-none after:absolute after:inset-0  after:content-[""]',
          chatsOverviewLoading && 'after:backdrop-blur-sm',
        )}
      >
        <ResponsiveContainer width="100%" height={350}>
          {formatChatsOverview?.length > 0 ? (
            <BarChart data={formatChatsOverview}>
              <XAxis
                dataKey="date"
                stroke={textColor}
                fontSize={12}
                tickLine={false}
                axisLine={false}
              />
              <YAxis
                stroke={textColor}
                fontSize={12}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => value}
              />
              <Tooltip
                content={<ChatsTooltip />}
                cursor={{ fill: 'transparent' }}
              />
              <Legend />
              <Bar
                dataKey="total"
                fill={barColor}
                radius={[8, 8, 0, 0]}
                activeBar={<Rectangle fill={barActiveColor} />}
              />
            </BarChart>
          ) : (
            <p className="flex h-full items-center justify-center">
              You have no chats for this period 😢
            </p>
          )}
        </ResponsiveContainer>
        {chatsOverviewLoading && (
          <Loader className="absolute left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 transform" />
        )}
      </div>
    </div>
  );
}
