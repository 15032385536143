import { useDispatch, useSelector } from 'react-redux';

import { showToastError } from 'components/CustomToast';
import { deleteFileByDownloadURL } from 'utils/firebaseHelper';

import {
  setBubbleIcon,
  setCompanyLogo,
  setCornerRadius,
  setShadowName,
  setTheme,
  setWidgetLocation,
} from '../redux/slices/chatbotPreview/designSlice';
import {
  setLeadsForm,
  setLeadsFormTitle,
} from '../redux/slices/chatbotPreview/leadsSlice';
import {
  setBotName,
  setBotTemplate,
  setChatAssessment,
  setChatbotGuidelines,
  setDomains,
  setInitialMessage,
  setInputFieldText,
  setResponseLength,
  setSuggestedMessages,
} from '../redux/slices/chatbotPreview/settingsSlice';
import {
  setSourcesLinks,
  setSourcesText,
} from '../redux/slices/chatbotPreview/sourcesSlice';
import {
  setChatbot,
  setChatbotId,
  setChatbots,
  setSymbolsAvaliable,
} from '../redux/slices/chatbotSlice';
import { setPageLoading } from '../redux/slices/settingsSlice';
import { setActiveSubscriptions } from '../redux/slices/stripeSlice';
import useChat from './API/useChat';
import useAppNavigation from './useAppNavigation';
import { usePopup } from './usePopup';
import { useSources } from './useSources';
import logger from 'services/logger';

export const useChatbotActions = () => {
  const dispatch = useDispatch();
  const { chatbotId } = useSelector((state) => state.chatbot);
  const { readChat, deleteBot, createChat, readChatStream } = useChat();
  const { closeChatbotActionsPopup } = usePopup();
  const { goToCreateChatbot } = useAppNavigation();
  const { getChatBotFiles } = useSources();

  const getChatbots = () => {
    readChat()
      .then((result) => {
        dispatch(setChatbots(result.chats));
        dispatch(setPageLoading(false));
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const getChatbotsStream = () => {
    readChatStream();
  };

  const getChatbot = (id) => {
    readChat(id)
      .then((result) => {
        const chatbot = result.chats.find((chat) => chat.id === id);
        dispatch(setSymbolsAvaliable(chatbot.symbolsLimit));
        dispatch(setChatbot(chatbot));
        dispatch(setBotName(chatbot.settings.botName));
        dispatch(setInitialMessage(chatbot.settings.initialMessage));
        if (chatbot.settings.suggestedMessages.length === 0) {
          dispatch(setSuggestedMessages(['']));
        } else {
          dispatch(setSuggestedMessages(chatbot.settings.suggestedMessages));
        }
        dispatch(setInputFieldText(chatbot.settings.inputFieldText));

        // ai settings
        dispatch(setChatbotGuidelines(chatbot.settings.chatbotGuidelines));
        dispatch(setBotTemplate(chatbot.settings.botTemplate));
        dispatch(setResponseLength(chatbot.settings.responseLength));
        dispatch(setChatAssessment(chatbot.settings.chatAssessment));
        dispatch(setSourcesText(chatbot.settings.sourcesText));
        dispatch(setSourcesLinks(chatbot.settings.sourcesLinks));

        // security settings
        dispatch(setDomains(chatbot.settings.domains));

        // design settings
        dispatch(setTheme(chatbot.settings?.theme));
        if (
          chatbot.settings.theme.light.shadow.shadowOffsetX === 0 &&
          chatbot.settings.theme.light.shadow.shadowOffsetY === 0 &&
          chatbot.settings.theme.light.shadow.shadowBlur === 0 &&
          chatbot.settings.theme.light.shadow.shadowColor === '#D8D8E6'
        ) {
          dispatch(setShadowName('No shadow'));
        } else if (
          chatbot.settings.theme.light.shadow.shadowOffsetX === 0 &&
          chatbot.settings.theme.light.shadow.shadowOffsetY === 10 &&
          chatbot.settings.theme.light.shadow.shadowBlur === 30 &&
          chatbot.settings.theme.light.shadow.shadowColor === '#D8D8E6'
        ) {
          dispatch(setShadowName('Soft shade'));
        } else {
          dispatch(setShadowName('Custom'));
        }

        dispatch(setActiveSubscriptions(chatbot.activeSubscriptions));

        dispatch(setCornerRadius(chatbot.settings.rounded));
        dispatch(setWidgetLocation(chatbot.settings.widgetLocation));

        // leads settings
        dispatch(setLeadsFormTitle(chatbot.settings.leadsFormTitle));
        const leadsFormCopy = [...chatbot.settings.leadsForm];
        const leadsFormWithIds = leadsFormCopy.map((field, index) => ({
          ...field,
          id: index + 1,
        }));
        dispatch(setLeadsForm(leadsFormWithIds));
        // dispatch(setLeadsForm(chatbot.settings.leadsForm));
        dispatch(setBubbleIcon(chatbot.settings.bubbleButtonIcon));
        dispatch(setCompanyLogo(chatbot.settings.companyLogo));
      })
      .catch((error) => {
        logger.log(error);
      })
      .finally(() => {
        getChatBotFiles();
      });
  };

  const createChatbot = () => {
    createChat()
      .then((result) => {
        logger.log(result);
        dispatch(setChatbotId(result.id));
        goToCreateChatbot(result.id);
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  const getChatBotById = async (id) => {
    const chatBot = await readChat(id);
    return chatBot.chats.find((chat) => chat.id === id);
  };

  const deleteChatbot = async () => {
    const chatbot = await getChatBotById(chatbotId);
    const activeSubscription =
      chatbot.activeSubscriptions
        .slice()
        .reverse()
        .find(
          (subscription) =>
            subscription.status === 'active' ||
            subscription.status === 'trialing',
        ) || {};
    if (!activeSubscription.id) {
      deleteBot(chatbotId)
        .then(() => {
          logger.log('Chatbot deleted');
          deleteFileByDownloadURL(chatbot.settings.bubbleButtonIcon);
          deleteFileByDownloadURL(chatbot.settings.companyLogo);
          getChatbots();
          closeChatbotActionsPopup();
        })
        .catch((error) => {
          logger.log(error);
          closeChatbotActionsPopup();
        });
    } else {
      showToastError('You can\'t delete chatbot with active subscription!');
      closeChatbotActionsPopup();
    }
  };

  return {
    getChatbots,
    getChatbot,
    createChatbot,
    deleteChatbot,
    getChatbotsStream,
  };
};
