import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useAnimations } from 'hooks/useAnimations';
import { cn } from 'utils/cn';

export const MenuWrapper = ({ children }) => {
  const { menuWrapperTransition } = useAnimations();
  const { isOpenMenu } = useSelector((state) => state.menu);
  const { prevCursorCoordinates, currentCursorCoordinates } = useSelector(
    (state) => state.animation,
  );

  const menuWrapperRef = useRef(null);

  useEffect(() => {
    menuWrapperTransition(menuWrapperRef);
  }, [currentCursorCoordinates.y, prevCursorCoordinates.y]);

  return (
    <div
      className={cn(
        'flex flex-auto flex-col gap-6 overflow-hidden px-6 py-10 [&>*]:gap-6 [&_h2]:overflow-hidden [&_h2]:text-ellipsis [&_h2]:whitespace-nowrap',
        isOpenMenu ? 'px-6' : 'px-2',
      )}
      ref={menuWrapperRef}
    >
      {children}
    </div>
  );
};
