import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSearchByStatus } from '../redux/slices/chatMenuSlice';

export const Switcher = () => {
  const dispatch = useDispatch();
  const itemRefs = useRef([]);
  const indicatorRef = useRef(null);
  const { isOpenMenu } = useSelector((state) => state.menu);
  const { searchByStatus } = useSelector((state) => state.chatMenu);
  const items = ['Active', 'Closed'];

  useEffect(() => {
    if (indicatorRef.current && searchByStatus[0] === 'active') {
      indicatorRef.current.style.left = itemRefs.current[0].offsetLeft + 'px';
      indicatorRef.current.style.width = itemRefs.current[0].offsetWidth + 'px';
    } else if (
      (indicatorRef.current && searchByStatus[0] === 'detached') ||
      searchByStatus[1] === 'close'
    ) {
      indicatorRef.current.style.left = itemRefs.current[1].offsetLeft + 'px';
      indicatorRef.current.style.width = itemRefs.current[1].offsetWidth + 'px';
    }
  }, [searchByStatus, isOpenMenu]);

  return (
    <div className="relative flex w-fit rounded-3xl bg-gray p-0.5">
      {items.map((item, index) => (
        <button
          key={index}
          className="relative z-10 px-2 py-0.5"
          ref={(el) => (itemRefs.current[index] = el)}
          onClick={() => {
            item === 'Active' && !searchByStatus?.includes('active')
              ? dispatch(setSearchByStatus(['active']))
              : {};
            item === 'Closed' &&
            !searchByStatus?.includes('detached') &&
            !searchByStatus?.includes('close')
              ? dispatch(setSearchByStatus(['detached', 'close', 'detach']))
              : {};
          }}
        >
          {isOpenMenu && item}
          {!isOpenMenu && item === 'Active' && 'act'}
          {!isOpenMenu && item === 'Closed' && 'cl'}
        </button>
      ))}
      <span
        className="absolute left-0.5 top-0.5 h-[calc(100%-4px)] rounded-2xl bg-whiteBlack transition-all duration-300"
        ref={indicatorRef}
      ></span>
    </div>
  );
};
