import { PlusIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import useAppNavigation from 'hooks/useAppNavigation';
import { useLeadsForm } from 'hooks/useLeadsForm';
import { useSortDrag } from 'hooks/useSortDrag';
import { useUpdateBot } from 'hooks/useUpdateBot';
import { Button, DropIndicator, InputTitle, TextInput } from 'ui-elements';

import { FormItem } from './FormItem';

export const UserForm = () => {
  const { leadsForm, leadsFormTitle } = useSelector(
    (state) => state.leadsSlice,
  );

  const { handleAddField, handleFormTitleChange } = useLeadsForm();
  const { handleDragEnd } = useSortDrag();
  const { updateLeadsFormTitle, updateLeadsForm } = useUpdateBot();
  const { getPath } = useAppNavigation();
  const chatbotId = getPath().pathArray[2];

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    const isEveryFieldNameUnique = leadsForm.every(
      (field, index, array) =>
        array.findIndex((f) => f.title === field.title) === index,
    );
    if (isEveryFieldNameUnique) {
      const leadsFormWithoutIds = leadsForm.map((field) => {
        const { id, ...rest } = field;
        return rest;
      });
      updateLeadsForm(chatbotId, leadsFormWithoutIds);
    }
  }, [leadsForm]);

  return (
    <>
      <h2>User form</h2>

      <div className="">
        <div className="flex gap-4">
          <TextInput
            className="w-full"
            placeholder="Leave your contacts and our specialist will get back to you soon"
            value={leadsFormTitle}
            onChange={(e) => handleFormTitleChange(e.target.value)}
            onBlur={() => updateLeadsFormTitle(chatbotId, leadsFormTitle)}
          />
        </div>

        <div className="flex flex-col">
          <div className="grid grid-cols-[24px_1fr_1fr_20px_24px] items-center gap-3">
            <InputTitle title="Field type" className="col-start-2" />
            <InputTitle title="Field name" className="col-start-3" />
          </div>
          <div className="flex flex-col gap-1.5">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext
                items={leadsForm}
                strategy={verticalListSortingStrategy}
              >
                {leadsForm?.map((field, index) => (
                  <FormItem key={field._id} index={index} field={field} />
                ))}
              </SortableContext>
            </DndContext>
          </div>
          <DropIndicator beforeName={null} />
          {leadsForm.length < 5 && (
            <div className="mt-2 grid grid-cols-[24px_1fr_1fr_20px_24px]">
              <Button
                className="col-start-2 w-full min-w-fit"
                onClick={handleAddField}
                variant="colorOutline"
              >
                Add field
                <PlusIcon className="size-6" />
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
