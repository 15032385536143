import { EnvelopeIcon } from '@heroicons/react/24/outline';
import React from 'react';

import {
  Button,
  Contact,
  GapBlock,
  HeaderWrapper,
  PageContent,
} from 'ui-elements';

export const Support = () => {

  const contactSupport = () => {
    const to = "hi@aiwidget.com";
    const subject = encodeURIComponent(`Support Request`);
    const body = encodeURIComponent("Please describe your issue here.");
    const url = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${to}&su=${subject}&body=${body}`;
    window.open(url, "_blank");
  };

  const goToFAQ = () => {
    window.location.href = 'https://aidesk.pro';
  };

  const goToAffiliate = () => {
    window.location.href = 'https://aidesk.pro/affiliate';
  };

  return (
    <div className="">
      <PageContent>
        <HeaderWrapper>
          <h2>Support</h2>
        </HeaderWrapper>

        <GapBlock>
          <h3>Contact us by email or leave us a message</h3>
          <Contact>
            <EnvelopeIcon className="size-5" />
            <a>hi@aiwidget.com</a>
          </Contact>

          <Button onClick={contactSupport}>Contact Support</Button>
        </GapBlock>

        <GapBlock>
          <h3>Answers to frequently asked questions</h3>
          <Button onClick={goToFAQ}>Go to FAQ page</Button>
        </GapBlock>

        <GapBlock>
          <h3>Affiliate program</h3>
          <Button onClick={goToAffiliate}>Earn with AI Desk</Button>
        </GapBlock>
      </PageContent>
    </div>
  );
};
