import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { setScrollPosition } from '../redux/slices/asideSlice';
import {
  setActiveChat,
  setChatbot,
  setChatbotId,
} from '../redux/slices/chatbotSlice';

const useAppNavigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const goToLogin = () => {
    navigate('/login');
  };

  const goToChatbots = () => {
    dispatch(setChatbotId(''));
    dispatch(setActiveChat({}));
    dispatch(setChatbot({}));
    navigate('/chatbots');
  };

  const goToChatbot = (id) => {
    dispatch(setChatbotId(id));
    navigate(`/chatbots/${id}`);
  };

  const goToCreateChatbot = (id) => {
    dispatch(setChatbotId(id));
    navigate(`/create-chatbot/${id}`);
  };

  const goToProfile = () => {
    navigate('/profile');
  };

  const goToSupport = () => {
    navigate('/support');
  };

  const goToDashboard = (id) => {
    navigate(`/chatbots/${id}/dashboard`);
  };

  const goToChats = (id) => {
    navigate(`/chatbots/${id}/chats`);
  };

  const goToChat = (chatbotId, id) => {
    navigate(`/chatbots/${chatbotId}/chats/${id}`);
  };

  const goToSettings = (id) => {
    dispatch(setScrollPosition(2));
    navigate(`/chatbots/${id}/settings`);
  };

  const goToPlugin = (id) => {
    navigate(`chatbots/${id}/plugin`);
  };

  const openInNewTab = (url) => {
    const newTab = window.open(url, '_blank');
    newTab.focus();
  };

  const openChatbot = (status, id) => {
    if (status === 'Draft') {
      goToCreateChatbot(id);
    } else {
      dispatch(setChatbotId(id));
      navigate(`/chatbots/${id}/dashboard`);
    }
  };

  const getPath = () => {
    const pathname = location.pathname;
    const pathArray = pathname.split('/');

    return {
      pathname,
      pathArray,
    };
  };

  return {
    goToLogin,
    goToChatbots,
    goToCreateChatbot,
    goToProfile,
    goToSupport,
    goToDashboard,
    goToChats,
    goToChat,
    goToSettings,
    goToPlugin,
    openInNewTab,
    goToChatbot,
    getPath,
    openChatbot,
  };
};

export default useAppNavigation;
