import React from 'react';

import { usePopup } from 'hooks/usePopup';
import { useSteps } from 'hooks/useSteps';
import { Button, PopupShadow, PopupWrapper } from 'ui-elements';

export const CancelBotCreationPopup = () => {
  const { handleCancelChatbotCreation } = useSteps();
  const { closeCancelBotCreationPopup } = usePopup();

  return (
    <div>
      <PopupWrapper className="fixed left-1/2 top-1/2 w-[440px] -translate-x-1/2 -translate-y-1/2 transform">
        <h3>Are you sure you want to cancel the bot creation?</h3>
        <footer>
          <Button
            size="sm"
            variant="destructive"
            onClick={() => handleCancelChatbotCreation()}
            className="p-3"
          >
            Cancel
          </Button>
        </footer>
      </PopupWrapper>
      <PopupShadow onClick={() => closeCancelBotCreationPopup()} />
    </div>
  );
};
