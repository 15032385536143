import React from 'react';

import { cn } from 'utils/cn';

export const TableMenuItem = ({ children, onClick, className }) => (
  <div
    className={cn(
      'flex cursor-pointer items-center gap-2.5 p-[5px] capitalize',
      className,
    )}
    onClick={onClick}
  >
    {children}
  </div>
);
