import React from 'react';
import { useSelector } from 'react-redux';

import {
  AiSetting,
  Appearance,
  BotsTexts,
  Files,
  Security,
  Text,
  UserForm,
  Website,
} from 'components';

export const SubStepContent = () => {
  const { activeStep, activeSubStep } = useSelector(
    (state) => state.chatbotCreating,
  );
  return (
    <div className="flex flex-col gap-5 [&>*]:flex [&>*]:flex-col [&>*]:gap-5">
      {activeStep === 0 && activeSubStep === 0 && <Files />}
      {activeStep === 0 && activeSubStep === 1 && <Text />}
      {activeStep === 0 && activeSubStep === 2 && <Website />}
      {activeStep === 1 && activeSubStep === 0 && <BotsTexts />}
      {activeStep === 1 && activeSubStep === 1 && <AiSetting />}
      {activeStep === 1 && activeSubStep === 2 && <Security />}
      {activeStep === 2 && activeSubStep === 0 && <Appearance />}
      {activeStep === 3 && activeSubStep === 0 && <UserForm />}
    </div>
  );
};
