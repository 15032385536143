import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useAppNavigation from 'hooks/useAppNavigation';
import { useChatActions } from 'hooks/useChatActions';
import { Avatar, Notification } from 'ui-elements';
import { cn } from 'utils/cn';
import { formatTime } from 'utils/timeHelper';

export const MenuChatItem = ({
  active,
  data,
  unreadMessages,
  lastMessage,
  isHotLead,
  updatedAt,
  id,
  status,
}) => {
  const { isOpenMenu } = useSelector((state) => state.menu);
  const { chats } = useSelector((state) => state.chatbot);
  const { setChatInfo } = useChatActions();

  const { getPath } = useAppNavigation();
  const chatbotId = getPath().pathArray[2];
  const chatId = getPath().pathArray[4];

  return (
    <Link
      className={cn(
        'flex items-center gap-4 overflow-hidden border-b border-border p-2.5 duration-200',
        active && 'rounded-xl border-transparent bg-selected',
      )}
      to={`/chatbots/${chatbotId}/chats/${id}`}
      onClick={() => setChatInfo(id, status, data)}
    >
      <div className="relative">
        <Avatar active={active} />
        {!isOpenMenu && (
          <>
            {isHotLead && (
              <Notification className="absolute right-0 top-0 translate-x-1/3 transform bg-transparent p-0 text-lg">
                🔥
              </Notification>
            )}
            {unreadMessages > 0 && (
              <Notification className="absolute bottom-0 right-0 translate-x-1/3 transform">
                {unreadMessages}
              </Notification>
            )}
          </>
        )}
      </div>
      {isOpenMenu && (
        <div className="w-full overflow-hidden">
          <header className="flex items-center justify-between gap-1">
            {data?.name ? <h6>{data.name}</h6> : <h6>New lead</h6>}
            {updatedAt && (
              <span className="ml-auto text-xs text-grayText">
                {formatTime(updatedAt)}
              </span>
            )}
          </header>
          {data?.email ||
            (data?.phone && (
              <main className="text-xs text-grayText">
                {data?.email || data?.phone}
              </main>
            ))}
          <footer className="flex items-center overflow-hidden">
            {lastMessage && (
              <p className="mr-auto overflow-hidden text-ellipsis whitespace-nowrap">
                {lastMessage}
              </p>
            )}
            {isHotLead && (
              <Notification className="bg-transparent text-lg">🔥</Notification>
            )}
            {unreadMessages > 0 && (
              <Notification>{unreadMessages}</Notification>
            )}
          </footer>
        </div>
      )}
    </Link>
  );
};
