import React from 'react';

import { cn } from 'utils/cn';

export const Toggler = ({ checked, onChange }) => (
  <label
    className={cn(
      'relative h-4 w-7 rounded-full ',
      checked ? 'bg-main' : 'bg-gray',
    )}
  >
    <input
      checked={checked}
      className={cn('absolute hidden h-0 w-0')}
      type="checkbox"
      onChange={onChange}
    />
    <span
      className={cn(
        'pointer-events-none absolute top-[1px] aspect-square h-[calc(100%-2px)]  rounded-full bg-whiteBlack transition-all duration-300 ease-in-out',
        checked
          ? 'left-[calc(100%-1px)] -translate-x-full transform'
          : 'left-[1px]',
      )}
    ></span>
  </label>
);
