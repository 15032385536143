import { CheckIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { cn } from 'utils/cn';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './Tooltip';

export const Checkbox = ({ checked, onChange, tooltipText, disabled }) => (
  <label className="block w-fit cursor-pointer">
    <input
      className="absolute hidden h-0 w-0"
      type="checkbox"
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    />
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span
            className={cn(
              'flex size-5 items-center justify-center rounded border',
              checked ? 'border-main bg-main' : 'border-gray',
              disabled && 'cursor-not-allowed border-gray bg-gray',
            )}
          >
            {checked && <CheckIcon className="h-4 w-4 text-white" />}
          </span>
        </TooltipTrigger>
        {tooltipText && (
          <TooltipContent>
            <p>{tooltipText}</p>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  </label>
);
