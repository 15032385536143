import React from 'react';
import { useSelector } from 'react-redux';

import useStripe from 'hooks/API/useStripe';
import { usePopup } from 'hooks/usePopup';
import { Button, Icon } from 'ui-elements';
import { cn } from 'utils/cn';
import { infinityIcon } from 'utils/iconsHelper';
import { formatEndSubDate } from 'utils/timeHelper';

export const Tariff = ({ tariff, chatbotId, activeSubscriptions }) => {
  const { createCheckoutSession } = useStripe();

  const { openCancelSubscriptionPopup, openRenewSubscriptionPopup } =
    usePopup();

  const activeSubscriptionsFromStore = useSelector(
    (state) => state.stripe.activeSubscriptions,
  );

  const { chatbots } = useSelector((state) => state.chatbot);
  const chatbotIdFromStore = useSelector((state) => state.chatbot);

  const allSubscriptions = activeSubscriptions || activeSubscriptionsFromStore;
  const filteredSubscriptions = allSubscriptions.filter(
    (subscription) => ['Standard', 'Extra'].includes(subscription.metadata.priceName)
  );
  const currentSubscription =
    filteredSubscriptions.slice().reverse().find(
      (subscription) => subscription.metadata.priceName === tariff.title,
    ) || {};

  const currentChatbot = chatbots.find(chatbot => chatbot.id === chatbotId || chatbot.id === chatbotIdFromStore);

  const isActive =
    currentSubscription.status === 'active' ||
    currentSubscription.status === 'trialing';

  const subEnd =
    currentSubscription.cancel_at || currentSubscription.canceled_at;
  const subDate = subEnd
    ? currentSubscription.cancel_at || currentSubscription.canceled_at
    : currentSubscription.current_period_end;

  const isStorageEnlarged = currentChatbot?.symbolsLimit > 50000;

  const handleCreateCheckout = (title, chatbotId) => {
    if (isStorageEnlarged) {
      const activeSubscription = filteredSubscriptions.find((subscription) => subscription.status === 'active' || subscription.status === 'trialing');
      openRenewSubscriptionPopup(activeSubscription.id, 'change', title, chatbotId);
    } else if (!isActive) {
      createCheckoutSession(title, chatbotId);
    } else {
      openRenewSubscriptionPopup(currentSubscription.id, 'renew', title, chatbotId);
    }
  };

  const RenderButton = () => {
    if (isActive && !subEnd) {
      return (
        <Button
          variant="outline"
          className={cn('mt-auto min-h-10 w-full min-w-fit cursor-pointer')}
          onClick={() => openCancelSubscriptionPopup(currentSubscription.id)}
        >
          Cancel
        </Button>
      );
    } else if (tariff.id === 'Individual') {
      return (
        <Button className={cn('mt-auto min-h-10 w-full min-w-fit')}>
          Contact us
        </Button>
      );
    } else if (tariff.id === 'Free') {
      return null;
    } else {
      return subEnd ? (
        <Button
          className={cn('mt-auto min-h-10 w-full min-w-fit')}
          onClick={() => handleCreateCheckout(tariff.title, chatbotId)}
        >
          Renew
        </Button>
      ) : (
        <Button
          className={cn('mt-auto min-h-10 w-full min-w-fit')}
          onClick={() => handleCreateCheckout(tariff.title, chatbotId)}
        >
          Get
        </Button>
      );
    }
  };

  return (
    <div className="flex min-h-[375px] flex-col gap-2.5 rounded-2xl border-2 border-transparent bg-tableBg p-5 pt-0 shadow-shadow hover:border-main min-[640px]:gap-5 min-[1150px]:w-56">
      <header className="flex flex-col items-center justify-between">
        <div className=" flex min-h-10 w-full justify-center">
          {isActive && (
            <p
              className={cn(
                'relative h-fit w-2/3 -translate-y-0.5 rounded-b-3xl bg-main text-center font-medium text-white min-[640px]:p-1 min-[640px]:px-2',
                subEnd && 'bg-destructive',
              )}
            >
              {subEnd ? 'Expiring' : 'Active'}
            </p>
          )}
        </div>
        <p className="w-full font-medium">{tariff.title}</p>
      </header>
      <main className="flex flex-col gap-5">
        <div className="">
          <p className="flex items-end gap-1 text-base font-bold">
            <span className="text-3xl font-bold min-[640px]:text-5xl">
              {tariff.storage}{' '}
            </span>
            {tariff.id === 'Individual' && (
              <Icon
                iconPath={infinityIcon}
                className="inline-block h-12 min-w-16"
              />
            )}
            <span className="font-bold text-grayText max-[375px]:text-xs">
              Symbols
            </span>
          </p>
        </div>
        <div className="min-h-5 text-xs text-grayText">
          {tariff.hint && <p className="text-center">{tariff.hint}</p>}
        </div>
        <p
          className={cn(
            'text-center text-base font-bold',
            tariff.id === 'Free' && 'text-3xl font-medium text-grayText',
          )}
        >
          {(tariff.id === 'ProMax' || tariff.id === 'Pro') && (
            <>
              <span className="text-3xl font-bold ">
                ${tariff.price?.split('.')[0]}
              </span>
              <span className="text-2xl font-bold ">
                .{tariff.price?.split('.')[1]}
              </span>
              <span className="font-bold text-grayText max-[375px]:text-xs">
                /month
              </span>
            </>
          )}
          {tariff.id === 'Free' && 'free'}
        </p>
      </main>
      <footer className="mt-auto flex flex-col gap-2.5">
        {subEnd && (
          <p
            className={cn(
              'text-center text-grayText',
              subEnd && 'text-destructive',
            )}
          >
            {formatEndSubDate(subEnd, subDate)}
          </p>
        )}
        <RenderButton />
      </footer>
    </div>
  );
};
