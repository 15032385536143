import { useWebSocket } from 'context/WebSocketContext';

const useChat = () => {
  const { sendRequest } = useWebSocket();

  const createChat = (settings) => {
    return sendRequest('chat/create', { settings });
  };

  const readChat = (id) => {
    return sendRequest('chat/readBot', { id });
  };

  const readChatStream = (id) => {
    return sendRequest('chat/readBot', { id, streaming: true})
  }

  const deleteBot = (id) => {
    return sendRequest('chat/delete', { id });
  };

  const updateBot = (id, update) => {
    return sendRequest('chat/update', { id, update });
  };

  const updateChat = (chatId, update) => {
    return sendRequest('chat/updateChat', { chatId, update });
  };

  // id: chatbot id,
  // chatId: this chat id,
  // content: your message,
  // questionId: id of question user asked you
  const answerChat = (id, chatId, content, questionId) => {
    return sendRequest('chat/answer', { id, chatId, content, questionId });
  };

  const streamChats = (id) => {
    return sendRequest('chat/streamChats', { id });
  };

  const getChats = (id, pagination, search) => {
    return sendRequest('chat/getChats', { id, pagination, search });
  };

  const getChatHistory = (chatId) => {
    return sendRequest('chat/getChatHistory', { chatId });
  };

  const setRead = (chatId, messages) => {
    return sendRequest('chat/setRead', { chatId, messages });
  };

  const getLeads = (id, start, end) => {
    return sendRequest('chat/getLeads', { id, start, end });
  };

  const deleteChats = (ids) => {
    return sendRequest('chat/deleteChats', { ids });
  };

  const getChatsCount = (id, days) => {
    return sendRequest('chat/getChatsCount', { id, days });
  };

  return {
    createChat,
    readChat,
    deleteBot,
    updateBot,
    updateChat,
    answerChat,
    streamChats,
    getChats,
    getChatHistory,
    setRead,
    getLeads,
    deleteChats,
    getChatsCount,
    readChatStream
  };
};

export default useChat;
