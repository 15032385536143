import { BoltIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import useAppNavigation from 'hooks/useAppNavigation';
import { usePopup } from 'hooks/usePopup';
import { Button, PopupWrapper } from 'ui-elements';

export const UpgradePopup = () => {
  const { activeStep, activeSubStep } = useSelector(
    (state) => state.chatbotCreating,
  );
  const { openPaywallPopup, closeUpgradePopup, openUpgradePopup } = usePopup();
  const popupWrapperRef = useRef(null);
  const { getPath } = useAppNavigation();

  useEffect(() => {
    if (
      ((activeStep === 0 && activeSubStep === 0) ||
        (activeStep === 0 && activeSubStep === 1) ||
        (activeStep === 0 && activeSubStep === 2) ||
        (activeStep === 1 && activeSubStep === 1)) &&
      (getPath().pathArray[1] === 'create-chatbot' ||
        getPath().pathArray[3] === 'settings')
    ) {
      openUpgradePopup();
    } else {
      closeUpgradePopup();
    }
  }, [activeStep, activeSubStep, getPath().pathArray]);

  return (
    <div
      className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
      ref={popupWrapperRef}
    >
      <PopupWrapper className="w-full min-[420px]:w-[420px]">
        <h3>
          To customize AI for your business to answer user questions, upgrade
          your subscription. Alternatively, you can respond to users manually,
          keeping the bot free.
        </h3>
        <footer>
          <Button
            onClick={() => {
              closeUpgradePopup();
              openPaywallPopup();
            }}
          >
            <BoltIcon className="size-5 text-yellow" />
            Upgrade
          </Button>
        </footer>
      </PopupWrapper>
    </div>
  );
};
