import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  removeLeadsByChatIds,
  setChatOverview,
  setChatsQuantity,
  setHotLeadsQuantity,
  setLeadsData,
} from '../redux/slices/dashboardSlice';
import {
  setChatsOverviewLoading,
  setLeadsOverviewLoading,
} from '../redux/slices/settingsSlice';
import useChat from './API/useChat';
import useAppNavigation from './useAppNavigation';
import logger from 'services/logger';

export const useDashboard = () => {
  const dispatch = useDispatch();

  const { getLeads, getChatsCount, deleteChats } = useChat();
  const { getPath } = useAppNavigation();
  const chatbotId = getPath().pathArray[2];

  const getLeadsByDate = (start, end) => {
    // dispatch(setLeadsOverviewLoading(true));
    getLeads(chatbotId, start, end)
      .then((response) => {
        dispatch(setLeadsData(response?.leads));
        dispatch(setHotLeadsQuantity(response?.leads?.length));
        // dispatch(setLeadsOverviewLoading(false));
      })
      .catch((error) => {
        logger.log(error);
        // dispatch(setLeadsOverviewLoading(false));
      });
  };

  const getChatsOverview = (days) => {
    // dispatch(setChatsOverviewLoading(true));
    getChatsCount(chatbotId, days)
      .then((response) => {
        logger.log(response, 'response chats dashboard table');
        dispatch(setChatOverview(response?.chats));
        const chatsQuantity = response?.chats
          ?.map((item) => item?.total || 0)
          .reduce((a, b) => a + b, 0);
        dispatch(setChatsQuantity(chatsQuantity));
        // dispatch(setChatsOverviewLoading(false));
      })
      .catch((error) => {
        logger.log(error);
        // dispatch(setChatsOverviewLoading(false));
      });
  };

  const deleteLeadsWithChat = (ids) => {
    deleteChats(ids)
      .then((response) => {
        dispatch(removeLeadsByChatIds(ids));
      })
      .catch((error) => {
        logger.log(error);
      });
  };

  return {
    getLeadsByDate,
    getChatsOverview,
    deleteLeadsWithChat,
  };
};
