import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import useStripe from 'hooks/API/useStripe';
import { usePopup } from 'hooks/usePopup';
import { Button, PopupShadow, PopupWrapper } from 'ui-elements';
import { LoaderForInline } from 'ui-elements/LoaderForInline';
import { useChatbotActions } from 'hooks/useChatbotActions';

export const CancelSubscriptionPopup = () => {
  const { selectedSubscriptionId } = useSelector((state) => state.stripe);
  const [isSubscriptionCanceling, setIsSubscriptionCanceling] = useState(false);
  const { closeCancelSubscriptionPopup, openSubscriptionCanceledPopup } =
    usePopup();
  const { cancelSubscription } = useStripe();
  const { getChatbots } = useChatbotActions();

  const handleCancelSubscription = () => {
    setIsSubscriptionCanceling(true);
    cancelSubscription({ subscriptionId: selectedSubscriptionId })
      .then(() => {
        getChatbots();
        openSubscriptionCanceledPopup();
      })
      .finally(() => setIsSubscriptionCanceling(false));
  };

  return (
    <div>
      <PopupWrapper className="fixed left-1/2 top-1/2 z-50 w-full -translate-x-1/2 -translate-y-1/2 transform p-10 min-[480px]:w-[440px]">
        <header className="relative">
          <h3 className="w-full text-center">
            Are you sure you want to cancel? <br />
            Is there anything we can do? <br />
            Contact our{' '}
            <a href="#" className="text-main underline">
              support team
            </a>
          </h3>
          <button
            onClick={closeCancelSubscriptionPopup}
            className="absolute right-0 top-0 -translate-y-full translate-x-full transform"
          >
            <XMarkIcon className="size-6" />
          </button>
        </header>
        <footer>
          <Button
            size="sm"
            variant="disabledOutline"
            className="cursor-pointer p-3"
            onClick={handleCancelSubscription}
          >
            {isSubscriptionCanceling ? <LoaderForInline /> : 'Yes, cancel it'}
          </Button>
          <Button
            size="sm"
            onClick={closeCancelSubscriptionPopup}
            className="p-3"
          >
            No, keep it
          </Button>
        </footer>
      </PopupWrapper>
      <PopupShadow
        className="z-40"
        onClick={() => closeCancelSubscriptionPopup()}
      />
    </div>
  );
};
