import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isWelcomePopup: false,

  isColorPickerPopup: false,
  colorPickerCoordinate: {},

  isCancelBotCreationPopup: false,

  isUpgradePopup: false,
  isCustomShadowPopup: false,
  customShadowPopupCoordinate: {},

  isTrialPaywallPopup: false,

  isClientInfoPopup: false,
  clientInfoPopupCoordinate: {},

  isDeleteLeadsPopup: false,
  isCopyPopup: false,
  copyText: '',

  isUpgradeLeadsPopup: false,
  isCreateBotPopup: false,

  isChatbotActionsPopup: false,
  chatbotActionsPopupCoordinate: {},

  isPaywallPopup: false,
  isWhiteLabelHighlighted: false,

  isCancelSubscriptionPopup: false,
  isSubscriptionCanceledPopup: false,

  isMoreStoragePopup: false,

  isRenewSubscriptionPopup: false,
  isSubscriptionRenewedPopup: false,

  isDeleteChatbotPopup: false,
};

const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    setIsWelcomePopup: (state, action) => {
      state.isWelcomePopup = action.payload;
    },
    setIsColorPickerPopup: (state, action) => {
      state.isColorPickerPopup = action.payload;
    },
    setColorPickerCoordinate: (state, action) => {
      state.colorPickerCoordinate = action.payload;
    },
    setIsCancelBotCreationPopup: (state, action) => {
      state.isCancelBotCreationPopup = action.payload;
    },
    setIsUpgradePopup: (state, action) => {
      state.isUpgradePopup = action.payload;
    },
    setIsTrialPaywallPopup: (state, action) => {
      state.isTrialPaywallPopup = action.payload
    },
    setIsCustomShadowPopup: (state, action) => {
      state.isCustomShadowPopup = action.payload;
    },
    setCustomShadowPopupCoordinate: (state, action) => {
      state.customShadowPopupCoordinate = action.payload;
    },
    setIsClientInfoPopup: (state, action) => {
      state.isClientInfoPopup = action.payload;
    },
    setClientInfoPopupCoordinates: (state, action) => {
      state.clientInfoPopupCoordinate = action.payload;
    },
    setIsDeleteLeadsPopup: (state, action) => {
      state.isDeleteLeadsPopup = action.payload;
    },
    setIsCopyPopup: (state, action) => {
      state.isCopyPopup = action.payload;
    },
    setCopyText: (state, action) => {
      state.copyText = action.payload;
    },
    setIsUpgradeLeadsPopup: (state, action) => {
      state.isUpgradeLeadsPopup = action.payload;
    },
    setIsCreateBotPopup: (state, action) => {
      state.isCreateBotPopup = action.payload;
    },
    setIsChatbotActionsPopup: (state, action) => {
      state.isChatbotActionsPopup = action.payload;
    },
    setChatbotActionsPopupCoordinates: (state, action) => {
      state.chatbotActionsPopupCoordinate = action.payload;
    },
    setIsPaywallPopup: (state, action) => {
      state.isPaywallPopup = action.payload;
    },
    highlightWhiteLabel: (state, action) => {
      state.isWhiteLabelHighlighted = action.payload;
    },
    setIsCancelSubscriptionPopup: (state, action) => {
      state.isCancelSubscriptionPopup = action.payload;
    },
    setIsSubscriptionCanceledPopup: (state, action) => {
      state.isSubscriptionCanceledPopup = action.payload;
    },
    setIsMoreStoragePopup: (state, action) => {
      state.isMoreStoragePopup = action.payload;
    },
    setIsRenewSubscriptionPopup: (state, action) => {
      state.isRenewSubscriptionPopup = action.payload;
    },
    setIsSubscriptionRenewedPopup: (state, action) => {
      state.isSubscriptionRenewedPopup = action.payload;
    },
    setIsDeleteChatbotPopup: (state, action) => {
      state.isDeleteChatbotPopup = action.payload;
    },
  },
});

export const {
  setIsDeleteChatbotPopup,
  setIsWelcomePopup,
  setIsColorPickerPopup,
  setColorPickerCoordinate,
  setIsCancelBotCreationPopup,
  setIsUpgradePopup,
  setIsTrialPaywallPopup,
  setIsCustomShadowPopup,
  setCustomShadowPopupCoordinate,
  setIsClientInfoPopup,
  setClientInfoPopupCoordinates,
  setIsDeleteLeadsPopup,
  setIsCopyPopup,
  setCopyText,
  setIsUpgradeLeadsPopup,
  setIsCreateBotPopup,
  setIsChatbotActionsPopup,
  setChatbotActionsPopupCoordinates,
  setIsPaywallPopup,
  highlightWhiteLabel,
  setIsCancelSubscriptionPopup,
  setIsSubscriptionCanceledPopup,
  setIsMoreStoragePopup,
  setIsRenewSubscriptionPopup,
  setIsSubscriptionRenewedPopup
} = popupSlice.actions;
export default popupSlice.reducer;
