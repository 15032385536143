import { createSlice } from '@reduxjs/toolkit';
import { generateUniqueId } from 'utils/generateHelper';

const initialState = {
  leadsFormTitle:
    'Leave your contacts and our specialist will get back to you soon',
  leadsForm: [],
};

const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setLeadsFormTitle: (state, action) => {
      state.leadsFormTitle = action.payload;
    },
    setLeadsForm: (state, action) => {
      state.leadsForm = action.payload;
    },
    setLeadFieldRequired: (state, action) => {
      state.leadsForm = state.leadsForm?.map((field, index) => {
        if (index === action.payload) {
          return { ...field, isRequired: !field.isRequired };
        }
        return field;
      });
    },
    removeLeadField: (state, action) => {
      state.leadsForm = state.leadsForm?.filter(
        (field, index) => index !== action.payload,
      );
    },
    addLeadField: (state, action) => {
      const arrayOfExistingNumbers = state.leadsForm
        .map((field) => {
          const number = parseInt(field.title.replace(/^\D+/g, ''), 10);
          return isNaN(number) ? null : number;
        })
        .filter((number) => number !== null);

      state.leadsForm = [
        ...state.leadsForm,
        {
          title: 'Field ' + generateUniqueId(arrayOfExistingNumbers),
          type: 'Text',
          isRequired: false,
        },
      ];
    },
    changeLeadField: (state, action) => {
      state.leadsForm = state.leadsForm?.map((field, index) => {
        if (index === action.payload.index) {
          return {
            ...field,
            title: action.payload.value,
          };
        }
        return field;
      });
    },
    setLeadFieldType: (state, action) => {
      state.leadsForm = state.leadsForm?.map((field, index) => {
        if (index === action.payload.index) {
          return { ...field, type: action.payload.value };
        }
        return field;
      });
    },
  },
});

export const {
  setLeadsFormTitle,
  setLeadsForm,
  setLeadFieldRequired,
  removeLeadField,
  addLeadField,
  changeLeadField,
  setLeadFieldType,
} = leadsSlice.actions;
export default leadsSlice.reducer;
