import React from 'react';

import { PageContent } from 'ui-elements';

export const FooterWrapper = ({ children }) => (
  <div className="bg-tableBg">
    <PageContent className="flex-row items-center justify-between py-4">
      {children}
    </PageContent>
  </div>
);
