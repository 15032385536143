import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAnimations } from 'hooks/useAnimations';
import useAppNavigation from 'hooks/useAppNavigation';
import { useSteps } from 'hooks/useSteps';
import { mainNavigationItems, subNavigationItems } from 'utils/data';

import { setScrollPosition } from '../../redux/slices/asideSlice';
import { NavigationItem } from './NavigationItem';

export const Navigation = () => {
  const { getCursorCoordinates } = useAnimations();

  const dispatch = useDispatch();
  const { handleStepClick } = useSteps();
  const { getPath } = useAppNavigation();
  const { isOpen, scrollPosition } = useSelector((state) => state.aside);

  const mainLinkRefs = useRef([]);
  const subLinkRefs = useRef([]);
  const chatbotId = getPath().pathArray[2];

  const isActiveRoute = (name) => {
    return getPath().pathArray.includes(name);
  };

  const isSubLocation =
    getPath().pathArray.includes('dashboard') ||
    getPath().pathArray.includes('settings') ||
    getPath().pathArray.includes('plugin') ||
    getPath().pathArray.includes('chats');
  const isMainLocation =
    getPath().pathArray.includes('chatbots') ||
    getPath().pathArray.includes('profile') ||
    getPath().pathArray.includes('support');

  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.style.top = isSubLocation
        ? `${subLinkRefs.current[scrollPosition]?.offsetTop}px`
        : `${mainLinkRefs.current[scrollPosition]?.offsetTop}px`;
    }
    dispatch(
      setScrollPosition(
        isSubLocation
          ? subNavigationItems.findIndex((item) => isActiveRoute(item.id))
          : mainNavigationItems.findIndex((item) => isActiveRoute(item.id)),
      ),
    );
  }, [scrollPosition]);

  return (
    <div
      className={`${isOpen ? 'pl-0' : 'pl-2.5'} relative flex flex-col gap-12 duration-300`}
    >
      {isSubLocation
        ? subNavigationItems.map((item, index) => (
            <NavigationItem
              key={item.id}
              to={`/chatbots/${chatbotId}/${item.id}`}
              name={item.id}
              Icon={item.icon}
              isActiveRoute={isActiveRoute}
              className={isSubLocation && 'flex'}
              onClick={(e) => {
                dispatch(setScrollPosition(index));
                handleStepClick(0);
                if (index !== scrollPosition)
                  getCursorCoordinates({ y: e.clientY });
              }}
              ref={(el) => (subLinkRefs.current[index] = el)}
            />
          ))
        : mainNavigationItems.map((item, index) => (
            <NavigationItem
              key={item.id}
              to={`/${item.id}`}
              name={item.id}
              Icon={item.icon}
              isActiveRoute={isActiveRoute}
              className={isMainLocation && !isSubLocation && 'flex'}
              onClick={(e) => {
                dispatch(setScrollPosition(index));
                handleStepClick(0);
                if (index !== scrollPosition)
                  getCursorCoordinates({ y: e.clientY });
              }}
              ref={(el) => (mainLinkRefs.current[index] = el)}
            />
          ))}
      <span
        className="absolute right-0 top-0 z-10 h-8 w-1 translate-x-3/4 transform rounded-sm bg-blackWhite duration-500"
        style={{
          top: isSubLocation
            ? `${subLinkRefs.current[scrollPosition]?.offsetTop}px`
            : `${mainLinkRefs.current[scrollPosition]?.offsetTop}px`,
        }}
        ref={scrollRef}
      ></span>
    </div>
  );
};
