import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePopup } from 'hooks/usePopup';
import { PopupWrapper } from 'ui-elements';
import { cn } from 'utils/cn';
import { formatHex, hexToRgba } from 'utils/colorHelper';

import {
  setDarkShadowBlur,
  setDarkShadowSpread,
  setDarkShadowX,
  setDarkShadowY,
  setLightShadowBlur,
  setLightShadowSpread,
  setLightShadowX,
  setLightShadowY,
} from '../../redux/slices/chatbotPreview/designSlice';
import {
  setColorPickerId,
  setHexColor,
  setRgbaColor,
} from '../../redux/slices/colorPickerSlice';

export const CustomShadowPopup = () => {
  const { openColorPickerPopup, moveToScreen, closeCustomShadowPopup } =
    usePopup();
  const dispatch = useDispatch();
  const customShadowPopupRef = useRef();
  const { theme, chosenTheme } = useSelector((state) => state.chatbotDesign);
  const { customShadowPopupCoordinate } = useSelector((state) => state.popup);

  const shadowSettings = [
    {
      id: 'shadowOffsetX',
      title: 'X',
      value:
        chosenTheme === 'Main'
          ? theme.light.shadow.shadowOffsetX
          : theme.dark.shadow.shadowOffsetX,
    },
    {
      id: 'shadowBlur',
      title: 'Blur',
      value:
        chosenTheme === 'Main'
          ? theme.light.shadow.shadowBlur
          : theme.dark.shadow.shadowBlur,
    },
    {
      id: 'shadowOffsetY',
      title: 'Y',
      value:
        chosenTheme === 'Main'
          ? theme.light.shadow.shadowOffsetY
          : theme.dark.shadow.shadowOffsetY,
    },
    {
      id: 'shadowSpread',
      title: 'Spread',
      value:
        chosenTheme === 'Main'
          ? theme.light.shadow.shadowSpread
          : theme.dark.shadow.shadowSpread,
    },
    {
      id: 'shadowColor',
      title: 'Shadow color',
      value:
        chosenTheme === 'Main'
          ? theme.light.shadow.shadowColor
          : theme.dark.shadow.shadowColor,
    },
  ];

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    if (value > 30) {
      return;
    }
    if (chosenTheme === 'Main') {
      if (index === 0) {
        dispatch(setLightShadowX(value));
      }
      if (index === 1) {
        dispatch(setLightShadowBlur(value));
      }
      if (index === 2) {
        dispatch(setLightShadowY(value));
      }
      if (index === 3) {
        dispatch(setLightShadowSpread(value));
      }
    } else {
      if (index === 0) {
        dispatch(setDarkShadowX(value));
      }
      if (index === 1) {
        dispatch(setDarkShadowBlur(value));
      }
      if (index === 2) {
        dispatch(setDarkShadowY(value));
      }
      if (index === 3) {
        dispatch(setDarkShadowSpread(value));
      }
    }
  };

  const handleColorPicker = (color, name) => {
    openColorPickerPopup(customShadowPopupRef);
    dispatch(setHexColor(color));
    dispatch(setRgbaColor(hexToRgba(color)));
    dispatch(setColorPickerId(name));
  };

  const handleOutsideClick = (e) => {
    if (
      customShadowPopupRef.current &&
      !customShadowPopupRef.current.contains(e.target)
    ) {
      closeCustomShadowPopup();
    }
  };

  useEffect(() => {
    moveToScreen(customShadowPopupRef);
  }, []);

  return (
    <div
      className="fixed left-0 top-0 h-screen w-screen"
      onClick={(e) => handleOutsideClick(e)}
    >
      <div
        className="fixed w-56 -translate-x-1/2 transform"
        style={{
          top: `${customShadowPopupCoordinate.y - 170}px`,
          left: `${customShadowPopupCoordinate.x + customShadowPopupCoordinate.width / 2}px`,
        }}
        ref={customShadowPopupRef}
      >
        <PopupWrapper className="p-2.5">
          <header>
            <h3>Drop shadow</h3>
            <button onClick={closeCustomShadowPopup}>
              <XMarkIcon className="size-6" />
            </button>
          </header>
          <main className="grid grid-cols-2 gap-2">
            {shadowSettings.map((setting, index) =>
              setting.title === 'Shadow color' ? (
                <button
                  key={setting.id}
                  className="col-span-2 flex w-fit items-center gap-2"
                  onClick={() =>
                    handleColorPicker(setting.value, setting.title)
                  }
                >
                  <div
                    className="size-6"
                    style={{
                      backgroundColor: setting.value,
                    }}
                  ></div>
                  <span className="font-normal">
                    {formatHex(setting?.value)}
                  </span>
                </button>
              ) : (
                <div key={setting.id} className="flex items-center gap-2">
                  <span className="">{setting.title} </span>
                  <input
                    type="number"
                    value={setting.value}
                    onChange={(e) => handleInputChange(e, index)}
                    className={cn(
                      'border-gray-300 h-6 w-16 rounded-md border',
                      'focus:outline-none focus:ring-2 focus:ring-main',
                      'focus:border-transparent',
                    )}
                    min={-10}
                    max={setting.title === 'Blur' ? 30 : 10}
                  />
                </div>
              ),
            )}
          </main>
        </PopupWrapper>
      </div>
    </div>
  );
};
