import { ExclamationCircleIcon as WarningIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useSelector } from 'react-redux';

import { usePopup } from 'hooks/usePopup';
import { cn } from 'utils/cn';
import { formatSymbols } from 'utils/filesHelper';

import { Button } from './Button';

export const UsedSymbols = () => {
  const { openMoreStoragePopup } = usePopup();
  const { symbolsUsed, symbolsAvaliable } = useSelector(
    (state) => state.chatbot,
  );

  return (
    <div className="flex flex-col justify-center gap-2">
      {symbolsUsed >= symbolsAvaliable && (
        <p className="mb-1 flex items-center justify-center gap-1">
          <WarningIcon className="h-4 w-4 text-destructive" />
          Storage is full
        </p>
      )}
      <div className="mb-1 h-1 w-full overflow-hidden rounded-lg bg-border">
        <div
          className={cn(
            'h-1 w-1/2 rounded-lg bg-main',
            symbolsUsed >= symbolsAvaliable && 'bg-destructive',
          )}
          style={{ width: `${(symbolsUsed / symbolsAvaliable) * 100}%` }}
        ></div>
      </div>
      <p>
        {formatSymbols(symbolsUsed)} of {formatSymbols(symbolsAvaliable)}{' '}
        Symbols used
      </p>
      {symbolsUsed >= symbolsAvaliable && (
        <Button
          variant="destructive"
          size="sm"
          onClick={() => openMoreStoragePopup()}
          className="w-full"
        >
          Get more
        </Button>
      )}
    </div>
  );
};
