import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isHideAside: true,
  isOpen: false,
  scrollPosition: 0,
};

const asideSlice = createSlice({
  name: 'aside',
  initialState,
  reducers: {
    setIsHideAside: (state, action) => {
      state.isHideAside = action.payload;
    },
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setScrollPosition: (state, action) => {
      state.scrollPosition = action.payload;
    },
  },
});

export const { setIsHideAside, setIsOpen, setScrollPosition } =
  asideSlice.actions;
export default asideSlice.reducer;
