import { ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MessagesContainer, Prompt } from 'components';
import useAppNavigation from 'hooks/useAppNavigation';
import { useChatActions } from 'hooks/useChatActions';
import { usePopup } from 'hooks/usePopup';
import {
  ChatStatusDropdown,
  HeaderWrapper,
  PageContent,
  Status,
} from 'ui-elements';
import { cn } from 'utils/cn';

import { setChatMessages } from '../redux/slices/chatbotSlice';

export const Chats = () => {
  const dispatch = useDispatch();
  const userInfoButtonRef = useRef(null);
  const { activeChat, cachedChatsHistory } = useSelector(
    (state) => state.chatbot,
  );

  const { openClientInfoPopup } = usePopup();
  const { getChatMessages, updateChatStatus } = useChatActions();

  const { getPath } = useAppNavigation();
  const chatId = getPath().pathArray[4];

  const closeChat = (status) => {
    if (status === 'Closed') {
      updateChatStatus('close');
    }
  };

  //ref
  useEffect(() => {
    const isChatCached =
      cachedChatsHistory?.findIndex((chat) => chat.chatId === chatId) !== -1;
    const cachedChatMessages = cachedChatsHistory?.find(
      (chat) => chat.chatId === chatId,
    )?.history;

    if (isChatCached) {
      dispatch(setChatMessages(cachedChatMessages));
    } else {
      getChatMessages();
    }
  }, [chatId]);

  return (
    <div className="flex flex-auto overflow-hidden">
      <PageContent>
        <HeaderWrapper>
          <div className="">
            <button
              className={cn(
                'flex items-center gap-2',
                !activeChat?.name && 'pointer-events-none text-grayText',
              )}
              onClick={() => openClientInfoPopup(userInfoButtonRef)}
              ref={userInfoButtonRef}
            >
              {activeChat && <h2>{activeChat?.name || 'New lead'}</h2>}
              <ChevronDownIcon className="size-6" />
            </button>
          </div>
          {activeChat && activeChat?.status === 'active' ? (
            <ChatStatusDropdown
              selectedValue="Active"
              list={['Active', 'Closed']}
              onClick={(value) => closeChat(value)}
            />
          ) : (
            <Status name="Closed" />
          )}
        </HeaderWrapper>

        <MessagesContainer />
        <div className="mb-7">
          {activeChat?.status === 'active' && <Prompt isDashboard />}
        </div>
      </PageContent>
    </div>
  );
};
