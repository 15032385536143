import { useDispatch, useSelector } from 'react-redux';

import useAppNavigation from 'hooks/useAppNavigation';
import { auth } from 'utils/firebaseHelper';

import { setIsHideAside } from '../redux/slices/asideSlice';
import { setUser } from '../redux/slices/userSlice';
import useAuth from './API/useAuth';

export const useLogout = () => {
  const dispatch = useDispatch();
  const { goToLogin } = useAppNavigation();
  const { signOut } = useAuth();
  const userFromStore = useSelector((state) => state.user);

  const signOutFromFirebase = async () => {
    dispatch(setUser(null));
    dispatch(setIsHideAside(true));
    await auth.signOut();
    await signOut(userFromStore.email, userFromStore.uid);
    sessionStorage.clear();
    indexedDB.databases().then((result) => {
      indexedDB.deleteDatabase(result.name);
      sessionStorage.clear();
    });
    goToLogin();
  };

  return {
    signOutFromFirebase,
  };
};
