import React from 'react';

import { Icon } from 'ui-elements';
import { logoIcons } from 'utils/iconsHelper';

export const ChatFooter = () => {
  return (
    <footer>
      <div className="flex justify-center items-center gap-1">
        Powered by
        <strong>AI Desk</strong>
        <Icon iconPath={logoIcons} active />
      </div>
    </footer>
  );
};
