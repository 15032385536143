import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useAnimations } from 'hooks/useAnimations';
import { cn } from 'utils/cn';

export function PageContent({ children, className }) {
  const { pageContentYTransition } = useAnimations();

  const pageContentRef = useRef(null);
  const { prevCursorCoordinates, currentCursorCoordinates } = useSelector(
    (state) => state.animation,
  );

  useEffect(() => {
    pageContentYTransition(pageContentRef);
  }, [currentCursorCoordinates.y, prevCursorCoordinates.y]);

  return (
    <div
      className={cn(
        'pageContent relative mx-auto flex w-full max-w-[1550px] flex-auto flex-col gap-7 overflow-hidden px-3 pt-8 [&>*]:px-7',
        className,
      )}
      ref={pageContentRef}
    >
      {children}
    </div>
  );
}
