import { TrashIcon } from '@heroicons/react/24/outline';
import { BoltIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { usePopup } from 'hooks/usePopup';
import { Button, PopupWrapper } from 'ui-elements';
import { cn } from 'utils/cn';

export const ChatbotActionsPopup = () => {
  const {
    closeChatbotActionsPopup,
    openPaywallPopup,
    openDeleteChatbotPopup,
    moveToScreen,
  } = usePopup();

  const chatbotActionsPopupRef = useRef(null);
  const { chatbotActionsPopupCoordinate } = useSelector((state) => state.popup);

  const items = [
    {
      id: 'Upgrade chatbot',
      icon: BoltIcon,
      onClick: openPaywallPopup,
    },
    {
      id: 'Delete chatbot',
      icon: TrashIcon,
      variant: 'destructive',
      onClick: openDeleteChatbotPopup,
    },
  ];

  const handleClickOutside = (event) => {
    closeChatbotActionsPopup(chatbotActionsPopupRef, event);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    moveToScreen(chatbotActionsPopupRef);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="fixed"
      ref={chatbotActionsPopupRef}
      style={{
        top: `${chatbotActionsPopupCoordinate.y}px`,
        left: `${chatbotActionsPopupCoordinate.x}px`,
      }}
    >
      <PopupWrapper className="w-60 gap-4 p-5">
        {items.map((item) => (
          <Button
            key={item.id}
            variant={item.variant}
            size="content"
            onClick={item?.onClick}
          >
            <item.icon
              className={cn(
                'size-6',
                item.id === 'Upgrade chatbot' && 'text-yellow',
              )}
            />
            {item.id}
          </Button>
        ))}
      </PopupWrapper>
    </div>
  );
};
