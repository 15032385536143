import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sourcesText: '',
  sourcesUrl: '',
  sourcesLinks: '',
  sourcesFiles: [],
};

const sourcesSlice = createSlice({
  name: 'sources',
  initialState,
  reducers: {
    setSourcesText: (state, action) => {
      state.sourcesText = action.payload;
    },
    setSourcesUrl: (state, action) => {
      state.sourcesUrl = action.payload;
    },
    setSourcesLinks: (state, action) => {
      state.sourcesLinks = action.payload;
    },
    setSourcesFiles: (state, action) => {
      state.sourcesFiles = action.payload;
    },
  },
});

export const {
  setSourcesText,
  setSourcesUrl,
  setSourcesLinks,
  setSourcesFiles,
} = sourcesSlice.actions;
export default sourcesSlice.reducer;
