import React from 'react';
import { useSelector } from 'react-redux';

import { useDashboard } from 'hooks/useDashboard';
import { usePopup } from 'hooks/usePopup';
import { Button, PopupShadow, PopupWrapper } from 'ui-elements';

export const DeleteLeadsPopup = () => {
  const { closeDeleteLeadsPopup } = usePopup();
  const { deleteLeadsWithChat } = useDashboard();
  const { selectedRowIndexes, leadsData } = useSelector(
    (state) => state.dashboard,
  );

  const leadsSelectedIds = selectedRowIndexes.map(
    (index) => leadsData[Number(index)]?.chatId,
  );

  return (
    <div>
      <PopupWrapper className="fixed left-1/2 top-1/2 w-72 -translate-x-1/2 -translate-y-1/2 transform p-5">
        <h3>Delete leads?</h3>
        <footer>
          <Button
            size="sm"
            variant="outline"
            className="p-2"
            onClick={() => closeDeleteLeadsPopup()}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="destructive"
            className="p-2"
            onClick={() => {
              closeDeleteLeadsPopup();
              deleteLeadsWithChat(leadsSelectedIds);
            }}
          >
            Delete
          </Button>
        </footer>
      </PopupWrapper>
      <PopupShadow />
    </div>
  );
};
