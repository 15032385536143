import React from 'react';

import { cn } from 'utils/cn';

export const MessageWrapper = ({ text, fromClient, style, className }) => (
  <div
    className={cn(
      'w-fit max-w-[90%] rounded-2xl bg-tableBg p-2.5',
      fromClient
        ? 'ml-auto rounded-br-none bg-blackWhite text-whiteBlack'
        : 'rounded-bl-none ',
      className,
    )}
    style={style}
  >
    {text}
  </div>
);
