import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePopup } from 'hooks/usePopup';
import { InputTitle, PickerWrapper } from 'ui-elements';
import { formatHex, hexToRgba } from 'utils/colorHelper';

import {
  setColorPickerId,
  setHexColor,
  setRgbaColor,
} from '../../../../redux/slices/colorPickerSlice';

export const Colors = () => {
  const { openColorPickerPopup } = usePopup();

  const dispatch = useDispatch();
  const { theme, chosenTheme } = useSelector((state) => state.chatbotDesign);

  const pickerRef = useRef([]);

  const variants = [
    {
      title: 'Accent color',
      color:
        chosenTheme === 'Main'
          ? theme.light.accentColor
          : theme.dark.accentColor,
    },
    {
      title: 'Background color',
      color:
        chosenTheme === 'Main'
          ? theme.light.backgroundColor
          : theme.dark.backgroundColor,
    },
    {
      title: 'Frame color',
      color:
        chosenTheme === 'Main' ? theme.light.frameColor : theme.dark.frameColor,
    },
    {
      title: 'Text color',
      color:
        chosenTheme === 'Main' ? theme.light.textColor : theme.dark.textColor,
    },
    {
      title: 'Accent text color',
      color:
        chosenTheme === 'Main'
          ? theme.light?.accentTextColor
          : theme.dark?.accentTextColor,
    },
  ];

  const handleColorPicker = (color, name, index) => {
    openColorPickerPopup(pickerRef.current[index]);
    dispatch(setHexColor(color));
    dispatch(setRgbaColor(hexToRgba(color)));
    dispatch(setColorPickerId(name));
  };

  return (
    <div className="grid grid-cols-colorsSettings gap-4">
      {variants.map((item, index) => (
        <div key={item.title}>
          <InputTitle title={item.title} />
          <div className="flex items-center gap-2.5">
            <div
              onClick={() => {
                handleColorPicker(item.color, item.title, index);
              }}
              ref={(el) => (pickerRef.current[index] = el)}
            >
              <PickerWrapper
                type="color"
                style={{ backgroundColor: `${item.color}` }}
              />
            </div>
            <span className="uppercase">{formatHex(item.color)}</span>
          </div>
        </div>
      ))}
    </div>
  );
};
