import { CalendarDaysIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addDays, format } from 'date-fns';
import {
  Button,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'ui-elements';
import { cn } from 'utils/cn';

import { setSearchByDate } from '../../redux/slices/chatMenuSlice';

export const SearchDatePicker = () => {
  const dispatch = useDispatch();
  const { searchByDate } = useSelector((state) => state.chatMenu);

  const setDate = (data) => {
    const formattedDate = {
      from: data?.from ? format(data?.from, 'yyyy-MM-dd') : '',
      to: data?.to
        ? format(data?.to, 'yyyy-MM-dd')
        : data?.from
          ? format(data?.from, 'yyyy-MM-dd')
          : '',
    };
    dispatch(setSearchByDate(formattedDate));
  };

  const clearDate = () => {
    dispatch(setSearchByDate({ from: '', to: '' }));
  };

  return (
    <div
    className={cn(
      'border',
      searchByDate?.from || searchByDate?.to
        ? 'flex items-center rounded-lg border-border px-1'
        : 'border-transparent',
    )}
  >
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant="icon"
            size="sm"
            className={cn(
              'items-center justify-start !p-0 text-left font-normal',
              !searchByDate && 'text-grayText',
            )}
          >
            <p className="text-xs">
              {searchByDate?.from ? (
                searchByDate?.to ? (
                  searchByDate.from === searchByDate.to ? (
                    format(searchByDate.from, 'LLL dd')
                  ) : (
                    <>
                      {format(searchByDate.from, 'LLL dd')} -{' '}
                      {format(searchByDate.to, 'LLL dd')}
                    </>
                  )
                ) : (
                  format(searchByDate.from, 'LLL dd')
                )
              ) : (
                <CalendarDaysIcon className="size-5" />
              )}
            </p>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={searchByDate?.from}
            selected={searchByDate}
            onSelect={(date) => setDate(date)}
            numberOfMonths={1}
          />
        </PopoverContent>
      </Popover>
      {(searchByDate?.from || searchByDate?.to) && (
        <button onClick={clearDate}>
          <XMarkIcon className="size-5 min-w-5" />
        </button>
      )}
    </div>
  );
};
