import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Footer } from 'components';
import { Design, Leads, Settings, Sources } from 'components';
import { useAnimations } from 'hooks/useAnimations';
import useAppNavigation from 'hooks/useAppNavigation';
import { useChatbotActions } from 'hooks/useChatbotActions';
import {
  HeaderWrapper,
  Loader,
  PageContent,
  StepTitle,
  StepsContainer,
} from 'ui-elements';

export const CreateChatbot = () => {
  const { activeStep } = useSelector((state) => state.chatbotCreating);
  const { pageLoading } = useSelector((state) => state.settings);

  const { getChatbot } = useChatbotActions();
  const { getPath } = useAppNavigation();
  const { pageContentXTransition } = useAnimations();

  const stepContentRef = useRef(null);
  const chatbotId = getPath().pathArray[2];

  useEffect(() => {
    getChatbot(chatbotId);
  }, []);

  useEffect(() => {
    pageContentXTransition(stepContentRef);
  }, [activeStep]);

  if (pageLoading) <Loader />;

  return (
    <div className="">
      <PageContent>
        <HeaderWrapper>
          <StepTitle />
          <StepsContainer />
        </HeaderWrapper>
        <div
          className="flex flex-auto overflow-hidden [&>*]:flex [&>*]:flex-auto"
          ref={stepContentRef}
        >
          {activeStep === 0 && <Sources />}
          {activeStep === 1 && <Settings />}
          {activeStep === 2 && <Design />}
          {activeStep === 3 && <Leads />}
        </div>
      </PageContent>
      <Footer />
    </div>
  );
};
