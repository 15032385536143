import { XMarkIcon } from '@heroicons/react/24/outline';

import React, { useRef } from 'react';

import { PaywallCard } from 'components';
import { usePopup } from 'hooks/usePopup';
import { PopupWrapper } from 'ui-elements';
import { paywallItems } from 'utils/payData';

export const PaywallPopup = () => {
  const { closePaywallPopup, openUpgradePopup, openTrialPaywallPopup } = usePopup();

  const paywallPopupRef = useRef();

  const handleOutsideClick = (e) => {
    if (
      paywallPopupRef.current &&
      !paywallPopupRef.current.contains(e.target)
    ) {
      closePaywallPopup();
      openUpgradePopup();
    }
  };

  return (
    <div
      className="fixed z-20 flex h-screen w-screen items-center justify-center"
      onClick={(e) => handleOutsideClick(e)}
    >
      <div className="fixed w-fit" ref={paywallPopupRef}>
        <PopupWrapper className="h-screen max-h-screen w-screen bg-gradient-to-b from-[#EFEFEF] to-[#F0F0F8] dark:from-menuBg  dark:to-menuBg md:px-14 md:py-10 min-[1100px]:h-fit min-[1100px]:w-fit">
          <header className="relative flex justify-between gap-1">
            <h3 className="w-full text-2xl font-medium md:text-center">
              Premium add-ons
            </h3>
            <button
              className="absolute right-0 top-1/2 -translate-y-1/2 transform"
              onClick={() => {
                closePaywallPopup();
                openTrialPaywallPopup();
                // openUpgradePopup();
              }}
            >
              <XMarkIcon className="size-6" />
            </button>
          </header>
          <div className="grid gap-4 md:grid-cols-2 min-[1100px]:grid-cols-4">
            {paywallItems.map((item) => (
              <PaywallCard
                key={item.title}
                title={item.title}
                price={item.price}
                benefits={item.benefits}
              />
            ))}
          </div>
        </PopupWrapper>
      </div>
    </div>
  );
};
