export const capitalize = (str) =>
  str?.charAt(0)?.toUpperCase() + str?.slice(1);

export const getFileFormat = (fileName) => {
  const extensionIndex = fileName.lastIndexOf('.');
  let extension = '';

  if (extensionIndex !== -1) {
    return (extension = fileName.substring(extensionIndex + 1));
  }
  return '';
};
