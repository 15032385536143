import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUpdateBot } from 'hooks/useUpdateBot';
import { InputTitle, TextInput } from 'ui-elements';

import {
  addSuggestedMessages,
  changeSuggestedMessages,
  removeSuggestedMessages,
} from '../../../../redux/slices/chatbotPreview/settingsSlice';

export const SuggestedMessages = ({ chatbotId }) => {
  const dispatch = useDispatch();
  const { suggestedMessages } = useSelector((state) => state.chatbotSettings);
  const { updateBotSuggestedMessages } = useUpdateBot();

  const handleSuggestedInputChange = (index, message) => {
    dispatch(changeSuggestedMessages({ index, message }));
  };

  const addSuggestedMessage = () => {
    if (suggestedMessages.length < 5) {
      dispatch(addSuggestedMessages());
    }
  };

  const removeSuggestedMessage = (index) => {
    dispatch(removeSuggestedMessages(index));
  };

  useEffect(() => {
    updateBotSuggestedMessages(chatbotId, suggestedMessages);
  }, [suggestedMessages.length]);

  return (
    <div>
      <InputTitle
        title="Suggested Messages"
        tooltipText="Pre-defined responses or options that are provided to the user during the conversation"
      />
      <div className="flex flex-col gap-5">
        {suggestedMessages.map((item, index) => (
          <div key={index} className="flex items-center gap-2.5">
            <TextInput
              value={item}
              placeholder="Enter a suggested message"
              onChange={(e) =>
                handleSuggestedInputChange(index, e.target.value)
              }
              onBlur={() =>
                updateBotSuggestedMessages(chatbotId, suggestedMessages)
              }
            />
            {index === suggestedMessages.length - 1 && (
              <button onClick={() => addSuggestedMessage()}>
                <PlusIcon className="size-5" />
              </button>
            )}
            {index !== suggestedMessages.length - 1 && (
              <button
                onClick={() => {
                  removeSuggestedMessage(index);
                }}
              >
                <TrashIcon className="size-5 hover:text-destructive" />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
