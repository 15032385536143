import {
  ChatBubbleOvalLeftEllipsisIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useRef } from 'react';

import { PencilAnimation } from 'ui-elements';

export const Header = ({
  botName,
  isEdiNameMode,
  unread,
  editBotName,
  setNewBotName,
  newBotName,
  updateName,
  cancelUpdateName,
}) => {
  const editInputRef = useRef(null);

  useEffect(() => {
    if (isEdiNameMode) {
      editInputRef.current.focus();
    }
  }, [isEdiNameMode]);

  return (
    <header className="relative flex h-fit max-w-full items-start gap-1 overflow-hidden">
      {isEdiNameMode ? (
        <input
          className="mr-auto w-full text-ellipsis text-base font-medium md:text-lg lg:text-xl"
          type="text"
          value={newBotName}
          onChange={(e) => setNewBotName(e.target.value)}
          ref={editInputRef}
          maxLength={100}
        />
      ) : (
        <h3 className="mr-auto w-full overflow-hidden text-ellipsis whitespace-nowrap pr-6 font-medium">
          {newBotName || botName}
        </h3>
      )}
      {!isEdiNameMode && (
        <button className="absolute right-0 top-0" onClick={editBotName}>
          <PencilAnimation />
        </button>
      )}
      {isEdiNameMode && (
        <div className="flex gap-1">
          <button onClick={updateName}>
            <CheckIcon className="size-5" />
          </button>
          <button onClick={cancelUpdateName}>
            <XMarkIcon className="size-5" />
          </button>
        </div>
      )}
      {unread && (
        <div className="after:content-'' mr-8 relative after:absolute after:right-[1px] after:top-[1px] after:size-2 after:rounded-full after:bg-destructive">
          <ChatBubbleOvalLeftEllipsisIcon className="size-6" />
        </div>
      )}
    </header>
  );
};
