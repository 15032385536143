import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';

import { cn } from 'utils/cn';

export const ChatStatusDropdown = ({ list, selectedValue, onClick }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(selectedValue);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpenDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className={cn(
          'flex items-center gap-1 rounded-2xl border border-active px-2.5 py-0.5',
          dropdownValue === 'Closed' && 'pointer-events-none border-gray',
        )}
        onClick={() => setIsOpenDropdown(!isOpenDropdown)}
      >
        <p>{dropdownValue}</p>
        {dropdownValue === 'Active' && <ChevronDownIcon className="size-5" />}
      </button>
      {isOpenDropdown && (
        <div className="absolute right-0 top-[calc(100%+8px)] rounded-xl border border-border bg-tableBg p-2.5 ">
          {list.map((item, index) => (
            <button
              className="flex items-center gap-1 "
              key={index}
              onClick={() => {
                setDropdownValue(item);
                setIsOpenDropdown(false);
                onClick(item);
              }}
            >
              {item}
              {dropdownValue === item && <CheckIcon className="size-5" />}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
