import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useRef } from 'react';

import { usePopup } from 'hooks/usePopup';
import { Button, PopupWrapper } from 'ui-elements';
import { storageTariffs } from 'utils/payData';

import { Tariff } from './Tariff';

export const MoreStoragePopup = () => {
  const { closeMoreStoragePopup } = usePopup();
  const moreStoragePopupRef = useRef();

  const handleOutsideClick = (e) => {
    if (
      moreStoragePopupRef.current &&
      !moreStoragePopupRef.current.contains(e.target)
    ) {
      closeMoreStoragePopup();
    }
  };
  return (
    <div
      className="fixed inset-0 flex items-center justify-center"
      onClick={(e) => handleOutsideClick(e)}
    >
      <div
        className="h-screen w-screen md:fixed md:left-1/2 md:top-1/2 md:h-fit md:max-w-[1100px] md:-translate-x-1/2 md:-translate-y-1/2 md:transform"
        ref={moreStoragePopupRef}
      >
        <PopupWrapper className="h-full max-h-screen w-full bg-gradient-to-b from-[#EFEFEF] to-[#F0F0F8] md:px-14 md:py-10 dark:from-menuBg  dark:to-menuBg md:h-fit">
          <header className="relative md:px-7">
            <h3 className="w-full text-2xl font-medium md:text-center">
              Get more storage
            </h3>
            <button
              className="md:absolute md:right-0 md:top-0"
              onClick={() => closeMoreStoragePopup()}
            >
              <XMarkIcon className="size-6" />
            </button>
          </header>
          <div className="grid gap-4 min-[640px]:grid-cols-2 min-[1150px]:grid-cols-4">
            {storageTariffs.map((tariff) => {
              return <Tariff tariff={tariff} key={tariff.title} />;
            })}
          </div>
          <div className="mt-auto flex flex-col items-center gap-2 md:mt-0">
            <p className="text-center">Still not enough?</p>
            <Button
              className="min-h-10 w-full min-w-[191px] md:max-w-[191px]"
              variant="secondary"
            >
              Contact us
            </Button>
          </div>
        </PopupWrapper>
      </div>
    </div>
  );
};
