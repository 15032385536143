import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { InputWrapper } from 'ui-elements/InputWrapper';
import { cn } from 'utils/cn';
import { getCoordinates } from 'utils/getCoordinates';

import { setTriggerCoordinates } from '../../redux/slices/dropdownSlice';

export const TriggerButton = ({ children, onClick, isOpenDropdown }) => {
  const dispatch = useDispatch();
  const dropdownBtnRef = useRef(null);

  const handleClick = () => {
    dispatch(setTriggerCoordinates(getCoordinates(dropdownBtnRef)));
    onClick();
  };

  return (
    <button
      onClick={handleClick}
      className="max-h-[50px] w-full"
      ref={dropdownBtnRef}
    >
      <InputWrapper
        className={cn(
          'flex w-full items-center justify-between gap-1 duration-200 max-h-[50px]',
        )}
        active={isOpenDropdown}
      >
        {children}
      </InputWrapper>
    </button>
  );
};
