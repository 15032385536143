import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { cn } from 'utils/cn';

export const NavigationItem = forwardRef(
  ({ to, onClick, name, Icon, isActiveRoute, className }, ref) => {
    const { isOpen } = useSelector((state) => state.aside);

    return (
      <Link
        to={to}
        onClick={onClick}
        ref={ref}
        className={cn('hidden items-center capitalize', className)}
      >
        {isActiveRoute(name) ? (
          <Icon.active className="h-8 w-8" />
        ) : (
          <Icon.default className="h-8 w-8" />
        )}
        <span
          className={cn(
            'duration-200',
            isOpen ? 'ml-2.5 text-base' : 'ml-0 text-[0px]',
            isActiveRoute(name) && 'font-medium',
          )}
        >
          {name}
        </span>
      </Link>
    );
  },
);
NavigationItem.displayName = 'NavigationItem';