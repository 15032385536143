import React, { useState } from 'react';

import { PaywallCard } from 'components/PaywallCard';
import { Tariff } from 'components/Popups/MoreStorage/Tariff';
import { PopupWrapper } from 'ui-elements';
import { paywallItems, storageTariffs } from 'utils/payData';

import { SubSwitcher } from './SubSwitcher';

export const SubWrapper = ({
  title,
  chatbotId,
  chatbotActiveSubscriptions,
}) => {
  const items = ['Premium add-ons', 'Storage size'];
  const [activeType, setActiveType] = useState('Premium add-ons');
  return (
    <div>
      <PopupWrapper className="max-h-fit min-[1150px]:w-fit overflow-y-visible bg-list p-6 shadow-none max-w-[992px]" isAnimation={false}>
        <header className="flex flex-col-reverse md:flex-row">
          <h3 className=" w-full !text-start overflow-hidden text-ellipsis whitespace-nowrap">{title}</h3>
          <SubSwitcher
            activeType={activeType}
            setActiveType={setActiveType}
            items={items}
          />
        </header>
        <div className="grid gap-4 min-[640px]:grid-cols-2 min-[1150px]:grid-cols-4">
          {activeType === 'Premium add-ons' &&
            paywallItems.map((item) => (
              <PaywallCard
                key={item.title}
                title={item.title}
                price={item.price}
                benefits={item.benefits}
                chatbotId={chatbotId}
                activeSubscriptions={chatbotActiveSubscriptions}
              />
            ))}
          {activeType === 'Storage size' &&
            storageTariffs.map((tariff) => (
              <Tariff
                tariff={tariff}
                key={tariff.title}
                chatbotId={chatbotId}
                activeSubscriptions={chatbotActiveSubscriptions}
              />
            ))}
        </div>
      </PopupWrapper>
    </div>
  );
};
