import React from 'react';

import { InputWrapper } from 'ui-elements';
import { cn } from 'utils/cn';

export const Textarea = ({
  placeholder = 'Enter text',
  charCounter = 0,
  maxChars = 0,
  className,
  value,
  onChange,
  onBlur,
}) => (
  <InputWrapper className="w-full">
    <div className={cn('relative w-full', className)}>
      <textarea
        className="h-full w-full"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
      ></textarea>
      <p className="absolute bottom-0 right-0 text-grayText">
        {charCounter}/{maxChars}
      </p>
    </div>
  </InputWrapper>
);
