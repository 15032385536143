import {
  ArrowUpTrayIcon,
  ChatBubbleBottomCenterTextIcon,
} from '@heroicons/react/24/outline';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { showToastError } from 'components/CustomToast';
import useImageUpload from 'hooks/useImageUpload';
import { Button, IconWrapper, InputTitle, LoaderForInline } from 'ui-elements';

export const ChatBubbleButton = () => {
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const { uploadBubbleIcon } = useImageUpload();
  const { bubbleButtonIcon } = useSelector((state) => state.chatbotDesign);
  const fileInputRef = useRef(null);
  const FILE_SIZE_LIMIT_IN_BYTES = 5242880;

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > FILE_SIZE_LIMIT_IN_BYTES) {
      showToastError('File size exceeds 5MB. Please choose a smaller file.');
      fileInputRef.current.value = '';
    } else {
      setImageIsLoading(true);
      uploadBubbleIcon(file).finally(() => setImageIsLoading(false));
    }
  };

  return (
    <div>
      <InputTitle title="Chat bubble button" />
      <div className="flex items-center gap-5">
        <IconWrapper>
          {bubbleButtonIcon ? (
            <div
              className="h-full w-full rounded-full object-contain"
              style={{
                backgroundImage: `url(${bubbleButtonIcon})`,
              }}
            ></div>
          ) : (
            <ChatBubbleBottomCenterTextIcon />
          )}
        </IconWrapper>
        <div>
          <Button
            size="sm"
            variant="outline"
            onClick={handleButtonClick}
            disabled={imageIsLoading}
          >
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.svg,.webp"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <ArrowUpTrayIcon className="size-5" />
            Upload image
          </Button>
          <span className="text-xs text-grayText">
            Up to 5MB in size. JPG, PNG, SVG, WEBP
          </span>
        </div>
        {imageIsLoading && <LoaderForInline />}
      </div>
    </div>
  );
};
