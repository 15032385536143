import { MinusIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { logoIcons } from 'utils/iconsHelper';

import { togglePreview } from '../../redux/slices/chatbotPreview/index';

export const ChatHeader = () => {
  const dispatch = useDispatch();
  const { companyLogo } = useSelector((state) => state.chatbotDesign);
  const { botName } = useSelector((state) => state.chatbotSettings);
  const { theme, chosenTheme } = useSelector((state) => state.chatbotDesign);

  return (
    <header className="flex items-center gap-2">
      <div
        className="w-fit rounded-full p-1.5"
        style={{
          backgroundColor:
            chosenTheme === 'Main'
              ? theme.light.backgroundColor
              : theme.dark.backgroundColor,
        }}
      >
        <div className="size-5">
          <img
            className={
              companyLogo && 'h-full w-full rounded-full object-contain'
            }
            src={companyLogo || logoIcons.active}
          />
        </div>
      </div>
      <p>{botName || 'Chat with us!'}</p>
      <button className="ml-auto" onClick={() => dispatch(togglePreview())}>
        <MinusIcon className="size-6" />
      </button>
    </header>
  );
};
