import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useAppNavigation from 'hooks/useAppNavigation';
import { Icon } from 'ui-elements';
import { cn } from 'utils/cn';
import { logoIcons } from 'utils/iconsHelper';

import { setScrollPosition } from '../../redux/slices/asideSlice';

export const Logo = () => {
  const dispatch = useDispatch();
  const { goToChatbots } = useAppNavigation();

  const { isOpen } = useSelector((state) => state.aside);
  return (
    <Link
      className={cn(
        'flex items-center duration-200',
        isOpen ? 'gap-1 pl-0' : 'gap-0 pl-2',
      )}
      to="/chatbots"
      onClick={() => {
        goToChatbots();
        dispatch(setScrollPosition(0));
      }}
    >
      <Icon className="min-h-[36px] min-w-[36px]" iconPath={logoIcons} />
      <span
        className={`font-semibold text-grayText duration-200  ${isOpen ? 'text-base' : 'text-[0px]'}`}
      >
        AI Desk
      </span>
    </Link>
  );
};
