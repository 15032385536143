import { useDispatch, useSelector } from 'react-redux';

import {
  setActiveStep,
  setActiveSubStep,
  setPrevStep,
} from '../redux/slices/chatbotCreating';
import useAppNavigation from './useAppNavigation';
import { useChatbotActions } from './useChatbotActions';
import { usePopup } from './usePopup';
import { useUpdateBot } from './useUpdateBot';

export const useSteps = () => {
  const dispatch = useDispatch();
  const { activeStep, activeSubStep } = useSelector(
    (state) => state.chatbotCreating,
  );
  const { isAIFunctionality, isAllInOne } = useSelector(
    (state) => state.stripe,
  );

  const { goToChatbots } = useAppNavigation();
  const { closeCancelBotCreationPopup, openCreateBotPopup } = usePopup();
  const { deleteChatbot, getChatbots } = useChatbotActions();
  const { updateBotStatus } = useUpdateBot();

  const handleStepBack = () => {
    dispatch(setPrevStep(activeStep));
    dispatch(setActiveStep(activeStep - 1));
    dispatch(setActiveSubStep(0));
  };

  const handleStepNext = (id) => {
    if (activeStep === 3) {
      openCreateBotPopup();
      updateBotStatus(id, 'free');
    } else {
      dispatch(setPrevStep(activeStep));
      dispatch(setActiveStep(activeStep + 1));
      dispatch(setActiveSubStep(0));
    }
  };

  const handleSaveAsDraft = () => {
    dispatch(setActiveStep(0));
    dispatch(setActiveSubStep(0));
    getChatbots();
    goToChatbots();
  };

  const handleCancelChatbotCreation = () => {
    closeCancelBotCreationPopup();
    dispatch(setActiveStep(0));
    dispatch(setActiveSubStep(0));
    deleteChatbot();
    goToChatbots();
  };

  const handleStepClick = (index) => {
    dispatch(setPrevStep(activeStep));
    dispatch(setActiveStep(index));
    dispatch(setActiveSubStep(0));
  };

  const handleSubStepClick = (index) => {
    if (activeStep > 2) {
      dispatch(setActiveSubStep(index));
    } else if (activeStep === 0 && isAllInOne) {
      dispatch(setActiveSubStep(index));
    } else if (activeStep === 0 && isAIFunctionality) {
      dispatch(setActiveSubStep(index));
    } else if (activeStep === 1) {
      dispatch(setActiveSubStep(index));
    }
  };

  return {
    handleStepBack,
    handleStepNext,
    handleSaveAsDraft,
    handleCancelChatbotCreation,
    handleStepClick,
    handleSubStepClick,
  };
};
