export const rgbaToHex = (rgba) => {
  const { r, g, b, a } = rgba;
  if (a < 1) {
    return `#${Number(r).toString(16).padStart(2, '0')}${Number(g).toString(16).padStart(2, '0')}${Number(b).toString(16).padStart(2, '0')}${Math.round(
      a * 255,
    )
      .toString(16)
      .padStart(2, '0')}`;
  } else {
    return `#${Number(r).toString(16).padStart(2, '0')}${Number(g).toString(16).padStart(2, '0')}${Number(b).toString(16).padStart(2, '0')}`;
  }
};

export const hexToRgba = (hex) => {
  hex?.replace('#', '');
  const r = parseInt(hex.substring(1, 3), 16) / 255;
  const g = parseInt(hex.substring(3, 5), 16) / 255;
  const b = parseInt(hex.substring(5, 7), 16) / 255;
  const a = hex.length > 7 ? parseInt(hex.substring(7, 9), 16) / 255 : 1;

  return { r: r * 255, g: g * 255, b: b * 255, a };
};

export const formatHex = (hex) => hex.replace('#', '');
