import { ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';

import gsap from 'gsap';
import { Button, InputWrapper } from 'ui-elements';
import { cn } from 'utils/cn';

export const EmbedOption = ({
  title,
  index,
  description,
  onClick,
  code,
  isDropdown,
}) => {
  const [isOpenContent, setIsOpenContent] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isDropdown) {
      gsap.fromTo(
        contentRef.current,
        {
          height: 0,
          duration: 0.3,
          ease: 'power3.out',
        },
        {
          height: 'auto',
          duration: 0.3,
        },
      );
    }
  }, [isOpenContent]);

  return (
    <div className="select-none overflow-hidden rounded-2xl border border-border p-4">
      <h3 className="font-normal">
        <p
          className={cn(
            'flex items-center justify-between gap-2',
            isDropdown ? 'cursor-pointer' : '',
          )}
          onClick={() => setIsOpenContent(!isOpenContent)}
        >
          <span className="font-medium">
            Option {index}:<span className="text-main"> {title} </span>
          </span>
          {isDropdown && (
            <ChevronDownIcon
              className={cn(
                'ml-auto inline-block size-6 duration-200',
                isOpenContent ? '-scale-y-100' : '',
              )}
            />
          )}
        </p>
      </h3>
      {(!isDropdown || isOpenContent) && (
        <div className="flex flex-col gap-5" ref={contentRef}>
          <p className="text-lg">{description}</p>
          <InputWrapper className="min-h-fit">
            <p className="text-lg font-medium">{code}</p>
          </InputWrapper>
          <Button className="mx-auto" onClick={onClick}>
            Copy {title}
          </Button>
        </div>
      )}
    </div>
  );
};
