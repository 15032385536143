import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import useStripe from 'hooks/API/useStripe';
import { usePopup } from 'hooks/usePopup';
import { Button, PopupShadow, PopupWrapper } from 'ui-elements';
import { LoaderForInline } from 'ui-elements/LoaderForInline';
import { useChatbotActions } from 'hooks/useChatbotActions';

export const RenewSubscriptionPopup = () => {
  const { chatbotId } = useSelector((state) => state.chatbot);
  const { selectedSubscriptionId, typeOfRenewSubscription, subscriptionToChangeTitle } = useSelector((state) => state.stripe);
  const [isSubscriptionRenewing, setIsSubscriptionRenewing] = useState(false);
  const { closeRenewSubscriptionPopup, openRenewedSubscripionPopup } =
    usePopup();
  const { renewSubscription, changeSubscriptions } = useStripe();
  const { getChatbots } = useChatbotActions();

  const isRenew = typeOfRenewSubscription === 'renew';

  const handleCancelSubscription = () => {
    setIsSubscriptionRenewing(true);
    if (isRenew) {
      renewSubscription({ subscriptionId: selectedSubscriptionId })
        .then(() => {
          getChatbots();
          openRenewedSubscripionPopup();
        })
        .finally(() => setIsSubscriptionRenewing(false));
    } else {
      changeSubscriptions({ subscriptionId: selectedSubscriptionId, priceName: subscriptionToChangeTitle, chatbotId })
        .then(() => {
          getChatbots();
          openRenewedSubscripionPopup();
        })
        .finally(() => setIsSubscriptionRenewing(false));
    }
  };

  return (
    <div>
      <PopupWrapper className="fixed left-1/2 top-1/2 z-50 w-full -translate-x-1/2 -translate-y-1/2 transform p-10 min-[480px]:w-[440px]">
        <header className="relative">
          <h3 className="w-full text-center">
            {isRenew ? 'Are you sure you want to renew subscription?' : 'Are you sure you want to change your current subscription?'}
          </h3>
          <button
            onClick={closeRenewSubscriptionPopup}
            className="absolute right-0 top-0 -translate-y-full translate-x-full transform"
          >
            <XMarkIcon className="size-6" />
          </button>
        </header>
        <footer>
          <Button
            size="sm"
            className="cursor-pointer p-3"
            onClick={handleCancelSubscription}
          >
            {isSubscriptionRenewing ? <LoaderForInline /> : isRenew ? 'Yes, renew it' : 'Yes, change it'}
          </Button>
          <Button
            size="sm"
            variant="outline"
            onClick={closeRenewSubscriptionPopup}
            className="p-3"
          >
            Cancel
          </Button>
        </footer>
      </PopupWrapper>
      <PopupShadow
        className="z-40"
        onClick={() => closeRenewSubscriptionPopup()}
      />
    </div>
  );
};
