import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchChatsByValue: '',
  searchByDate: {
    from: '',
    to: '',
  },
  searchByStatus: ['active'],
  // searchByStatus: ['detach', 'close'],
};

const chatMenuSlice = createSlice({
  name: 'chatMenu',
  initialState,
  reducers: {
    setSearchChatsByValue: (state, action) => {
      state.searchChatsByValue = action.payload;
    },
    setSearchByDate: (state, action) => {
      state.searchByDate = action.payload;
    },
    setSearchByStatus: (state, action) => {
      state.searchByStatus = action.payload;
    },
  },
});

export const { setSearchChatsByValue, setSearchByDate, setSearchByStatus } =
  chatMenuSlice.actions;

export default chatMenuSlice.reducer;
