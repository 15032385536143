import { createSlice } from '@reduxjs/toolkit';
import { stepsData } from 'utils/steps';

const initialState = {
  steps: stepsData,
  activeStep: 0,
  prevStep: 0,
  activeSubStep: 0,
  isChatbotPaid: false,
};

const chatbotCreatingSlice = createSlice({
  name: 'chatbotCreating',
  initialState,
  reducers: {
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setPrevStep: (state, action) => {
      state.prevStep = action.payload;
    },
    setActiveSubStep: (state, action) => {
      state.activeSubStep = action.payload;
    },
    setIsChatbotPaid: (state, action) => {
      state.isChatbotPaid = action.payload;
    },
  },
});

export const {
  setActiveStep,
  setPrevStep,
  setActiveSubStep,
  setIsChatbotPaid,
} = chatbotCreatingSlice.actions;

export default chatbotCreatingSlice.reducer;
