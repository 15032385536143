import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useWebSocket } from 'context/WebSocketContext';

import {
  addMessageToCachedChatsHistory,
  addNewChat,
  addNewMessageToActiveChat,
  addUnreadMessage,
  setChatbots,
} from '../redux/slices/chatbotSlice';
import useAppNavigation from './useAppNavigation';
import { useChatActions } from './useChatActions';

export const useChatHistory = () => {
  const dispatch = useDispatch();
  const { socket } = useWebSocket();
  const { streamAllChats } = useChatActions();
  const { getPath } = useAppNavigation();
  const chatId = getPath().pathArray[4];

  const handleMessage = (event) => {
    const data = JSON.parse(event.data);
    if (data?.name === 'readBot') {
      dispatch(setChatbots(data.chats));
      return;
    }
    if (data?.newChat) {
      dispatch(addNewChat(data.newChat));
    }
    if (data?.newMessage && data?.chatId) {
      dispatch(addUnreadMessage(data.chatId));
      dispatch(
        addMessageToCachedChatsHistory({
          chatId: data.chatId,
          message: data.newMessage,
        }),
      );
      if (data.chatId === chatId) {
        dispatch(addNewMessageToActiveChat(data.newMessage));
      }
    }
    return data;
  };

  useEffect(() => {
    streamAllChats();
    socket.current.addEventListener('message', handleMessage);

    return () => {
      socket.current.removeEventListener('message', handleMessage);
    };
  }, []);

  return {
    socket,
  };
};
