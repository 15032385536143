import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleDarkMode } from '../redux/slices/settingsSlice';

export const useTheme = () => {
  const dispatch = useDispatch();
  const { aiDeskTheme } = useSelector((state) => state.settings);
  const localStorageTheme = localStorage.getItem('aiDeskTheme');

  const toggleTheme = () => {
    if (aiDeskTheme === 'light') {
      dispatch(toggleDarkMode('dark'));
      localStorage.setItem('aiDeskTheme', 'dark');
    } else {
      dispatch(toggleDarkMode('light'));
      localStorage.setItem('aiDeskTheme', 'light');
    }
  };

  useEffect(() => {
    if (!localStorageTheme) {
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        dispatch(toggleDarkMode('dark'));
        document.documentElement.classList.add('dark');
      } else {
        dispatch(toggleDarkMode('light'));
        document.documentElement.classList.remove('dark');
      }
    } else {
      if (localStorageTheme === 'dark') {
        dispatch(toggleDarkMode('dark'));
        document.documentElement.classList.add('dark');
      } else {
        dispatch(toggleDarkMode('light'));
        document.documentElement.classList.remove('dark');
      }
    }
  }, [aiDeskTheme]);

  return {
    toggleTheme,
    isDarkMode: aiDeskTheme === 'dark',
  };
};
