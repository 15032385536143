import React from 'react';

import { cn } from 'utils/cn';

export const Loader = ({ className }) => (
  <div
    className={cn(
      'relative flex h-full w-full items-center justify-center',
      className,
    )}
  >
    <div className="h-10 w-10 animate-spin rounded-full border-4 border-transparent border-r-main border-t-main" />
  </div>
);
