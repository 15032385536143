import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chosenTheme: 'Main',
  companyLogo: '',
  bubbleButtonIcon: '',
  cornerRadius: 'rounded',
  shadowName: 'Soft shade',
  theme: {
    light: {
      accentColor: '#3DC96D',
      backgroundColor: '#F0F0F5',
      frameColor: '#EBEBEB',
      textColor: '#000000',
      accentTextColor: '#FFFFFF',
      shadow: {
        shadowOffsetX: 0,
        shadowBlur: 15,
        shadowOffsetY: 2,
        shadowSpread: 15,
        shadowColor: '#D9D9D9',
      },
    },
    dark: {
      accentColor: '#3DC96D',
      backgroundColor: '#F0F0F5',
      frameColor: '#EBEBEB',
      textColor: '#000000',
      accentTextColor: '#FFFFFF',
      shadow: {
        shadowOffsetX: 0,
        shadowBlur: 15,
        shadowOffsetY: 2,
        shadowSpread: 15,
        shadowColor: '#D9D9D9',
      },
      isDefault: true
    },
  },
  widgetLocation: 'right',
};

const chatbotDesignSlice = createSlice({
  name: 'chatbotDesign',
  initialState,
  reducers: {
    setChosenTheme: (state, action) => {
      state.chosenTheme = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setLightAccentColor: (state, action) => {
      state.theme.light.accentColor = action.payload;
      if (state.theme.dark.isDefault) {
        state.theme.dark.accentColor = action.payload;
      }
    },
    setDarkAccentColor: (state, action) => {
      state.theme.dark.accentColor = action.payload;
      state.theme.dark.isDefault = false;
    },
    setLightBackgroundColor: (state, action) => {
      state.theme.light.backgroundColor = action.payload;
      if (state.theme.dark.isDefault) {
        state.theme.dark.backgroundColor = action.payload;
      }
    },
    setDarkBackgroundColor: (state, action) => {
      state.theme.dark.backgroundColor = action.payload;
      state.theme.dark.isDefault = false;
    },
    setLightFrameColor: (state, action) => {
      state.theme.light.frameColor = action.payload;
      if (state.theme.dark.isDefault) {
        state.theme.dark.frameColor = action.payload;
      }
    },
    setDarkFrameColor: (state, action) => {
      state.theme.dark.frameColor = action.payload;
      state.theme.dark.isDefault = false;
    },
    setLightTextColor: (state, action) => {
      state.theme.light.textColor = action.payload;
      if (state.theme.dark.isDefault) {
        state.theme.dark.textColor = action.payload;
      }
    },
    setDarkTextColor: (state, action) => {
      state.theme.dark.textColor = action.payload;
      state.theme.dark.isDefault = false;
    },
    setLightAccentTextColor: (state, action) => {
      state.theme.light.accentTextColor = action.payload;
      if (state.theme.dark.isDefault) {
        state.theme.dark.accentTextColor = action.payload;
      }
    },
    setDarkAccentTextColor: (state, action) => {
      state.theme.dark.accentTextColor = action.payload;
      state.theme.dark.isDefault = false;
    },
    setLightShadow: (state, action) => {
      state.theme.light.shadow = action.payload;
      if (state.theme.dark.isDefault) {
        state.theme.dark.shadow = action.payload;
      }
    },
    setDarkShadow: (state, action) => {
      state.theme.dark.shadow = action.payload;
      state.theme.dark.isDefault = false;
    },
    setLightShadowColor: (state, action) => {
      state.theme.light.shadow.shadowColor = action.payload;
      if (state.theme.dark.isDefault) {
        state.theme.dark.shadow.shadowColor = action.payload;
      }
    },
    setDarkShadowColor: (state, action) => {
      state.theme.dark.shadow.shadowColor = action.payload;
      state.theme.dark.isDefault = false;
    },
    setLightShadowX: (state, action) => {
      state.theme.light.shadow.shadowOffsetX = action.payload;
      if (state.theme.dark.isDefault) {
        state.theme.dark.shadow.shadowOffsetX = action.payload;
      }
    },
    setDarkShadowX: (state, action) => {
      state.theme.dark.shadow.shadowOffsetX = action.payload;
      state.theme.dark.isDefault = false;
    },
    setLightShadowBlur: (state, action) => {
      state.theme.light.shadow.shadowBlur = action.payload;
      if (state.theme.dark.isDefault) {
        state.theme.dark.shadow.shadowBlur = action.payload;
      }
    },
    setDarkShadowBlur: (state, action) => {
      state.theme.dark.shadow.shadowBlur = action.payload;
      state.theme.dark.isDefault = false;
    },
    setLightShadowY: (state, action) => {
      state.theme.light.shadow.shadowOffsetY = action.payload;
      if (state.theme.dark.isDefault) {
        state.theme.dark.shadow.shadowOffsetY = action.payload;
      }
    },
    setDarkShadowY: (state, action) => {
      state.theme.dark.shadow.shadowOffsetY = action.payload;
      state.theme.dark.isDefault = false;
    },
    setLightShadowSpread: (state, action) => {
      state.theme.light.shadow.shadowSpread = action.payload;
      if (state.theme.dark.isDefault) {
        state.theme.dark.shadow.shadowSpread = action.payload;
      }
    },
    setDarkShadowSpread: (state, action) => {
      state.theme.dark.shadow.shadowSpread = action.payload;
      state.theme.dark.isDefault = false;
    },
    setShadowName: (state, action) => {
      state.shadowName = action.payload;
    },
    setCornerRadius: (state, action) => {
      state.cornerRadius = action.payload;
    },
    setWidgetLocation: (state, action) => {
      if (action.payload === 'Right side of the screen') {
        state.widgetLocation = 'right';
      } else if (action.payload === 'Left side of the screen') {
        state.widgetLocation = 'left';
      } else {
        state.widgetLocation = action.payload;
      }
    },
    setBubbleIcon: (state, action) => {
      state.bubbleButtonIcon = action.payload;
    },
    setCompanyLogo: (state, action) => {
      state.companyLogo = action.payload;
    },
  },
});

export const {
  setChosenTheme,
  setTheme,
  setLightAccentColor,
  setDarkAccentColor,
  setLightBackgroundColor,
  setDarkBackgroundColor,
  setLightFrameColor,
  setDarkFrameColor,
  setLightTextColor,
  setDarkTextColor,
  setLightAccentTextColor,
  setDarkAccentTextColor,
  setLightShadow,
  setDarkShadow,
  setLightShadowColor,
  setDarkShadowColor,
  setLightShadowX,
  setDarkShadowX,
  setLightShadowBlur,
  setDarkShadowBlur,
  setLightShadowY,
  setDarkShadowY,
  setLightShadowSpread,
  setDarkShadowSpread,
  setCornerRadius,
  setWidgetLocation,
  setShadowName,
  setBubbleIcon,
  setCompanyLogo
} = chatbotDesignSlice.actions;
export default chatbotDesignSlice.reducer;
