import React from 'react';
import { Link } from 'react-router-dom';

import { useChatbotActions } from 'hooks/useChatbotActions';
import { usePopup } from 'hooks/usePopup';
import { Button, PopupWrapper } from 'ui-elements';

export const WelcomePopup = () => {
  const { closeWelcomePopup } = usePopup();
  const { createChatbot } = useChatbotActions();

  const createBot = () => {
    closeWelcomePopup();
    createChatbot();
  };

  return (
    <div className="fixed left-1/2 top-1/2 h-fit w-[420px] -translate-x-1/2 -translate-y-1/2 transform">
      <PopupWrapper className="p-7">
        <h3 className="text-center">Welcome to AI Desk</h3>
        <Link to="/chatbot" className="flex justify-center">
          <Button onClick={createBot}>Create your chatbot</Button>
        </Link>
      </PopupWrapper>
    </div>
  );
};
