import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPreviewOpen: true,
  isShowFooter: true,
  isPreviewAnimation: false,
};

const chatbotPreviewSlice = createSlice({
  name: 'chatbotPreview',
  initialState,
  reducers: {
    togglePreview: (state) => {
      state.isPreviewOpen = !state.isPreviewOpen;
    },
    toggleFooter: (state) => {
      state.isShowFooter = !state.isShowFooter;
    },
    setPreviewAnimation: (state, action) => {
      state.isPreviewAnimation = action.payload;
    },
  },
});

export const { togglePreview, toggleFooter, setPreviewAnimation } =
  chatbotPreviewSlice.actions;
export default chatbotPreviewSlice.reducer;
