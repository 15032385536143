import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentCursorCoordinates: { x: 0, y: 0 },
  prevCursorCoordinates: { x: 0, y: 0 },
};

const animationSlice = createSlice({
  name: 'animation',
  initialState,
  reducers: {
    setCurrentCursorCoordinates: (state, action) => {
      state.currentCursorCoordinates = action.payload;
    },
    setPrevCursorCoordinates: (state, action) => {
      state.prevCursorCoordinates = action.payload;
    },
  },
});

export const { setCurrentCursorCoordinates, setPrevCursorCoordinates } =
  animationSlice.actions;
export default animationSlice.reducer;
