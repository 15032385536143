import { UserIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { cn } from 'utils/cn';

export const Avatar = ({ active }) => (
  <div
    className={cn(
      'flex min-h-12 min-w-12 items-center justify-center overflow-hidden rounded-full bg-primary text-white',
      active && 'bg-main',
    )}
  >
    <UserIcon className="size-6" />
  </div>
);
