import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { usePopup } from 'hooks/usePopup';
import { useSources } from 'hooks/useSources';
import { DropFileInput } from 'ui-elements';

import { FilesTable } from './FilesTable';

export const Files = () => {
  const { uploadFileFromDevice } = useSources();

  return (
    <>
      <header>
        <h2>Files</h2>
        <p>
          Uploading your files is a convenient way to share important
          information, resources, or updates with your users. That is how
          responses of your chatbot will better suit your needs and reflect your
          brand&apos;s voice.
        </p>
      </header>
      <DropFileInput
        inputText="Click to upload a file or drag and drop it here"
        inputDescription="Up to 100MB in size. PDF, DOC, DOCX"
        onFileChange={uploadFileFromDevice}
      />
      <FilesTable />
    </>
  );
};
