import React from 'react';
import { toast } from 'react-toastify';

import { ToastIcon } from 'ui-elements';

export const showToastError = (content) => {
  toast.error(<CustomToast content={content} />, {
    icon: false,
    position: 'bottom-left',
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: 'light',
    closeButton: false,
  });
};

export const showToastSuccess = (title = 'Success!', content) => {
  toast.success(<CustomToastSuccess title={title} content={content} />, {
    icon: true,
    position: 'bottom-left',
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: 'light',
    closeButton: false,
  });
};

export const CustomToast = ({ content }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col font-redHatDisplay">
        <span className="pb-1 text-lg font-bold text-black">Error!</span>
        <span className="text-base text-black">
          {content || 'Something went wrong...'}
        </span>
      </div>
      <ToastIcon />
    </div>
  );
};

export const CustomToastSuccess = ({ title, content }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col font-redHatDisplay">
        <span className="pb-1 text-lg font-bold text-black">{title}</span>
        <span className="text-base text-black">
          {content || 'Something went wrong...'}
        </span>
      </div>
    </div>
  );
};
