import React, { useEffect, useRef, useState } from 'react';

import gsap from 'gsap';

export const PencilAnimation = () => {
  const [isHovered, setIsHovered] = useState(false);
  const pencilRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
    if (isHovered) {
      gsap.fromTo(
        pencilRef.current,
        {
          rotation: 0,
          duration: 0.5,
          x: 0,
          y: 0,
        },
        {
          rotation: -55,
          duration: 2,
          x: 10,
          y: -2,
          // repeat: -1,
          transformOrigin: '50% 50%',
          yoyo: true,
        },
      );

      gsap.fromTo(
        lineRef.current,
        {
          x: -23,
          duration: 2,
        },
        {
          x: -2,
          duration: 2,
          // repeat: -1,
          yoyo: true,
        },
      );
    } else {
      gsap.killTweensOf(pencilRef.current);
      gsap.killTweensOf(lineRef.current);
      gsap.to(pencilRef.current, {
        rotation: 0,
        x: 0,
        y: 0,
        duration: 0.5,
        ease: 'power1.out',
      });

      gsap.to(lineRef.current, {
        x: -23,
        duration: 0.5,
        ease: 'power1.out',
      });
    }
  }, [isHovered]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <g id="pencilContainer">
        <g id="pencilGroup">
          <path
            id="pencil"
            ref={pencilRef}
            d="M12.3658 4.71795C12.8064 4.278 13.3982 4.02181 14.0212 4.00133C14.6441 3.98085 15.2515 4.19762 15.7202 4.60767L15.8381 4.71795L16.4166 5.29544C16.8572 5.73523 17.1137 6.32577 17.1342 6.94733C17.1547 7.5689 16.9377 8.17501 16.5271 8.64278L16.4166 8.76041L7.24119 17.9195C7.11146 18.049 6.95431 18.1478 6.78127 18.2086L6.64869 18.247L3.00373 19.0867C2.87602 19.1164 2.74303 19.1149 2.61599 19.0826C2.48894 19.0502 2.37154 18.9879 2.27367 18.9007C2.17581 18.8136 2.10035 18.7043 2.05366 18.582C2.00696 18.4597 1.99039 18.328 2.00533 18.198L2.0217 18.1057L2.86215 14.4668C2.90368 14.2883 2.98477 14.1213 3.09948 13.9783L3.19031 13.8762L12.3658 4.71795ZM11.7872 7.60543L4.43014 14.9487L3.90966 17.2015L6.16669 16.6812L13.5237 9.33791L11.7872 7.60543ZM14.6809 5.87294C14.54 5.73229 14.3525 5.64782 14.1536 5.63534C13.9547 5.62285 13.7581 5.68323 13.6007 5.80515L13.5237 5.87295L12.9443 6.45045L14.6809 8.18293L15.2595 7.60544C15.4004 7.46478 15.485 7.27765 15.4975 7.07915C15.51 6.88064 15.4495 6.6844 15.3274 6.52723L15.2595 6.45045L14.6809 5.87294Z"
            fill="currentColor"
          />
          <path
            id="line"
            className="-translate-x-6 transform"
            ref={lineRef}
            d="M2.82031 20.7002H21.9998"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </g>
      </g>
    </svg>
  );
};
