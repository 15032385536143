export const convertArrayOfObjectsToCSV = (data) => {
  // Create array of column headers
  const headers = Object.keys(data[0]);

  // Create CSV string with headers
  let csv = headers.join(',') + '\n';

  // Append rows to the CSV string
  data.forEach((item) => {
    const row = headers.map((header) => item[header]);
    csv += row.join(',') + '\n';
  });

  navigator.clipboard.writeText(csv);
};
