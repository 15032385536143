import React from 'react';

import { Status } from 'ui-elements';
import { lastSaved } from 'utils/timeHelper';

export const Main = ({ status, updateTime }) => {
  return (
    <main className="flex items-center justify-between gap-1 overflow-hidden">
      <Status className='min-w-12 py-0.5 text-xs font-medium px-2' name={status} />
      <p className="overflow-hidden text-ellipsis whitespace-nowrap text-grayText text-xs">
        Updated {lastSaved(updateTime)}
      </p>
    </main>
  );
};
